import joints0 from './images/joints-0.png';
import joints1 from './images/joints-1.png';

export default {
  v: "5.3.4",
  fr: 29.9700012207031,
  ip: 0,
  op: 30.0000012219251,
  w: 200,
  h: 200,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    { id: "image_0", w: 74, h: 69, u: "", p: joints0, e: 0 },
    { id: "image_1", w: 73, h: 74, u: "", p: joints1, e: 0 }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 105, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 2,
      nm: "bones2.eps",
      cl: "eps",
      parent: 1,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 10,
              s: [0],
              e: [10]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 20,
              s: [10],
              e: [0]
            },
            { t: 30.0000012219251 }
          ],
          ix: 10
        },
        p: { a: 0, k: [-1.5, -4, 0], ix: 2 },
        a: { a: 0, k: [10.5, 59.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "bones1.eps",
      cl: "eps",
      parent: 1,
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 10,
              s: [0],
              e: [-10]
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 20,
              s: [-10],
              e: [0]
            },
            { t: 30.0000012219251 }
          ],
          ix: 10
        },
        p: { a: 0, k: [-1.5, -3.5, 0], ix: 2 },
        a: { a: 0, k: [61, 13.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
