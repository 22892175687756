import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import { browserHistory } from '../../history';
import { Image } from '../../components/rb-import';

import style from './motivation.module.scss';
import great from '../QuestionPage/single/ratings/great.svg';

const prompts = defineMessages({
  great: {
    id: 'motivate.great',
    description: 'Great!',
    defaultMessage: 'Great!',
  },
  hiname: {
    id: 'motivate.hi',
    description: 'Hi [name]',
    defaultMessage: 'Hi {name}',
  },
  expect: {
    id: 'motivate.expect',
    description: 'Here\'s what you can expect:',
    defaultMessage: 'Here\'s what you can expect:',
  },
  take1: {
    id: 'motivate.take1',
    description: 'This is going to take',
    defaultMessage: 'This is going to take',
  },
  take2: {
    id: 'motivate.take2',
    description: 'about 5-10 minutes',
    defaultMessage: 'about 5-10 minutes',
  },
  ask: {
    id: 'motivate.ask',
    description: 'We will ask you a number of questions so that we can provide you our best recommendations.',
    defaultMessage: 'We will ask you a number of questions so that we can provide you our best recommendations.',
  },
  yes: {
    id: 'motivate.yes',
    description: 'And, yes!...',
    defaultMessage: 'And, yes!...',
  },
  comeback: {
    id: 'motivate.comeback',
    description: 'You can start now and come back later',
    defaultMessage: 'You can start now and come back later',
  },
  start: {
    id: 'motivate.start',
    description: 'So let\'s get started!',
    defaultMessage: 'So let\'s get started!',
  },
})

const clauses = [
  [
    { id: 'c1', text: prompts.great, duration: 750, color: '#404040', below: 2 },
    { id: 'c2', text: prompts.hiname, duration: 2000, color: '#EC6529', below: 2 },
    { id: 'c3', text: prompts.expect, duration: 2000, color: '#404040' },
  ],
  [
    { id: 'c4', text: prompts.take1, duration: 0, color: '#404040' },
    { id: 'c5', text: prompts.take2, duration: 2000, color: '#EC6529', below: 2 },
    { id: 'c6', text: prompts.ask, duration: 5000, color: '#404040' },
  ],
  [
    { id: 'c7', text: prompts.yes, duration: 1000, color: '#404040', below: 2 },
    { id: 'c8', text: prompts.comeback, duration: 2000, color: '#404040', below: 3 },
    { id: 'c9', text: prompts.start, duration: 2000, color: '#EC6529', below: 2 },
    { id: 'c0', image: great, duration: 2000 },
  ],
];

class MotivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = { index: 0, prompt: 0 };
  }

  componentDidMount() {
    const { mute } = this.props;
    const { index, prompt } = this.state;
    const current = clauses[index][prompt];
    this.setTimer(current.duration, mute);
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer = (duration, mute = false) => {
    this.clearTimer();

    // const { duration = 2000, endDuration = 4000, mute } = this.props;
    // const { index } = this.state;

    if (!mute) {
      this.timer = setTimeout(() => {
        this.next();
      }, duration);
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer =null;
    }
  }

  next = () => {
    const { mute } = this.props;
    const { index, prompt } = this.state;

    this.clearTimer();

    if (prompt < clauses[index].length - 1) {
      this.setState(state => ({ prompt: state.prompt + 1 }));

      const current = clauses[index][prompt + 1];
      this.setTimer(current.duration, mute);
    } else {
      if (index < clauses.length - 1) {
        this.setState(state => ({ index: state.index + 1, prompt: 0 }));

        const current = clauses[index + 1][0];
        this.setTimer(current.duration, mute);
      } else {
        this.navigateAway();
      }
    }
  }

  navigateAway = () => {
    browserHistory.push('/runner');
  }

  render() {
    const { name, intl } = this.props;
    const { index, prompt } = this.state;

    const currentClauses = clauses[index];

    return (
      <Fragment>
        <div className={classnames(style.panel)} onClick={this.next}>
          <div className={classnames(style.wrapper, "mt-5")}>
            <div className={style.inner}>
              {currentClauses.map((clause, i) => i <= prompt ? (
                <div 
                  key={clause.id}
                  className={style.prompt}
                  style={{
                    color: clause.color,
                    ...clause.below && { marginBottom: `${clause.below}rem` },
                  }}
                >
                  {clause.text && intl.formatMessage(clause.text, { name })}
                  {clause.image && (
                    <Image src={clause.image} />
                  )}
                </div>
              ) : null)}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(MotivationPage);
