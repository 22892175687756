import React, { Component } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  RedditShareButton,
  PinterestShareButton,

  // VKShareButton, OKShareButton, TelegramShareButton, TumblrShareButton,
  // LivejournalShareButton, MailruShareButton, ViberShareButton, WorkplaceShareButton,
  // GooglePlusShareButton,
  FacebookShareCount,
  // LinkedinShareCount,
  RedditShareCount,
  PinterestShareCount,

  // VKShareCount, OKShareCount, GooglePlusShareCount, TumblrShareCount,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
  RedditIcon,
  PinterestIcon // Instagram

  // GooglePlusIcon,
  // VKIcon, OKIcon, TelegramIcon,
  // TumblrIcon, MailruIcon,
  // LivejournalIcon, ViberIcon, WorkplaceIcon,
} from "react-share";
import classnames from "classnames";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import style from "./share.module.scss";
import { Image } from "../rb-import";
import share from "../../images/share.svg";

const shareUrl = "https://youngevityhealthquiz.com";
// const title = "Youngevity Health Quiz";
const hashTag = "#ygybetterhealth";

class SharePanel extends Component {
  constructor(props) {
    super(props);
    this.state = { showing: true };
  }

  showButtons = () => {
    this.setState({ showing: false });
  };

  render() {
    const { showing } = this.state;

    return (
      <div className={style.sharepanel}>
        <div
          className={classnames(style.shareprompt, {
            [style.showing]: showing
          })}
          onClick={this.showButtons}
        >
          <Image src={share} className={style.shareicon} />
          <FormattedHTMLMessage
            id="share.sharethis"
            description="Share this experience with a friend"
            defaultMessage="SHARE THIS EXPERIENCE<br />WITH A FRIEND"
          />
        </div>

        <div
          className={classnames(style.buttonwrapper, {
            [style.showing]: !showing
          })}
        >
          <div className={style.title}>
            <FormattedMessage
              id="share.experience"
              description="Share your experience prompt"
              defaultMessage="Share your experience"
            >
              {text => text}
            </FormattedMessage>
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.facebook"
              description="Facebook share message"
              defaultMessage="Do you know what nutrients your body needs for optimal health? Take a simple assessment to find out!"
            >
              {text => (
                <FacebookShareButton
                  url={shareUrl}
                  quote={text}
                  className={style.sharebutton}
                  hashtag={hashTag}
                >
                  <FacebookIcon size={32} />
                </FacebookShareButton>
              )}
            </FormattedMessage>

            <FacebookShareCount url={shareUrl} className={style.sharecount}>
              {count => count}
            </FacebookShareCount>
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.title"
              description="Share title"
              defaultMessage="Youngevity Health Quiz"
            >
              {shareTitle => (
                <TwitterShareButton
                  url={shareUrl}
                  title={shareTitle}
                  // via={shareUrl}
                  hashtags={[
                    "Youngevity",
                    "90ForLife",
                    "90EssentialNutrients",
                    "HealthyBody",
                    "HealthQuiz",
                    "DrWallach",
                    "HealthAssessment"
                  ]}
                  className={style.sharebutton}
                >
                  <TwitterIcon size={32} />
                </TwitterShareButton>
              )}
            </FormattedMessage>

            <div className={style.sharecount}>&nbsp;</div>
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.linkedin"
              description="LinkedIn share message"
              defaultMessage="Do you know what nutrients your body needs for optimal health? Take a simple assessment to find out!"
            >
              {text => (
                <FormattedMessage
                  id="share.title"
                  description="Share title"
                  defaultMessage="Youngevity Health Quiz"
                >
                  {shareTitle => (
                    <LinkedinShareButton
                      url={shareUrl}
                      title={shareTitle}
                      description={text}
                      windowWidth={750}
                      windowHeight={600}
                      className={style.sharebutton}
                    >
                      <LinkedinIcon size={32} />
                    </LinkedinShareButton>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>

            {/* <LinkedinShareCount url={shareUrl} className={style.sharecount}>
              {count => count}
            </LinkedinShareCount> */}
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.title"
              description="Share title"
              defaultMessage="Youngevity Health Quiz"
            >
              {shareTitle => (
                <WhatsappShareButton
                  url={shareUrl}
                  title={shareTitle}
                  separator=": "
                  className={style.sharebutton}
                >
                  <WhatsappIcon size={32} />
                </WhatsappShareButton>
              )}
            </FormattedMessage>

            <div className={style.sharecount}>&nbsp;</div>
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.title"
              description="Share title"
              defaultMessage="Youngevity Health Quiz"
            >
              {shareTitle => (
                <RedditShareButton
                  url={shareUrl}
                  title={shareTitle}
                  windowWidth={660}
                  windowHeight={460}
                  className={style.sharebutton}
                >
                  <RedditIcon size={32} />
                </RedditShareButton>
              )}
            </FormattedMessage>

            <RedditShareCount url={shareUrl} className={style.sharecount} />
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.pinterest"
              description="Pinterest share message"
              defaultMessage="Do you know what nutrients your body needs for optimal health? Take a simple assessment to find out!"
            >
              {text => (
                <PinterestShareButton
                  url={shareUrl}
                  media="https://youngevityhealthquiz.com/images/pinterest_img_quiz.jpg"
                  windowWidth={1000}
                  windowHeight={730}
                  className={style.sharebutton}
                  description={text}
                >
                  <PinterestIcon size={32} round />
                </PinterestShareButton>
              )}
            </FormattedMessage>
            <PinterestShareCount url={shareUrl} className={style.sharecount} />
          </div>
          <div className={style.sharewrapper}>
            <FormattedMessage
              id="share.email"
              description="Email share message"
              defaultMessage={`Are you ready to take your health into your own hands?\n\nYour body has unique health needs. And while every human body requires basic nutrition to survive, there are also specific nutrients each of our bodies need for optimal health and to achieve personal health goals. To find out what nutrients your body needs, take a simple, personalized health assessment. Get Started Now!\n\nhttps://youngevityhealthquiz.com`}
            >
              {text => (
                <FormattedMessage
                  id="share.title"
                  description="Share title"
                  defaultMessage="Youngevity Health Quiz"
                >
                  {shareTitle => (
                    <EmailShareButton
                      url={shareUrl}
                      subject={shareTitle}
                      body={text}
                      className={style.sharebutton}
                    >
                      <EmailIcon size={32} />
                    </EmailShareButton>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </div>
        </div>
      </div>
    );
  }
}

export default SharePanel;
