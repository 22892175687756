import { connect } from 'react-redux';

import Header from './Header';
import { showPanel, hidePanel, getCartEmpty, setShowingCart } from '../../state';

const mapStateToProps = state => ({
  cartEmpty: getCartEmpty(state),
});

export default connect(mapStateToProps, { showPanel, hidePanel, setShowingCart })(Header);
