import React from 'react';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import { Image } from '../../../components/rb-import';

import style from '../question.module.scss';

import bigcheck from './bigcheck.svg';

const QuizToggle = ({ image, imageSelected, text, square, selected, onClick, intl }) => {
  const currentImage = () => {
    if (square) {
      return selected ? bigcheck : image;
    }
    return selected ? imageSelected : image;
  }
  return (
    <div
      className={classnames(
        square ? style.square : style.rating,
        { [style.selected]: selected },
      )} 
      onClick={onClick}
    >
      <Image src={currentImage()} className={style.pic} />
      <div className={style.label}>
        {intl.formatMessage(text)}
      </div>
    </div>
  );
};

export default injectIntl(QuizToggle);
