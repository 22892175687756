import { defineMessages } from "react-intl";

import prod10256 from "./images/10256.jpg";
import prod10254 from "./images/10254.jpg";
import prod10258 from "./images/10258.jpg";
import prod10257 from "./images/10257.jpg";
import prod10260 from "./images/10260.jpg";
import prod10259 from "./images/10259.jpg";
import prod10253 from "./images/10253.jpg";

const productText = defineMessages({
  "10256.clause": {
    id: "10256.clause",
    description: "Clause for SKU#10256",
    defaultMessage: "improving your bone and joint health"
  },
  "10256.categ": {
    id: "10256.categ",
    description: "Category for SKU#10256",
    defaultMessage: "Joints, Bones & Teeth"
  },
  "10256.subtitle": {
    id: "10256.subtitle",
    description: "Description for SKU#10256",
    defaultMessage: "For healthy bones, joints, and soft tissue, we recommend…"
  },
  "10256.tagline": {
    id: "10256.tagline",
    description: "Tagline for SKU#10256",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support bone and joint health."
  },
  "10256.name": {
    id: "10256.name",
    description: "Name of SKU#10256",
    defaultMessage: `Healthy Body Bone and Joint Pak™ 2.0`
  },
  "10256.summary": {
    id: "10256.summary",
    description: "Summary of SKU#10256",
    defaultMessage: `Get the 90 essential nutrients you need to thrive, plus additional 
    joint support from glucosamine and our advanced soothing joint and muscle relief 
    cream.  This Pak was specifically designed to deliver the nutritional support 
    needed to promote healthy bones and joints.`
  },
  "10256.description": {
    id: "10256.description",
    description: "Description of SKU#10256",
    defaultMessage: `<p>Get the 90 essential nutrients you need to thrive, plus 
    additional joint support from glucosamine and our advanced soothing joint
    and muscle relief cream.  This Pak was specifically designed to deliver
    the nutritional support needed to promote healthy bones and joints.</p>
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research
    and life’s work, which is committed to helping people live younger, longer
    lives through essential nutrition.</p> 
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ powder – 1 canister (357g)</li>
      <li>Ultimate Gluco-Gel™ – 1 container (240 capsules)</li>
      <li>Ultimate CM Cream™ – 1 container (2oz)</li>
    </ul> 
    <p>See individual products for details.</p>`
  },

  "10254.clause": {
    id: "10254.clause",
    description: "Clause for SKU#10254",
    defaultMessage: "your body’s metabolism and energy production"
  },
  "10254.categ": {
    id: "10254.categ",
    description: "Category for SKU#10254",
    defaultMessage: "Metabolism & Energy"
  },
  "10254.subtitle": {
    id: "10254.subtitle",
    description: "Subtitle for SKU#10254",
    defaultMessage: "For a healthy metabolism and energy, we recommend…"
  },
  "10254.tagline": {
    id: "10254.tagline",
    description: "Tagline for SKU#10254",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support healthy blood sugar levels and metabolism."
  },
  "10254.name": {
    id: "10254.name",
    description: "Name for SKU#10254",
    defaultMessage: "Healthy Body Blood Sugar Pak™ 2.0"
  },
  "10254.summary": {
    id: "10254.summary",
    description: "Summary for SKU#10254",
    defaultMessage: `This Pak gives you foundational nutrition that includes the 90 essential
    nutrients you need to thrive plus additional botanicals that help the body metabolize
    glucose. This Pak was specifically formulated to promote healthy blood sugar levels
    that help support a healthy metabolism and energy production.`
  },
  "10254.description": {
    id: "10254.description",
    description: "Description for SKU#10254",
    defaultMessage: `<p>This Pak gives you foundational nutrition that includes the 90 essential 
    nutrients you need to thrive plus additional botanicals that help the body metabolize 
    glucose. This Pak was specifically formulated to promote healthy blood sugar levels 
    that help support a healthy metabolism and energy production.</p>
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research and life’s
    work, which is committed to helping people live younger, longer lives through essential 
    nutrition.</p>
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Slender FX™ Sweet EZE™ – 1 container (120 capsules)</li>
    </ul>
    <p>See individual products for details.</p>`
  },

  "10258.clause": {
    id: "10258.clause",
    description: "Clause for SKU#10258",
    defaultMessage: "cardiovascular & neurological difficulties"
  },
  "10258.categ": {
    id: "10258.categ",
    description: "Category for SKU#10258",
    defaultMessage: "Brain & Heart Health"
  },
  "10258.subtitle": {
    id: "10258.subtitle",
    description: "Subtitle for SKU#10258",
    defaultMessage:
      "For a healthy brain and cardiovascular system, we recommend…"
  },
  "10258.tagline": {
    id: "10258.tagline",
    description: "Tagline for SKU#10258",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support brain and cardiovascular health."
  },
  "10258.name": {
    id: "10258.name",
    description: "Name for SKU#10258",
    defaultMessage: "Healthy Body Brain and Heart Pak™ 2.0"
  },
  "10258.summary": {
    id: "10258.summary",
    description: "Summary for SKU#10258",
    defaultMessage: `Get the 90 essential nutrients you need to thrive, with the added benefit 
    of Ultimate Selenium™, an important antioxidant shown to protect against
    cell-damaging free radicals. This Pak provides a full spectrum of vitamins, trace 
    minerals, and a wide-ranging supply of Essential Fatty Acids that are known to 
    support brain and heart health.`
  },
  "10258.description": {
    id: "10258.description",
    description: "Description for SKU#10258",
    defaultMessage: `<p>Get the 90 essential nutrients you need to thrive, with the added benefit of 
    Ultimate Selenium™, an important antioxidant shown to protect against cell-damaging free 
    radicals. This Pak provides a full spectrum of vitamins, trace minerals, and a wide-ranging 
    supply of Essential Fatty Acids that are known to support brain and heart health.</p>
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research and life’s work, 
    which is committed to helping people live younger, longer lives through essential nutrition.</p>
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Ultimate EFA™ – 1 container (180 softgels)</li>
      <li>Ultimate Selenium™ – 1 container (90 capsules)</li>
    </ul>
    <p>See individual products for details.</p>`
  },

  "10257.clause": {
    id: "10257.clause",
    description: "Clause for SKU#10257",
    defaultMessage: "digestion problems and your immune system"
  },
  "10257.categ": {
    id: "10257.categ",
    description: "Category for SKU#10257",
    defaultMessage: "Digestion & Immunity"
  },
  "10257.subtitle": {
    id: "10257.subtitle",
    description: "Subtitle for SKU#10257",
    defaultMessage: "For a healthy digestion and immune system, we recommend…"
  },
  "10257.tagline": {
    id: "10257.tagline",
    description: "Tagline for SKU#10257",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support a healthy digestive and immune system."
  },
  "10257.name": {
    id: "10257.name",
    description: "Name for SKU#10257",
    defaultMessage: "Healthy Body Digestion Pak™ 2.0"
  },
  "10257.summary": {
    id: "10257.summary",
    description: "Summary for SKU#10257",
    defaultMessage: `With 90 essential nutrients at its core, this Pak offers a solid
    foundation for good health, plus enzymes and probiotics to support a healthy
    digestive system. The additional nutritional support in this Pak helps create
    a healthy environment for good bacteria to thrive and support a healthy immune
    system.`
  },
  "10257.description": {
    id: "10257.description",
    description: "Description for SKU#10257",
    defaultMessage: `<p>With 90 essential nutrients at its core, this Pak offers a
    solid foundation for good health, plus enzymes and probiotics to support a 
    healthy digestive system. The additional nutritional support in this Pak 
    helps create a healthy environment for good bacteria to thrive and support a 
    healthy immune system.</p> 
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research
    and life’s work, which is committed to helping people live younger, longer
    lives through essential nutrition.</p>
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Ultimate Enzymes® – 1 container (120 capsules)</li>
      <li>Ultimate Flora FX™ – 1 container (60 capsules)</li>
    </ul>
    <p>See individual products for details.</p>`
  },

  "10260.clause": {
    id: "10260.clause",
    description: "Clause for SKU#10260",
    defaultMessage: "minimizing skin damage and healthy aging"
  },
  "10260.categ": {
    id: "10260.categ",
    description: "Category for SKU#10260",
    defaultMessage: "Anti-Aging"
  },
  "10260.subtitle": {
    id: "10260.subtitle",
    description: "Subtitle for SKU#10260",
    defaultMessage: "To support healthy aging, we recommend…"
  },
  "10260.tagline": {
    id: "10260.tagline",
    description: "Tagline for SKU#10260",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support healthy aging."
  },
  "10260.name": {
    id: "10260.name",
    description: "Name for SKU#10260",
    defaultMessage: "Anti-aging Healthy Body Start Pak™ 2.0"
  },
  "10260.summary": {
    id: "10260.summary",
    description: "Summary for SKU#10260",
    defaultMessage: `This Pak provides the 90 essential nutrients your body needs to thrive plus
    additional antioxidant support to combat free radicals that are known to compromise
    healthy cells in the body. This Pak™ is specifically formulated with antioxidant-rich
    nutrients that support healthy aging.`
  },
  "10260.description": {
    id: "10260.description",
    description: "Description for SKU#10260",
    defaultMessage: `<p>This Pak provides the 90 essential nutrients your body needs to
    thrive plus additional antioxidant support to combat free radicals that are known
    to compromise healthy cells in the body. This Pak™ is specifically formulated with
    antioxidant-rich nutrients that support healthy aging.</p>  
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research and
    life’s work, which is committed to helping people live younger, longer lives through
    essential nutrition.</p> 
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Cell Shield RTQ™ – 1 container (60 capsules</li>
    </ul>
    <p>See individual products for details.</p>`
  },

  "10259.clause": {
    id: "10259.clause",
    description: "Clause for SKU#10259",
    defaultMessage: "supporting your body during physical activities"
  },
  "10259.categ": {
    id: "10259.categ",
    description: "Category for SKU#10259",
    defaultMessage: "Athletic Performance"
  },
  "10259.subtitle": {
    id: "10259.subtitle",
    description: "Subtitle for SKU#10259",
    defaultMessage: "For support with athletic performance, we recommend…"
  },
  "10259.tagline": {
    id: "10259.tagline",
    description: "Tagline for SKU#10259",
    defaultMessage:
      "Broad-spectrum foundational nutrition formulated to support athletic performance."
  },
  "10259.name": {
    id: "10259.name",
    description: "Name for SKU#10259",
    defaultMessage: "Healthy Body Athletic Pak™ 2.0"
  },
  "10259.summary": {
    id: "10259.summary",
    description: "Summary for SKU#10259",
    defaultMessage: `Our 90 essential nutrients help all athletes perform at a high level, but
    this Pak gives you an extra boost. We’ve added Ultimate Gluco-Gel™ for bone and joint
    care, and Rebound FX™ for quick, continuous energy that supports workouts, sports
    activities, and all other physical exercise.`
  },
  "10259.description": {
    id: "10259.description",
    description: "Description for SKU#10259",
    defaultMessage: `<p>Our 90 essential nutrients help all athletes perform at a high
    level, but this Pak gives you an extra boost. We’ve added Ultimate Gluco-Gel™ for
    bone and joint care, and Rebound FX™ for quick, continuous energy that supports 
    workouts, sports activities, and all other physical exercise.</p>
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research and 
    life’s work, which is committed to helping people live younger, longer lives 
    through essential nutrition.</p> 
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Ultimate Gluco-Gel™ – 1 container (120 capsules)</li>
      <li>Rebound FX™ – 1 box (30 ct stick packs)</li>
    </ul> 
    <p>See individual products for details.</p>`
  },

  "10253.clause": {
    id: "10253.clause",
    description: "Clause for SKU#10253",
    defaultMessage: "losing weight and improving your diet"
  },
  "10253.categ": {
    id: "10253.categ",
    description: "Category for SKU#10253",
    defaultMessage: "Weight Loss"
  },
  "10253.subtitle": {
    id: "10253.subtitle",
    description: "Subtitle for SKU#10253",
    defaultMessage: "For support with weight loss, we recommend…"
  },
  "10253.tagline": {
    id: "10253.tagline",
    description: "Tagline for SKU#10253",
    defaultMessage: "For support with weight loss, we recommend…"
  },
  "10253.name": {
    id: "10253.name",
    description: "Name for SKU#10253",
    defaultMessage: "Healthy Body Weight Loss Pak™ 2.0"
  },
  "10253.summary": {
    id: "10253.summary",
    description: "Summary for SKU#10253",
    defaultMessage: `With this Healthy Body Start Pak, you get the 90 essential nutrients you 
    need for optimal health plus a unique formula known to support weight loss. It also 
    contains Slender FX™ REV™, designed to target excess fat, and help your body mobilize 
    and metabolize fat stores in the body.`
  },
  "10253.description": {
    id: "10253.description",
    description: "Description for SKU#10253",
    defaultMessage: `<p>With this Healthy Body Start Pak, you get the 90 essential nutrients 
    you need for optimal health plus a unique formula known to support weight loss. It 
    also contains Slender FX™ REV™, designed to target excess fat, and help your body 
    mobilize and metabolize fat stores in the body.</p> 
    <p>Each ingredient in this Pak represents Dr. Wallach’s pioneering research and
    life’s work, which is committed to helping people live younger, longer lives
    through essential nutrition.</p> 
    <p>This Pak™ includes:</p>
    <ul>
      <li>Beyond Tangy Tangerine® 2.0 Citrus Peach Fusion – 1 canister (480g)</li>
      <li>Ultimate EFA Plus™ – 1 container (90 softgels)</li>
      <li>Beyond Osteo-FX™ Powder – 1 canister (357g)</li>
      <li>Slender FX™ REV™ - 1 bottle (2 fl. oz.)</li>
    </ul>
    <p>See individual products for details.</p>`
  }
});

const products = [
  {
    segmentId: "joints",
    clause: productText["10256.clause"],
    categoryTitle: productText["10256.categ"],
    subtitle: productText["10256.subtitle"],
    tagline: productText["10256.tagline"],
    name: productText["10256.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 266.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 213.95 } },
    summary: productText["10256.summary"],
    description: productText["10256.description"],
    image: prod10256,
    sku: "10256"
  },
  {
    segmentId: "energy",
    clause: productText["10254.clause"],
    categoryTitle: productText["10254.categ"],
    subtitle: productText["10254.subtitle"],
    tagline: productText["10254.tagline"],
    name: productText["10254.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 216.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 173.95 } },
    summary: productText["10254.summary"],
    description: productText["10254.description"],
    image: prod10254,
    sku: "10254"
  },
  {
    segmentId: "heart",
    clause: productText["10258.clause"],
    categoryTitle: productText["10258.categ"],
    subtitle: productText["10258.subtitle"],
    tagline: productText["10258.tagline"],
    name: productText["10258.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 274.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 219.95 } },
    summary: productText["10258.summary"],
    description: productText["10258.description"],
    image: prod10258,
    sku: "10258"
  },
  {
    segmentId: "immune",
    clause: productText["10257.clause"],
    categoryTitle: productText["10257.categ"],
    subtitle: productText["10257.subtitle"],
    tagline: productText["10257.tagline"],
    name: productText["10257.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 253.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 202.95 } },
    summary: productText["10257.summary"],
    description: productText["10257.description"],
    image: prod10257,
    sku: "10257"
  },
  {
    segmentId: "aging",
    clause: productText["10260.clause"],
    categoryTitle: productText["10260.categ"],
    subtitle: productText["10260.subtitle"],
    tagline: productText["10260.tagline"],
    name: productText["10260.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 239.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 191.95 } },
    summary: productText["10260.summary"],
    description: productText["10260.description"],
    image: prod10260,
    sku: "10260"
  },
  {
    segmentId: "perf",
    clause: productText["10259.clause"],
    categoryTitle: productText["10259.categ"],
    subtitle: productText["10259.subtitle"],
    tagline: productText["10259.tagline"],
    name: productText["10259.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 266.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 213.95 } },
    summary: productText["10259.summary"],
    description: productText["10259.description"],
    image: prod10259,
    sku: "10259"
  },
  {
    segmentId: "weight",
    clause: productText["10253.clause"],
    categoryTitle: productText["10253.categ"],
    subtitle: productText["10253.subtitle"],
    tagline: productText["10253.tagline"],
    name: productText["10253.name"],
    retailPrice: { "en-US": { currency: "USD", amount: 274.99 } },
    wholesalePrice: { "en-US": { currency: "USD", amount: 219.95 } },
    summary: productText["10253.summary"],
    description: productText["10253.description"],
    image: prod10253,
    sku: "10253"
  }
];

export default products;