import React, { Component } from 'react';
import classnames from 'classnames';
import { defineMessages, injectIntl } from 'react-intl';

import { Button } from '../rb-import';

const buttonTextStrings = defineMessages({
  added: {
    id: 'skubutton.added',
    description: 'Button text when product has already been added',
    defaultMessage: 'ADDED TO CART',
  },
  add: {
    id: 'skubutton.add',
    description: 'Button text when product has not yet been added',
    defaultMessage: 'ADD TO CART',
  },
});

const getLabel = (selected, intl) => intl.formatMessage(selected ? buttonTextStrings.added : buttonTextStrings.add);

const checkElements = (elems) => {
  elems.forEach(elem => {
    if (!elem || elem.id === undefined || elem.description === undefined || elem.defaultMessage === undefined) {
      throw Error(`Missing id, description or defaultMessage on ${typeof elem === 'string' ? elem : JSON.stringify(elem)}`);
    }
  });
}

class SkuButton extends Component {
  render() {
    const { sku, title, price, wholesale, image, skuSelected, addToCart, className, onClick, intl, ...props } = this.props;
    checkElements([title]);
    if (!price || !wholesale || !image) {
      throw Error(`Missing sku information: sku=${sku}, title=${title}, price=${price}, wholesale=${wholesale}, image=${image}`);
    }
    return (
      <Button
        {...props} 
        variant="ypri" 
        size="ys" 
        disabled={skuSelected} 
        onClick={() => {
          addToCart(sku, title, image, price, wholesale);
          if (onClick) { onClick(); 
        }}}
        className={classnames(className, 'btn-space', 'wm30')}
        >
        {getLabel(skuSelected, intl)}
      </Button>
    );
  }
}

export default injectIntl(SkuButton);