import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';

import { Container, Row, Col, ButtonToolbar, Button } from '../../components/rb-import';

const DebugPage = ({ resetQuiz, emptyCart, match }) => (
  <Container>
    <Row className="my-5">
      <Col>
        <h4>Status</h4>
        <ul style={{ fontSize: '1rem' }}>
          <li>Work-in-progress!</li>
          <li>Printable results and share buttons are current development focus</li>
        </ul>
        <ButtonToolbar>
          <Button onClick={resetQuiz}>
            Reset Quiz
          </Button>
          <Button onClick={emptyCart}>
            Reset Cart
          </Button>
          <LinkContainer to="/quiz">
            <Button style={{ marginLeft: '10px' }}>
              Start Quiz
            </Button>
          </LinkContainer>
        </ButtonToolbar>
      </Col>
    </Row>
  </Container>
);

export default DebugPage;
