import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { getQuizStatus, verifyRepId, setCountry } from '../../state';

import StartPage from './StartPage';

const mapStateToProps = state => ({
  completed: getQuizStatus(state) === 'completed',
});

export default withRouter(connect(mapStateToProps, { verifyRepId, setCountry })(StartPage));
