import { defineMessages } from "react-intl";

import image10283 from "./images/10283.jpg";
import image10284 from "./images/10284.jpg";
import image10285 from "./images/10285.jpg";
import image13223 from "./images/13223.jpg";
import image13203 from "./images/13203.jpg";
import image20682 from "./images/20682.jpg";
import image30300 from "./images/30300.jpg";
import imagePJ330 from "./images/PJ330.jpg";
import imagePJ500 from "./images/PJ500.jpg";
import imageUSYG300002 from "./images/USYG300002.jpg";
import imageUSYG100084 from "./images/USYG100084.jpg";
import imageUSYG100082 from "./images/USYG100082.jpg";
import imageUSYG102067 from "./images/USYG102067.jpg";
import imageUSYGY300001 from "./images/USYGY300001.jpg";
import imageUSYGY100080 from "./images/USYGY100080.jpg";
import imageUSYGY100088 from "./images/USYGY100088.jpg";
import imageUSYGY100086 from "./images/USYGY100086.jpg";
import imageUSYGY100092 from "./images/USYGY100092.jpg";
import imageUSYGY100083 from "./images/USYGY100083.jpg";
import imageUSYGY100093 from "./images/USYGY100093.jpg";

const recoMessages = defineMessages({
  "weight.title": {
    id: "weight.title",
    description: "Title for  targeted nutrition section",
    defaultMessage: "Weight loss"
  },
  "weight.description": {
    id: "weight.description",
    description: "Description for weight targeted nutrition section",
    defaultMessage: `If you’re interested in losing weight and would like additional support, we offer 
    two great weight loss bundles. Combine these Paks with our Better Health Challenge and commit to 
    your goals!`
  },

  "10285.name": {
    id: "10285.name",
    description: "Description for SKU#10285",
    defaultMessage: "Keto 90 Pak – Better Health Challenge"
  },
  "10285.tagline": {
    id: "10285.tagline",
    description: "Tagline for SKU#10285",
    defaultMessage:
      "Nutritional support for better health following the Keto 90 diet."
  },
  "10285.summary": {
    id: "10285.summary",
    description: "Summary for SKU#10285",
    defaultMessage: `This weight loss Pak is essential to following the Keto 90 Program. This Pak delivers 
    specific nutritional support that meets the macronutrient profile of the keto diet.`
  },
  "10285.description": {
    id: "10285.description",
    description: "Description for SKU#10285",
    defaultMessage: `<p>This Pak helps you successfully follow the high-fat, moderate protein, low-carb
    Keto 90 Program. Through a careful combination of targeted products, it offers specific 
    nutritional support that meets the macronutrient profiles of the Keto Diet. Learn more about 
    the Keto 90 Program at <a href="ygybetterhealth.com">ygybetterhealth.com</a>.</p> 
    <p>This Pak includes:</p>
    <ul>
      <li>Slender FX™ Keto Caramel Weight Management Bar – 1 box (10 bars)</li>
      <li>Slender FX™ TrueKeto™ Strawberry Crème Shake – 1 canister (2.8 lbs)</li>
      <li>Slender FX™ Keto Power Up™ - 1 bottle (60 capsule)</li>
      <li>Ultimate Mineral Caps™ - 1 bottle (64 capsules)</li>
    </ul> 
    <p>See individual products for details.</p>`
  },

  "10283.name": {
    id: "10283.name",
    description: "Name for SKU#10283",
    defaultMessage: "REV 90 Pak – Better Health Challenge"
  },
  "10283.tagline": {
    id: "10283.tagline",
    description: "Tagline for SKU#10283",
    defaultMessage:
      "Nutritional support for better health following the REV 90 diet."
  },
  "10283.summary": {
    id: "10283.summary",
    description: "Summary for SKU#10283",
    defaultMessage: `This Pak was designed to support the nutritional needs of individuals following the REV 90 
    Program – a high-density, low calorie nutrition plan.`
  },
  "10283.description": {
    id: "10283.description",
    description: "Description for SKU#10283",
    defaultMessage: `<p>With an emphasis on high-density, low-calorie nutrition, this exclusive Pak was 
    designed to support the nutritional needs of the REV 90 Program.</p> 
    <p>It focuses on a diet full of satisfying, low-calorie food options, providing you with the proper
    nutrition to successfully follow the REV 90 plan. Learn more about the REV 90 Program
    at <a href="ygybetterhealth.com">ygybetterhealth.com</a>.</p>
    <p>This Pak™ includes:</p>
    <ul>
      <li>FitShake™ Banana Cream – 1 canister (2.4 lbs)</li>
      <li>Super Greens™ – 1 canister (255 g)</li>
      <li>Slender FX™ Rev™ - 1 bottle (2 fl. oz)</li>
    </ul> 
    <p>See individual products for details.</p>`
  },

  "10284.name": {
    id: "10284.name",
    description: "Name for SKU#10284",
    defaultMessage: "Wellness 90 Pak – Better Health Challenge"
  },
  "10284.tagline": {
    id: "10284.tagline",
    description: "Tagline for SKU#10284",
    defaultMessage:
      "Nutritional support for better health following the Wellness 90 diet."
  },
  "10284.summary": {
    id: "10284.summary",
    description: "Summary for SKU#10284",
    defaultMessage: `This Pak was designed to support individuals following the Wellness 90 Program.
    It includes a great-tasting meal replacement shake and additional nutritional support for 
    a healthy metabolism.`
  },
  "10284.description": {
    id: "10284.description",
    description: "Description for SKU#10284",
    defaultMessage: `<p>The Wellness 90 Program highlights a nutritional diet made up of whole foods 
    that helps individuals who would like to create sensible diet changes.</p>
    <p>This Pak delivers nutrients to support a healthy metabolism and promote healthy nutritional 
    changes while following the Wellness 90 Program. This program focus on a diet rich in vegetables, 
    fruits, lean proteins, legumes, nuts and other healthy fats. Learn more about the Wellness 90 
    Program at <a href="ygybetterhealth.com">ygybetterhealth.com</a>.</p>
    <p>This Pak™ includes:</p>
    <ul>
    <li>TMR Shake (Vanilla) – 1 canister (3.1 lbs)</li>
    <li> Slender FX™ Sweet EZE– 1 bottle (120 capsules)</li>
    <li>Slender FX™ Rev™ - 1 bottle (2 fl. oz)</li>
    <li>True Detox Tea™ - 1 box (30 packets)</li>
    </ul>
    <p>See individual products for details.</p>`
  },

  "muscle.title": {
    id: "muscle.title",
    description: "Title for muscle targeted nutrition section",
    defaultMessage: "Building Muscle"
  },
  "muscle.description": {
    id: "muscle.description",
    description: "Description for muscle targeted nutrition section",
    defaultMessage: `Protein is essential to muscle growth and recovery. Even more important is the type of 
    protein you’re consuming. Youngevity offers an incredible protein supplement to help you meet your
    goals.`
  },

  "USYG300002.name": {
    id: "USYG300002.name",
    description: "Name for SKU#USYG300002",
    defaultMessage: "FitShake™ – Banana Cream"
  },
  "USYG300002.tagline": {
    id: "USYG300002.tagline",
    description: "Tagline for SKU#USYG300002",
    defaultMessage:
      "Enhance physical performance and muscle building in a great-tasting protein shake!"
  },
  "USYG300002.summary": {
    id: "USYG300002.summary",
    description: "Summary for SKU#USYG300002",
    defaultMessage: `FitShake™ was specifically designed to go beyond muscle building and help support the 
    connective tissues surrounding the muscle in a great-tasting banana cream shake.`
  },
  "USYG300002.description": {
    id: "USYG300002.description",
    description: "Description for SKU#USYG300002",
    defaultMessage: `<p>Specifically designed to supplement your performance, this is the first 
    Youngevity shake to focus on the health and strength of muscles, connective tissues, and joints.
    This great-tasting banana flavored shake is low fat, low carb and delivers 25 grams of 
    high-quality protein and just 150 calories per serving.</p>
    <p>Features:</p>
    <ul>
      <li>Whey protein, flaxseed and bone broth concentrate</li>
      <li>Digestive and immune support</li>
      <li>Delicious banana cream flavor</li>
    </ul>`
  },

  "exercise.title": {
    id: "exercise.title",
    description: "Title for exercise targeted nutrition section",
    defaultMessage: "Exercise & Performance"
  },
  "exercise.description": {
    id: "exercise.description",
    description: "Description for exercise targeted nutrition section",
    defaultMessage: `Making sure your body is getting proper nutrition will help support physical performance. 
    Check out some essential products that can support exercise and your overall health.`
  },

  "USYG100084.name": {
    id: "USYG100084.name",
    description: "Name for SKU#USYG100084",
    defaultMessage: "Ultimate Daily Classic™"
  },
  "USYG100084.tagline": {
    id: "USYG100084.tagline",
    description: "Tagline for SKU#USYG100084",
    defaultMessage:
      "Broad foundation for good health through vitamins, minerals, and antioxidant-rich botanicals."
  },
  "USYG100084.summary": {
    id: "USYG100084.summary",
    description: "Summary for SKU#USYG100084",
    defaultMessage: `Provides essential vitamins and minerals needed daily for a solid foundation. This formula 
    also includes antioxidant-rich botanicals support healthy brain function, cardiovascular health, 
    and more.`
  },
  "USYG100084.description": {
    id: "USYG100084.description",
    description: "Description for SKU#USYG100084",
    defaultMessage: `<p>Ultimate Daily Classic™ provides essential vitamins and minerals to support your 
    active lifestyle. This formula includes antioxidant-rich botanicals such as ginkgo leaf and hawthorn 
    berry, which are known to support healthy brain function, cardiovascular health, and combat infections. 
    Additionally, Ultimate Daily Classic™ contains chondroitin sulfate to support healthy bones and joints.</p>
    <p>Features:</p>
    <ul>
      <li>Essential vitamins & minerals</li>
      <li>Antioxidants</li>
      <li>Daily support for optimal health</li>
    </ul>`
  },

  "13223.name": {
    id: "13223.name",
    description: "Name for SKU#13223",
    defaultMessage: "Rebound FX™ Citrus Punch"
  },
  "13223.tagline": {
    id: "13223.tagline",
    description: "Tagline for SKU#13223",
    defaultMessage:
      "Natural energy boost plus replenishing nutrients to support exercise and performance."
  },
  "13223.summary": {
    id: "13223.summary",
    description: "Summary for SKU#13223",
    defaultMessage: `Rebound FX™ is a great-tasting nutrient-rich alternative to high-sodium, high carbohydrate 
    sports drinks for sustained energy during exercise.`
  },
  "13223.description": {
    id: "13223.description",
    description: "Description for SKU#13223",
    defaultMessage: `<p>With a great citrus taste, Rebound FX™ Citrus Punch is a superior alternative to
    high-sodium, high carbohydrate sports drinks. It supplies quick sustained energy, while offering 
    a balance of antioxidants, natural herbs, and minerals that must be replenished after a hard 
    workout.</p> 
    <p>Features:</p>
    <ul>
      <li>Replenishes essential nutrients</li>
      <li>Supports exercise performance</li>
      <li>Great citrus taste</li>
    </ul>`
  },

  "energy.title": {
    id: "energy.title",
    description: "Title for energy targeted nutrition section",
    defaultMessage: "Energy"
  },
  "energy.description": {
    id: "energy.description",
    description: "Description for energy targeted nutrition section",
    defaultMessage: `Getting the energy you need to keep you going throughout the day can be difficult at times. 
    The last thing you want to do is rely on a high-sugar drink with artificial ingredients. We offer 
    all-natural alternatives to help you get that energy boost you’re looking for!`
  },

  "PJ330.name": {
    id: "PJ330.name",
    description: "Name for SKU#PJ330",
    defaultMessage: "Projoba Pollen Burst™"
  },
  "PJ330.tagline": {
    id: "PJ330.tagline",
    description: "Tagline for SKU#PJ330",
    defaultMessage:
      "A natural boost in energy plus powerful antioxidant support!"
  },
  "PJ330.summary": {
    id: "PJ330.summary",
    description: "Summary for SKU#PJ330",
    defaultMessage: `Pollen Burst™ is the world’s first energy drink to use flower pollen as a main source of energy
    plus antioxidant superoxide dismutase (SOD).`
  },
  "PJ330.description": {
    id: "PJ330.description",
    description: "Description for SKU#PJ330",
    defaultMessage: `<p>Pollen Burst is the world’s first energy drink powered by flower pollen. Featuring 
    Vitamin D and the powerful antioxidant superoxide dismutase (SOD), it helps boost energy levels and 
    fight damage caused by free radical toxins and environmental stresses.</p>
    <p>Features:</p>
    <ul>
      <li>Sustained energy</li>
      <li>Contains antioxidants & vitamin D</li>
      <li>Delicious, natural citrus taste</li>
    </ul>`
  },

  "PJ500.name": {
    id: "PJ500.name",
    description: "Name for SKU#PJ500",
    defaultMessage: "Projoba Pollen Burst™ Plus Berry"
  },
  "PJ500.tagline": {
    id: "PJ500.tagline",
    description: "Tagline for SKU#PJ500",
    defaultMessage:
      "A natural boost in energy plus powerful berry flavored antioxidant support!"
  },
  "PJ500.summary": {
    id: "PJ500.summary",
    description: "Summary for SKU#PJ500",
    defaultMessage: `Featuring flower pollen plus New Zealand Blackcurrant, this berry-flavored drink delivers a
    refreshing boost of energy.`
  },
  "PJ500.description": {
    id: "PJ500.description",
    description: "Description for SKU#PJ500",
    defaultMessage: `<p>Featuring New Zealand Blackcurrant and the powerful antioxidant, SOD, this natural 
    berry-flavored drink helps fight damage by free radicals, toxins, and other environmental stresses. 
    It also helps provide increased and sustained energy.</p>
    <p>Features:</p>
    <ul>
      <li>Sustained energy</li>
      <li>Contains antioxidants & vitamin D</li>
      <li>Berry & Blackcurrant flavor</li>
    </ul>`
  },

  "men-health.title": {
    id: "men-health.title",
    description: "Title for men-health targeted nutrition section",
    defaultMessage: "Men’s Health"
  },
  "men-health.description": {
    id: "men-health.description",
    description: "Description for men-health targeted nutrition section",
    defaultMessage: `Men require specific nutrient to help their body function optimally. We offer all-natural 
    solutions to support your health concerns.`
  },

  "20682.name": {
    id: "20682.name",
    description: "Name for SKU#20682",
    defaultMessage: "Ultimate Prost FX™"
  },
  "20682.tagline": {
    id: "20682.tagline",
    description: "Tagline for SKU#20682",
    defaultMessage:
      "Delivers antioxidant-rich nutrients and botanicals to support a healthy prostate."
  },
  "20682.summary": {
    id: "20682.summary",
    description: "Summary for SKU#20682",
    defaultMessage: `Ultimate Prost FX™ delivers nutritional support for men with botanicals and natural ingredients known to support a healthy prostate.`
  },
  "20682.description": {
    id: "20682.description",
    description: "Description for SKU#20682",
    defaultMessage: `<p>Ultimate Prost FX™ is specially formulated to support prostate health. Featuring saw palmetto fruit, selenium, pumpkin seed, 
    sarsaparilla root and lycopene, Ultimate Prost FX™ is an excellent source for men seeking nutritional answers to their prostate health 
    concerns.</p>
    <p>Features:</p>
    <ul>
      <li>All-natural support for men</li>
      <li>Supports a healthy prostate</li>
      <li>Featuring saw palmetto and selenium</li>
    </ul>`
  },

  "women-health.title": {
    id: "women-health.title",
    description: "Title for women-health targeted nutrition section",
    defaultMessage: "Women’s Health"
  },
  "women-health.description": {
    id: "women-health.description",
    description: "Description for women-health targeted nutrition section",
    defaultMessage: `Supporting healthy hormone balance and minimizing oxidative stress is a concern for many women. 
    We offer a nutritional formula that naturally supports a women’s endocrine system.`
  },

  "USYG100082.name": {
    id: "USYG100082.name",
    description: "Name for SKU#USYG100082",
    defaultMessage: "XeraFem™"
  },
  "USYG100082.tagline": {
    id: "USYG100082.tagline",
    description: "Tagline for SKU#USYG100082",
    defaultMessage:
      "Naturally restore estrogen and hormonal balance and combat oxidative stress."
  },
  "USYG100082.summary": {
    id: "USYG100082.summary",
    description: "Summary for SKU#USYG100082",
    defaultMessage: `XeraFem™ supplies all-natural, high-quality vitamins, minerals and botanicals that help promote
    better health and hormone production for women.`
  },
  "USYG100082.description": {
    id: "USYG100082.description",
    description: "Description for SKU#USYG100082",
    defaultMessage: `<p>XeraFem™ supplies all-natural, high-quality vitamins, minerals, and botanicals that help 
    promote hormone production for women. This natural approach to hormonal balance helps to nutritionally 
    restore estrogen and hormonal balance while also supporting powerful defenses against oxidative stresses 
    of aging naturally. XeraFem™ does not contain hormones.</p>
    <p>Features:</p>
    <ul>
      <li>All-natural hormonal support for women</li>
      <li>Imortalium® Youth Complex</li>
      <li>Supports comfort and balance</li>
    </ul>`
  },

  "stress.title": {
    id: "stress.title",
    description: "Title for stress targeted nutrition section",
    defaultMessage: "Stress and Sleep"
  },
  "stress.description": {
    id: "stress.description",
    description: "Description for stress targeted nutrition section",
    defaultMessage: `Getting a good night’s sleep is difficult for many individuals and is also 
    vital to your health. Give your body some support with nutrients that help the mind unwind 
    and promote sleep.`
  },

  "USYG102067.name": {
    id: "USYG102067.name",
    description: "Name for SKU#USYG102067",
    defaultMessage: "Sleep EZE™"
  },
  "USYG102067.tagline": {
    id: "USYG102067.tagline",
    description: "Tagline for SKU#USYG102067",
    defaultMessage:
      "A natural alternative for achieving an optimal sleep routine."
  },
  "USYG102067.summary": {
    id: "USYG102067.summary",
    description: "Summary for SKU#USYG102067",
    defaultMessage: `All-natural Sleep EZE™ is formulated with a proprietary blend of herbs and other 
    natural nutrients to support healthy, restful sleep.`
  },
  "USYG102067.description": {
    id: "USYG102067.description",
    description: "Description for SKU#USYG102067",
    defaultMessage: `<p>All-natural Sleep EZE™ is formulated with a proprietary blend of herbs and 
    other natural nutrients to support a healthy, restful sleep. Key ingredients in this 
    formula include GABA, Melatonin, Calcium, Magnesium and other soothing botanicals to help 
    calm your mind and body.</p>
    <p>Features:</p>
    <ul>
      <li>Soothing botanicals including melatonin</li>
      <li>5 HTP & GABA to support hormone production</li>
      <li>Essential minerals</li>
    </ul>`
  },

  "vitamins.title": {
    id: "vitamins.title",
    description: "Title for vitamins targeted nutrition section",
    defaultMessage: "Getting Enough Vitamins"
  },
  "vitamins.description": {
    id: "vitamins.description",
    description: "Description for vitamins targeted nutrition section",
    defaultMessage: `A healthy diet alone does not always guarantee essential vitamins and minerals. Our 
    Plant Derived Minerals™ come from a nutrient-rich source to help meet your needs.`
  },

  "13203.name": {
    id: "13203.name",
    description: "Name for SKU#13203",
    defaultMessage: "Plant Derived Minerals™"
  },
  "13203.tagline": {
    id: "13203.tagline",
    description: "Tagline for SKU#13203",
    defaultMessage:
      "The essential minerals recommended by Youngevity Founder, Dr. Wallach."
  },
  "13203.summary": {
    id: "13203.summary",
    description: "Summary for SKU#13203",
    defaultMessage: `Our Plant Derived Minerals™ concentrate contains up to 77 minerals from prehistoric
    plants in their unaltered colloidal form.`
  },
  "13203.description": {
    id: "13203.description",
    description: "Description for SKU#13203",
    defaultMessage: `<p>When dinosaurs roamed the earth 70 million years ago, they likely walked on 
    soil abundant with minerals. As a result, plants and fruits contained water-soluble minerals 
    that became part of a vibrant, life-sustaining food source. Our plant-derived products 
    contain up to 77 minerals from these prehistoric plants in their unaltered form.</p>
    <p>Features:</p>
    <ul>
      <li>Full spectrum, high quality minerals</li>
      <li>Essential minerals recommended by Dr. Wallach</li>
      <li>Mix with fruit or vegetable juice</li>
    </ul>`
  },

  "whole-foods.title": {
    id: "whole-foods.title",
    description: "Title for whole-foods targeted nutrition section",
    defaultMessage: "Nutrient-Dense Whole Foods"
  },
  "whole-foods.description": {
    id: "whole-foods.description",
    description: "Description for whole-foods targeted nutrition section",
    defaultMessage: `Getting your daily dose of leafy greens and other nutrient-rich foods doesn’t have 
    to be difficult. Just add a Super Greens™ drink to your daily meal plan.`
  },

  "USYG300001.name": {
    id: "USYG300001.name",
    description: "Name for SKU#USYG300001",
    defaultMessage: "Super Greens™"
  },
  "USYG300001.tagline": {
    id: "USYG300001.tagline",
    description: "Tagline for SKU#USYG300001",
    defaultMessage: "Support healthy digestion, pH levels and optimal health!"
  },
  "USYG300001.summary": {
    id: "USYG300001.summary",
    description: "Summary for SKU#USYG300001",
    defaultMessage: `Super Greens™ is a nutrient dense, easy-to-mix powder made from the world’s most 
    powerful superfoods with real, organic whole fruits, vegetables, and herbs.`
  },
  "USYG300001.description": {
    id: "USYG300001.description",
    description: "Description for SKU#USYG300001",
    defaultMessage: `<p>Super Greens™ is a nutrient dense powder made from the world's most potent 
    superfoods. Deliciously flavored with refreshing organic peppermint and spearmint, this highly 
    alkaline formula may help support digestion and overall pH levels. Now you can get more green, 
    leafy vegetables and fruit with just one scoop per day.</p>
    <p>Features:</p>
    <ul>
      <li>Organic fruits and green vegetables</li>
      <li>Probiotics and enzymes to support digestion</li>
      <li>Great way to get extra veggies</li>
    </ul>`
  },

  "anti-aging.title": {
    id: "anti-aging.title",
    description: "Title for anti-aging targeted nutrition section",
    defaultMessage: "Anti-aging Needs"
  },
  "anti-aging.description": {
    id: "anti-aging.description",
    description: "Description for anti-aging targeted nutrition section",
    defaultMessage: `Helping your body combat free radical damage may also help your cells live longer. 
    Youngevity offers the most advanced anti-aging supplement that is rich in antioxidants and other 
    nutrients that support anti-aging.`
  },

  "USYG100080.name": {
    id: "USYG100080.name",
    description: "Name for SKU#USYG100080",
    defaultMessage: "Immortalium®"
  },
  "USYG100080.tagline": {
    id: "USYG100080.tagline",
    description: "Tagline for SKU#USYG100080",
    defaultMessage:
      "Nutritional support for the biological clocks inside every cell."
  },
  "USYG100080.summary": {
    id: "USYG100080.summary",
    description: "Summary for SKU#USYG100080",
    defaultMessage: `This cutting-edge formula delivers anti-aging nutrients that help support the lifespan 
    of telomeres; the biological clocks in almost every cell.`
  },
  "USYG100080.description": {
    id: "USYG100080.description",
    description: "Description for SKU#USYG100080",
    defaultMessage: `<p>Imortalium® is the most advanced anti-aging supplement to date that 
    nutritionally helps extend the lifespan of telomeres; the biological clocks found in almost 
    every cell. The cutting-edge, bi-layered tablets allow anti-aging nutrients to be released both 
    rapidly and slowly for maximum exposure to antioxidant enzymes, and essential nutrients that 
    support optimal telomere health.</p>
    <p>Features:</p>
    <ul>
      <li>Advanced anti-aging support</li>
      <li>Trans Resveratrol & Regnerally™ Brand SOD</li>
      <li>Skin – rejuvenating whole food blend</li>
    </ul>`
  },

  "immune-system.title": {
    id: "immune-system.title",
    description: "Title for immune-system targeted nutrition section",
    defaultMessage: "Build a Better Immune System"
  },
  "immune-system.description": {
    id: "immune-system.description",
    description: "Description for immune-system targeted nutrition section",
    defaultMessage: `A healthy immune system helps protect the body from harmful substances. Antioxidants 
    are vital to helping the body fight off the harmful substances- such as free radicals. See what 
    products we recommend to support your immune system.`
  },

  "USYG100088.name": {
    id: "USYG100088.name",
    description: "Name for SKU#USYG100088",
    defaultMessage: "Pürmeric™"
  },
  "USYG100088.tagline": {
    id: "USYG100088.tagline",
    description: "Tagline for SKU#USYG100088",
    defaultMessage:
      "Organic botanicals and curcuminoids in an enhanced organic capsule."
  },
  "USYG100088.summary": {
    id: "USYG100088.summary",
    description: "Summary for SKU#USYG100088",
    defaultMessage: `Pürmeric™ is made from the highest quality, certified organic curcumin available, 
    delivering superior antioxidant activity and synergistic phytonutrients.`
  },
  "USYG100088.description": {
    id: "USYG100088.description",
    description: "Description for SKU#USYG100088",
    defaultMessage: `<p>By delivering 95% curcuminoids, this herbal supplement helps promote optimal 
    health and combat oxidation in the body. It’s easily absorbable and contains organic 
    botanicals, which typically have more phytonutrients than non-organic botanicals, making them 
    more beneficial to your health. Pürmeric™ is made from the highest quality, certified organic 
    curcumin available, delivering superior antioxidant support.</p>
    <p>Features:</p>
    <ul>
      <li>Pure, certified organic turmeric root</li>
      <li>Combats free radical damage</li>
      <li>Supports a healthy immune system</li>
    </ul>`
  },

  "3026.name": {
    id: "3026.name",
    description: "Name for SKU#3026",
    defaultMessage: "ZRadical™"
  },
  "3026.tagline": {
    id: "3026.tagline",
    description: "Tagline for SKU#3026",
    defaultMessage:
      "Boost immune system and support your overall health with a great-tasting beverage."
  },
  "3026.summary": {
    id: "3026.summary",
    description: "Summary for SKU#3026",
    defaultMessage: `ZRadical™ delivers a proprietary blend that features 50 mg of pure fucoidan – an 
    antioxidant-rich sulfated polysaccharide.`
  },
  "3026.description": {
    id: "3026.description",
    description: "Description for SKU#3026",
    defaultMessage: `<p>Combining the best of nature and science, ZRadical™ is an advanced biotherapeutic 
    drink containing fucoidan, which help support immune, cardiovascular, neurological, and joint 
    health. The proprietary blend delivers 50 mg of pure fucoidan in a great-tasting, antioxidant-rich 
    beverage.</p>
    <p>Features:</p>
    <ul>
      <li>Derived from pristine ocean water</li>
      <li>Promotes a healthy immune system</li>
      <li>Delicious berry taste</li>
    </ul>`
  },

  "circulation.title": {
    id: "circulation.title",
    description: "Title for circulation targeted nutrition section",
    defaultMessage: "Circulation and Blood Vessels"
  },
  "circulation.description": {
    id: "circulation.description",
    description: "Description for circulation targeted nutrition section",
    defaultMessage: `The cardiovascular system consists of many vascular networks that work together to help 
    it preform optimally. Our targeted formula helps address the entire vascular system delivering 
    advanced cardiovascular support.`
  },

  "USYG100093.name": {
    id: "USYG100093.name",
    description: "Name for SKU#USYG100093",
    defaultMessage: "ProCardio FX™"
  },
  "USYG100093.tagline": {
    id: "USYG100093.tagline",
    description: "Tagline for SKU#USYG100093",
    defaultMessage:
      "Unique combination of cardiovascular-supporting nutrients, in one convenient soft-gel."
  },
  "USYG100093.summary": {
    id: "USYG100093.summary",
    description: "Summary for SKU#USYG100093",
    defaultMessage: `ProCardio FX™ was developed to deliver targeted nutrition to the complete vascular system
    with synergizing nutrients that help optimize nutrient absorption.`
  },
  "USYG100093.description": {
    id: "USYG100093.description",
    description: "Description for SKU#USYG100093",
    defaultMessage: `<p>ProCardio FX™ delivers a diverse blend of cardiovascular-supporting nutrients. 
    Specifically, it supports healthy blood vessels for optimal blood flow, circulation, and energy 
    production within the body. It also helps protect cells from free radical damage with 
    antioxidant-rich nutrients like selenium, turmeric, and resveratrol.</p>
    <p>Features:</p>
    <ul>
      <li>1000 mg of fish oil</li>
      <li>Synergizing, antioxidant-rich botanicals</li>
      <li>Broad spectrum cardiovascular support</li>
    </ul>`
  },

  "eye-health.title": {
    id: "eye-health.title",
    description: "Title for eye-health targeted nutrition section",
    defaultMessage: "Eye Health"
  },
  "eye-health.description": {
    id: "eye-health.description",
    description: "Description for eye-health targeted nutrition section",
    defaultMessage: `Your vision is vital to your daily function. Youngevity offers an advanced formula to 
    support all aspects of ocular health.`
  },

  "USYG100086.name": {
    id: "USYG100086.name",
    description: "Name for SKU#USYG100086",
    defaultMessage: "Ocutiv™"
  },
  "USYG100086.tagline": {
    id: "USYG100086.tagline",
    description: "Tagline for SKU#USYG100086",
    defaultMessage:
      "Support ocular health with an advanced antioxidant-rich capsule."
  },
  "USYG100086.summary": {
    id: "USYG100086.summary",
    description: "Summary for SKU#USYG100086",
    defaultMessage: `Ocutiv™ is the most comprehensive eye health supplement to date with nutrients that 
    help combat oxidative damage as well as helps soothe eye discomfort and irritation.`
  },
  "USYG100086.description": {
    id: "USYG100086.description",
    description: "Description for SKU#USYG100086",
    defaultMessage: `<p>With targeted vitamins, minerals, antioxidants, and whole foods, Ocutiv™ 
    nutritionally supports advanced ocular health. Our most comprehensive eye health supplement 
    yet, it helps combat oxidative damage and soothe discomfort and irritation.</p>
    <p>Features:</p>
    <ul>
      <li>Powerful antioxidants, vitamins and minerals</li>
      <li>Promotes eye comfort</li>
      <li>Includes active carotenoids</li>
    </ul>`
  },

  "joint-mobility.title": {
    id: "joint-mobility.title",
    description: "Title for joint-mobility targeted nutrition section",
    defaultMessage: "Joint Mobility and Comfort"
  },
  "joint-mobility.description": {
    id: "joint-mobility.description",
    description: "Description for joint-mobility targeted nutrition section",
    defaultMessage: `Joint care is a major concern for many individuals – from the most active to aging adults. 
    Supporting the entire joint structure with an nutrient-rich formula may help improve joint health and 
    promote a strong foundation.`
  },

  "USYG100092.name": {
    id: "USYG100092.name",
    description: "Name for SKU#USYG100092",
    defaultMessage: "ProJoint FX™"
  },
  "USYG100092.tagline": {
    id: "USYG100092.tagline",
    description: "Tagline for SKU#USYG100092",
    defaultMessage:
      "Support optimal joint health with a synergizing blend of nutrients."
  },
  "USYG100092.summary": {
    id: "USYG100092.summary",
    description: "Summary for SKU#USYG100092",
    defaultMessage: `This comprehensive formula looks at all aspects of joint care and provides targeted 
    nutrition for the entire joint structure; ligaments, tendons, cartilage, and tissue surrounding 
    the joint.`
  },
  "USYG100092.description": {
    id: "USYG100092.description",
    description: "Description for SKU#USYG100092",
    defaultMessage: `<p>ProJoint FX™ supports healthy joints that promote flexibility, mobility and 
    movement. The nutrients in this formula may help combat inflammation to deliver relief to the 
    joint while also promoting healthy ligaments, tendons and cartilage.</p>
    <p>Features:</p>
    <ul>
      <li>Joint rebuild nutrient complex</li>
      <li>Antioxidant-rich joint relief complex</li>
      <li>Supports comprehensive joint care</li>
    </ul>`
  },

  "mental-focus.title": {
    id: "mental-focus.title",
    description: "Title for mental-focus targeted nutrition section",
    defaultMessage: "Mental Focus & Memory"
  },
  "mental-focus.description": {
    id: "mental-focus.description",
    description: "Description for mental-focus targeted nutrition section",
    defaultMessage: `With so much going on, it can be hard to get your brain to stay on task. Additionally, 
    stress can impact your brain function. We offer a cutting-edge formula to support short term and 
    long-term focus and memory.`
  },

  "USYG100083.name": {
    id: "USYG100083.name",
    description: "Name for SKU#USYG100083",
    defaultMessage: "Synaptiv™"
  },
  "USYG100083.tagline": {
    id: "USYG100083.tagline",
    description: "Tagline for SKU#USYG100083",
    defaultMessage:
      "All-natural antioxidant neuroprotection and brain function support."
  },
  "USYG100083.summary": {
    id: "USYG100083.summary",
    description: "Summary for SKU#USYG100083",
    defaultMessage: `Synaptiv™ promotes mental clarity and decreased stress levels while also providing
    extended support to help combat free-radicals for optimal brain health.`
  },
  "USYG100083.description": {
    id: "USYG100083.description",
    description: "Description for SKU#USYG100083",
    defaultMessage: `<p>Synaptiv helps provide mental clarity and decrease stress levels, while 
    providing extended support to help combat free-radicals. As a result, this scientifically 
    developed formula helps support both immediate and long-term brain health in a unique 
    bi-layered tablet.</p>
    <p>Features:</p>
    <ul>
      <li>Measurable neuronal-stress combating activity</li>
      <li>Supports optimal cognitive activity</li>
      <li>Youngevity full spectrum antioxidant blend</li>
    </ul>`
  }
});

export const moreRecommendations = [
  // title: 'Do you have any physical wellness goals?'
  {
    id: "weight",
    title: recoMessages["weight.title"],
    description: recoMessages["weight.description"],
    products: [
      {
        name: recoMessages["10285.name"],
        tagline: recoMessages["10285.tagline"],
        summary: recoMessages["10285.summary"],
        description: recoMessages["10285.description"],
        image: image10285,
        retail: { "en-US": { currency: "USD", amount: 273.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 218.95 } },
        sku: "10285",
        availability: ['US', 'CA'],
      },
      {
        name: recoMessages["10283.name"],
        tagline: recoMessages["10283.tagline"],
        summary: recoMessages["10283.summary"],
        description: recoMessages["10283.description"],
        image: image10283,
        retail: { "en-US": { currency: "USD", amount: 246.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 197.95 } },
        sku: "10283",
        availability: ['US', 'CA'],
      },
      {
        name: recoMessages["10284.name"],
        tagline: recoMessages["10284.tagline"],
        summary: recoMessages["10284.summary"],
        description: recoMessages["10284.description"],
        image: image10284,
        retail: { "en-US": { currency: "USD", amount: 274.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 219.95 } },
        sku: "10284",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "muscle",
    title: recoMessages["muscle.title"],
    description: recoMessages["muscle.description"],
    products: [
      {
        name: recoMessages["USYG300002.name"],
        tagline: recoMessages["USYG300002.tagline"],
        summary: recoMessages["USYG300002.summary"],
        description: recoMessages["USYG300002.description"],
        image: imageUSYG300002,
        retail: { "en-US": { currency: "USD", amount: 74.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 59.95 } },
        sku: "USYG300002",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "exercise",
    title: recoMessages["exercise.title"],
    description: recoMessages["exercise.description"],
    products: [
      {
        name: recoMessages["USYG100084.name"],
        tagline: recoMessages["USYG100084.tagline"],
        summary: recoMessages["USYG100084.summary"],
        description: recoMessages["USYG100084.description"],
        image: imageUSYG100084,
        retail: { "en-US": { currency: "USD", amount: 54.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 43.95 } },
        sku: "USYG100084",
        availability: ['US'],
      },
      {
        name: recoMessages["13223.name"],
        tagline: recoMessages["13223.tagline"],
        summary: recoMessages["13223.summary"],
        description: recoMessages["13223.description"],
        image: image13223,
        retail: { "en-US": { currency: "USD", amount: 51.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 41.95 } },
        sku: "13223",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "energy",
    title: recoMessages["energy.title"],
    description: recoMessages["energy.description"],
    products: [
      {
        name: recoMessages["PJ330.name"],
        tagline: recoMessages["PJ330.tagline"],
        summary: recoMessages["PJ330.summary"],
        description: recoMessages["PJ330.description"],
        image: imagePJ330,
        retail: { "en-US": { currency: "USD", amount: 81.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 65.95 } },
        sku: "PJ330",
        availability: ['US', 'CA'],
      },
      {
        name: recoMessages["PJ500.name"],
        tagline: recoMessages["PJ500.tagline"],
        summary: recoMessages["PJ500.summary"],
        description: recoMessages["PJ500.description"],
        image: imagePJ500,
        retail: { "en-US": { currency: "USD", amount: 81.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 65.95 } },
        sku: "PJ500",
        availability: ['US', 'CA'],
      }
    ]
  },
  // title: 'Do you have any hormonal or stress related goals?'
  {
    id: "men-health",
    title: recoMessages["men-health.title"],
    description: recoMessages["men-health.description"],
    products: [
      {
        name: recoMessages["20682.name"],
        tagline: recoMessages["20682.tagline"],
        summary: recoMessages["20682.summary"],
        description: recoMessages["20682.description"],
        image: image20682,
        retail: { "en-US": { currency: "USD", amount: 50.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 40.95 } },
        sku: "20682",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "women-health",
    title: recoMessages["women-health.title"],
    description: recoMessages["women-health.description"],
    products: [
      {
        name: recoMessages["USYG100082.name"],
        tagline: recoMessages["USYG100082.tagline"],
        summary: recoMessages["USYG100082.summary"],
        description: recoMessages["USYG100082.description"],
        image: imageUSYG100082,
        retail: { "en-US": { currency: "USD", amount: 54.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 43.95 } },
        sku: "USYG100082",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "stress",
    title: recoMessages["stress.title"],
    description: recoMessages["stress.description"],
    products: [
      {
        name: recoMessages["USYG102067.name"],
        tagline: recoMessages["USYG102067.tagline"],
        summary: recoMessages["USYG102067.summary"],
        description: recoMessages["USYG102067.description"],
        image: imageUSYG102067,
        retail: { "en-US": { currency: "USD", amount: 51.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 41.95 } },
        sku: "USYG102067",
        availability: ['US', 'CA'],
      }
    ]
  },
  // title: 'Do you have any advanced nutrition goals?'
  {
    id: "vitamins",
    title: recoMessages["vitamins.title"],
    description: recoMessages["vitamins.description"],
    products: [
      {
        name: recoMessages["13203.name"],
        tagline: recoMessages["13203.tagline"],
        summary: recoMessages["13203.summary"],
        description: recoMessages["13203.description"],
        image: image13203,
        retail: { "en-US": { currency: "USD", amount: 29.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 23.95 } },
        sku: "13203",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "whole-foods",
    title: recoMessages["whole-foods.title"],
    description: recoMessages["whole-foods.description"],
    products: [
      {
        name: recoMessages["USYG300001.name"],
        tagline: recoMessages["USYG300001.tagline"],
        summary: recoMessages["USYG300001.summary"],
        description: recoMessages["USYG300001.description"],
        image: imageUSYGY300001,
        retail: { "en-US": { currency: "USD", amount: 64.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 51.95 } },
        sku: "USYG300001",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "anti-aging",
    title: recoMessages["anti-aging.title"],
    description: recoMessages["anti-aging.description"],
    products: [
      {
        name: recoMessages["USYG100080.name"],
        tagline: recoMessages["USYG100080.tagline"],
        summary: recoMessages["USYG100080.summary"],
        description: recoMessages["USYG100080.description"],
        image: imageUSYGY100080,
        retail: { "en-US": { currency: "USD", amount: 85.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 68.95 } },
        sku: "USYG100080",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "immune-system",
    title: recoMessages["immune-system.title"],
    description: recoMessages["immune-system.description"],
    products: [
      {
        name: recoMessages["USYG100088.name"],
        tagline: recoMessages["USYG100088.tagline"],
        summary: recoMessages["USYG100088.summary"],
        description: recoMessages["USYG100088.description"],
        image: imageUSYGY100088,
        retail: { "en-US": { currency: "USD", amount: 85.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 68.95 } },
        sku: "USYG100088",
        availability: ['US', 'CA'],
      },
      {
        name: recoMessages["3026.name"],
        tagline: recoMessages["3026.tagline"],
        summary: recoMessages["3026.summary"],
        description: recoMessages["3026.description"],
        image: image30300,
        retail: { "en-US": { currency: "USD", amount: 54.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 43.95 } },
        sku: "3026",
        availability: ['US', 'CA'],
      }
    ]
  },
  // title: 'Do you have any targeted health goals?'
  {
    id: "circulation",
    title: recoMessages["circulation.title"],
    description: recoMessages["circulation.description"],
    products: [
      {
        name: recoMessages["USYG100093.name"],
        tagline: recoMessages["USYG100093.tagline"],
        summary: recoMessages["USYG100093.summary"],
        description: recoMessages["USYG100093.description"],
        image: imageUSYGY100093,
        retail: { "en-US": { currency: "USD", amount: 60.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 48.95 } },
        sku: "USYG100093",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "eye-health",
    title: recoMessages["eye-health.title"],
    description: recoMessages["eye-health.description"],
    products: [
      {
        name: recoMessages["USYG100086.name"],
        tagline: recoMessages["USYG100086.tagline"],
        summary: recoMessages["USYG100086.summary"],
        description: recoMessages["USYG100086.description"],
        image: imageUSYGY100086,
        retail: { "en-US": { currency: "USD", amount: 85.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 68.95 } },
        sku: "USYG100086",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "joint-mobility",
    title: recoMessages["joint-mobility.title"],
    description: recoMessages["joint-mobility.description"],
    products: [
      {
        name: recoMessages["USYG100092.name"],
        tagline: recoMessages["USYG100092.tagline"],
        summary: recoMessages["USYG100092.summary"],
        description: recoMessages["USYG100092.description"],
        image: imageUSYGY100092,
        retail: { "en-US": { currency: "USD", amount: 74.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 59.95 } },
        sku: "USYG100092",
        availability: ['US', 'CA'],
      }
    ]
  },
  {
    id: "mental-focus",
    title: recoMessages["mental-focus.title"],
    description: recoMessages["mental-focus.description"],
    products: [
      {
        name: recoMessages["USYG100083.name"],
        tagline: recoMessages["USYG100083.tagline"],
        summary: recoMessages["USYG100083.summary"],
        description: recoMessages["USYG100083.description"],
        image: imageUSYGY100083,
        retail: { "en-US": { currency: "USD", amount: 79.99 } },
        wholesale: { "en-US": { currency: "USD", amount: 63.95 } },
        sku: "USYG100083",
        availability: ['US', 'CA'],
      }
    ]
  }
];