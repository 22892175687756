import React, { Component } from 'react';
import { connect } from 'react-redux';
import hoistNonReactStatic from "hoist-non-react-statics";

import EB from './ErrorBoundary';
import { logErrorOnServer, resetAppState } from '../../state';

const ErrorBoundary =  connect(null, { logErrorOnServer, resetAppState })(EB);

export default ErrorBoundary;

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function withErrorBoundary(WrappedComponent) {
  class WithErrorBoundary extends Component {
    render() {
      return (
        <ErrorBoundary>
          <WrappedComponent {...this.props} />
        </ErrorBoundary>
      )
    }
  }
  WithErrorBoundary.displayName = `WithErrorBoundary(${getDisplayName(WrappedComponent)})`;
  hoistNonReactStatic(WithErrorBoundary, WrappedComponent);
  return WithErrorBoundary;
}
