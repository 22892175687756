import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import classnames from "classnames";
import { withRouter, Redirect } from "react-router";

import StartPage from "./pages/StartPage";
import NamePage from "./pages/NamePage";
import SummaryPage from "./pages/SummaryPage";
import PromptPage from "./pages/PromptPage";
import QuestionPage from "./pages/QuestionPage";
import QuizRunner from "./pages/QuizRunner";
import ResultsPage from "./pages/ResultsPage";
import DebugPage from "./pages/DebugPage";
import AllProductsPage from "./pages/AllProductsPage";
import DistributorPage from "./pages/DistributorPage";
import ReturningPage from "./pages/ReturningPage";
import MotivationPage from "./pages/MotivationPage";

import { Container } from "./components/rb-import";
import ScrollToTopOnMount from "./components/ScrollToTopOnMount";

import style from "./App.module.scss";

class AppRouter extends Component {
  render() {
    const { showingConfig, showingCart, location } = this.props;

    const productionEnv = process.env.NODE_ENV === "production";

    return (
      <ScrollToTopOnMount>
        <Container
          className={classnames(
            style.content,
            {
              [style.yam]: showingConfig,
              [style.yammo]: showingCart
            },
            style[location.pathname.slice(1)]
          )}
        >
          <Switch key="sw">
            <Route path="/name" component={NamePage} />
            <Route path="/runner" component={QuizRunner} />
            <Route path="/summary" component={SummaryPage} />
            <Route path="/prompt" component={PromptPage} />
            <Route path="/question" component={QuestionPage} />
            <Route path="/results" component={ResultsPage} />
            <Route path="/all" component={AllProductsPage} />
            <Route
              path="/distributor"
              component={DistributorPage}
            />
            <Route path="/returning" component={ReturningPage} />
            <Route path="/motive" component={MotivationPage} />
            {!productionEnv && (
              <Route path="/debug" exact component={DebugPage} />
            )}
            <Route path="/quiz" render={() => <Redirect to="/" />} />
            <Route path="/" component={StartPage} />
          </Switch>
        </Container>
      </ScrollToTopOnMount>
    );
  }
}

export default withRouter(AppRouter);
