import React, { Component } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import ReactGA from 'react-ga';

import { Container, Row, Col, ListGroup, Image } from "../rb-import";

import style from "../../App.module.scss";

import usFlag from "../../images/flag-us.svg";
import caFlag from "../../images/flag-ca.svg";

const displayLanguage = lang => {
  switch (lang) {
    case "en":
      return "English";
    case "es":
      return "Español";
    default:
      return "English";
  }
};

const displayCountry = country => {
  switch (country) {
    case "US":
      return "United States";
    case "CA":
      return "Canada";
    default:
      return "United States";
  }
};

const displayFlag = country => {
  switch (country) {
    case "US":
      return usFlag;
    case "CA":
      return caFlag;
    default:
      return usFlag;
  }
};

class Config extends Component {
  setLocale = locale => {
    const { setLocale } = this.props;
    setLocale(locale);
    ReactGA.event({
      category: "Config",
      action: locale
    });
  };

  render() {
    const { language, country, supportedLanguages } = this.props;

    return (
      <Container fluid>
        <Row>
          <Col style={{ paddingLeft: 0, paddingRight: 0 }}>
            <ListGroup className={style.noradius}>
              <ListGroup.Item
                className={classnames(style.title, style.noradius)}
              >
                <Image src={displayFlag(country)} />
                <div>
                  {displayCountry(country)} | {displayLanguage(language)}
                </div>
              </ListGroup.Item>
              <ListGroup.Item className={style.gray}>
                <FormattedMessage
                  id="config.country"
                  description="Country title"
                  defaultMessage="COUNTRY"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setLocale(`${language}-US`)}
              >
                {country === "US" && (
                  <>
                    <FontAwesomeIcon icon="check" />{" "}
                  </>
                )}
                <FormattedMessage
                  id="config.us"
                  description="United States"
                  defaultMessage="United States"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setLocale(`${language}-CA`)}
              >
                {country === "CA" && (
                  <>
                    <FontAwesomeIcon icon="check" />{" "}
                  </>
                )}
                <FormattedMessage
                  id="config.canada"
                  description="Canada"
                  defaultMessage="Canada"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
              <ListGroup.Item className={style.gray}>
                <FormattedMessage
                  id="config.language"
                  description="Language Title"
                  defaultMessage="LANGUAGE"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
              <ListGroup.Item
                action
                onClick={() => this.setLocale(`en-${country}`)}
                disabled={supportedLanguages.indexOf("en") === -1}
              >
                {language === "en" && (
                  <>
                    <FontAwesomeIcon icon="check" />{" "}
                  </>
                )}
                <FormattedMessage
                  id="config.english"
                  description="English language"
                  defaultMessage="English"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
              <ListGroup.Item
                className={style.noradius}
                action
                onClick={() => this.setLocale(`es-${country}`)}
                disabled={supportedLanguages.indexOf("es") === -1}
              >
                {language === "es" && (
                  <>
                    <FontAwesomeIcon icon="check" />{" "}
                  </>
                )}
                <FormattedMessage
                  id="config.spanish"
                  description="Spanish language"
                  defaultMessage="Español"
                >
                  {text => text}
                </FormattedMessage>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Config;
