import { connect } from 'react-redux';

import { getName, resetQuiz, getQuizStatus } from '../../state';
import NamePage from './ReturningPage';

export const mapStateToProps = state => ({
  name: getName(state),
  completed: getQuizStatus(state) === 'completed',
});

/**
 * Redux connector for the NamePage component
 */
export default connect(mapStateToProps, { resetQuiz })(NamePage);
