import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";
import ReactGA from "react-ga";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";

import QuizToggle from "../toggle";

import veryPoor from "./ratings/reallypoor.svg";
import veryPoorSelected from "./ratings/reallypoor-selected.svg";
import poor from "./ratings/poor.svg";
import poorSelected from "./ratings/poor-selected.svg";
import fair from "./ratings/fair.svg";
import fairSelected from "./ratings/fair-selected.svg";
import good from "./ratings/good.svg";
import goodSelected from "./ratings/good-selected.svg";
import great from "./ratings/great.svg";
import greatSelected from "./ratings/great-selected.svg";

import style from "../question.module.scss";

const ratingLabels = defineMessages({
  veryPoor: {
    id: "single.verypoor",
    description: "Label for very poor button",
    defaultMessage: "Really Poor"
  },
  poor: {
    id: "single.poor",
    description: "Label for poor button",
    defaultMessage: "Poor"
  },
  fair: {
    id: "single.fair",
    description: "Label for fair button",
    defaultMessage: "Fair"
  },
  good: {
    id: "single.good",
    description: "Label for good button",
    defaultMessage: "Good"
  },
  great: {
    id: "single.great",
    description: "Label for very great button",
    defaultMessage: "Great"
  }
});

/**
 * Presents the portion of the QuestionPage responsible for handling a single-
 * choice question.
 */
class SingleChoice extends Component {
  constructor(props) {
    super(props);
    this.clicking = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      ReactGA.pageview(`/runner/${this.props.question.id}`);
    }
  }

  /**
   * Handles click events that are generated when the user clicks on a rating button,
   * This routine logs the current answer and then calls the onClick handler.
   *
   * @param {Number} value rating selected by the user
   */
  handleClick = value => {
    const {
      question: { id },
      logAnswer,
      onNavigate
    } = this.props;

    if (!this.clicking) {
      this.clicking = true;
      if (value === -1) {
        onNavigate("prev");
      } else {
        return Promise.resolve(logAnswer(id, value)).then(() => {
          // Need the timeout here for visual effect only...
          setTimeout(() => {
            onNavigate("next");
            this.clicking = false;
          }, 250);
        });
      }
    }
  };

  /**
   * Displays the title, subtitle, body and rating buttons.
   */
  render() {
    const {
      question: { title, subtitle, body, tip, didYouKnow },
      answer,
      intl
    } = this.props;
    return (
      <Fragment>
        <div className={style.title}>{intl.formatMessage(title)}</div>
        <div className={style.subtitle}>
          <FontAwesomeIcon icon={faLightbulb} style={{ color: "#ec6529" }} />{" "}
          {intl.formatMessage(subtitle)}
        </div>
        <div className={style.body}>
          {body && intl.formatMessage(body)}{" "}
          {tip && (
            <Fragment>
              <div className={style.tipdyk}>
                <FormattedMessage
                  id="single.tip"
                  description="Tip title (inline)"
                  defaultMessage="Tip:"
                >
                  {text => text}
                </FormattedMessage>
              </div>{" "}
              {intl.formatMessage(tip)}
            </Fragment>
          )}{" "}
          {didYouKnow && (
            <Fragment>
              <div className={style.tipdyk}>
                <FormattedMessage
                  id="single.dyk"
                  description="Did you know title (inline)"
                  defaultMessage="Did You Know:"
                >
                  {text => text}
                </FormattedMessage>
              </div>{" "}
              {intl.formatMessage(didYouKnow)}
            </Fragment>
          )}
        </div>
        <div className={style.landback} onClick={() => this.handleClick(-1)}>
          <FontAwesomeIcon icon="chevron-left" />
        </div>
        <div className={style.ratings}>
          <QuizToggle
            image={veryPoor}
            imageSelected={veryPoorSelected}
            selected={answer === 5}
            text={ratingLabels.veryPoor}
            onClick={() => this.handleClick(5)}
          />
          <QuizToggle
            image={poor}
            imageSelected={poorSelected}
            selected={answer === 4}
            text={ratingLabels.poor}
            onClick={() => this.handleClick(4)}
          />
          <QuizToggle
            image={fair}
            imageSelected={fairSelected}
            selected={answer === 3}
            text={ratingLabels.fair}
            onClick={() => this.handleClick(3)}
          />
          <QuizToggle
            image={good}
            imageSelected={goodSelected}
            selected={answer === 2}
            text={ratingLabels.good}
            onClick={() => this.handleClick(2)}
          />
          <QuizToggle
            image={great}
            imageSelected={greatSelected}
            selected={answer === 1}
            text={ratingLabels.great}
            onClick={() => this.handleClick(1)}
          />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(SingleChoice);
