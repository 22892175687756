import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { OverlayTrigger } from '../../../components/rb-import';

import Gauge from '../../../components/Gauge';
import UpdatingPopover from '../../../components/UpdatingPopover';

import style from './interest.module.scss';
import './popover.scss';

const levelStrings = defineMessages({
  veryLow: {
    id: 'interest.level.veryLow',
    description: 'Label for VERY LOW interest level',
    defaultMessage: 'VERY LOW',
  },
  low: {
    id: 'interest.level.low',
    description: 'Label for LOW interest level',
    defaultMessage: 'LOW',
  },
  moderate: {
    id: 'interest.level.moderate',
    description: 'Label for MODERATE interest level',
    defaultMessage: 'MODERATE',
  },
  high: {
    id: 'interest.level.high',
    description: 'Label for HIGH interest level',
    defaultMessage: 'HIGH',
  },
  veryHigh: {
    id: 'interest.level.veryHigh',
    description: 'Label for VERY HIGH interest level',
    defaultMessage: 'VERY HIGH',
  },
});

const levels = [
  levelStrings.veryLow,
  levelStrings.low,
  levelStrings.moderate,
  levelStrings.high,
  levelStrings.veryHigh,
];

const ratingsStrings = defineMessages({
  great: {
    id: 'rating.great',
    description: 'Label for Great rating',
    defaultMessage: 'Great',
  },
  good: {
    id: 'rating.good',
    description: 'Label for Good rating',
    defaultMessage: 'Good',
  },
  fair: {
    id: 'rating.fair',
    description: 'Label for Fair rating',
    defaultMessage: 'Fair',
  },
  poor: {
    id: 'rating.poor',
    description: 'Label for Poor rating',
    defaultMessage: 'Poor',
  },
  reallyPoor: {
    id: 'rating.reallypoor',
    description: 'Label for Really Poor rating',
    defaultMessage: 'Really Poor',
  },
});

// TODO Write test to ensure proper rating value is returned
const ratings = [
  '',
  ratingsStrings.great,
  ratingsStrings.good,
  ratingsStrings.fair,
  ratingsStrings.poor,
  ratingsStrings.reallyPoor,
];

export const getRating = (score, intl) => intl.formatMessage(ratings[score]);
export const getLevel = (level, intl) => intl.formatMessage(levels[level])
export const getReasonText = (reason, intl) => intl.formatMessage(reason);

class InterestGauge extends Component {
  render() {
    const { num, reason, intl } = this.props;

    if (num < 0 || num > 4) {
      throw Error(`Invalid interest level: ${num}`);
    }
  
    return (
      <div className={style.interest}>
        <div className={style.title}>
          <FormattedMessage
            id="pak.interest.title"
            description="Title for interest gauge"
            defaultMessage="YOUR INTEREST&nbsp;"
          >
            {(text) => text}
          </FormattedMessage>
          {' '}
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={(
              <UpdatingPopover 
                id="reason-popover" 
                title={
                  <FormattedMessage
                    id="pak.interest.youranswers"
                    description="Title for reasons popover"
                    defaultMessage="Your answers"
                  >
                    {(text) => text}
                  </FormattedMessage>
                }
              >
                {reason.map((r) => (
                  <div className="row" key={r.id}>
                    <div className="question">
                      {getReasonText(r.question, intl)}
                    </div>
                    <div className="answer">
                      {/* <Image src={getRating(r.answer)} className="mb-3" /> */}
                      {getRating(r.answer, intl)}
                    </div>
                  </div>
                ))}
              </UpdatingPopover>
            )}
          >
            <FontAwesomeIcon
              icon="info-circle"
              style={{ color: '#EC6529' }}
            />
          </OverlayTrigger>
        </div>
        <Gauge num={num} className={style.gauge} />
        <div className={style.level}>{getLevel(num, intl)}</div>
      </div>
    );
  }
}

export default injectIntl(InterestGauge);
