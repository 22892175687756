import React, { Component, Fragment } from "react";
import classnames from "classnames";
import ReactGA from "react-ga";
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { Row, Col } from "../../components/rb-import";
import Header from "../../components/Header";

import style from "./summary.module.scss";

const segNames = defineMessages({
  joints: {
    id: 'summary.seg.joints',
    description: 'Title for joints summary screen',
    defaultMessage: 'Joints, Bones & Teeth',
  },
  energy: {
    id: 'summary.seg.energy',
    description: 'Title for metabolism & energy summary screen',
    defaultMessage: 'Metabolism & Energy',
  },
  brain: {
    id: 'summary.seg.brain',
    description: 'Title for Brain & Heart Health summary screen',
    defaultMessage: 'Brain & Heart Health',
  },
  immunity: {
    id: 'summary.seg.immunity',
    description: 'Title for Digestion & Immunity summary screen',
    defaultMessage: 'Digestion & Immunity',
  },
  aging: {
    id: 'summary.seg.aging',
    description: 'Title for Anti-Aging summary screen',
    defaultMessage: 'Anti-Aging',
  },
  performance: {
    id: 'summary.seg.performance',
    description: 'Title for Athletic Performance summary screen',
    defaultMessage: 'Athletic Performance',
  },
  weight: {
    id: 'summary.seg.weight',
    description: 'Title for Weight Loss summary screen',
    defaultMessage: 'Weight Loss',
  },
  targeted: {
    id: 'summary.seg.targeted',
    description: 'Title for Targeted Nutrition summary screen',
    defaultMessage: 'Targeted Nutrition',
  },
})

const segments = [
  segNames.joints,
  segNames.energy,
  segNames.brain,
  segNames.immunity,
  segNames.aging,
  segNames.performance,
  segNames.weight,
  segNames.targeted,
];

export const segment = (seg, current, i, intl) => (
  <div className={style.segment} key={seg.id}>
    <div className={style.num}>{i <= current + 1 ? "✓" : i}</div>
    <div
      className={classnames(style.segname, { [style.hi]: i <= current + 1 })}
    >
      {intl.formatMessage(seg)}
    </div>
  </div>
);

class SummaryPage extends Component {
  componentDidMount() {
    // if (newProps.content.current !== this.props.content.current) {
    const { current } = this.props.content;
    ReactGA.pageview(`/runner/summary/${current}`);
    // }
  }

  render() {
    const { content, hasPrev, onNavigate, name, intl, language = 'en' } = this.props;
    const { current, title, subtitle } = content;
    return (
      <Fragment>
        <Header key="header" />
        <div
          className={classnames(style.panel, "mx-auto")}
          key="panel"
          onClick={() => {
            onNavigate("next");
          }}
        >
          <Row className={style["title-row"]}>
            <Col sm={12} className={style.headerwrapper}>
              {title && (
                <div className={style.header}>{intl.formatMessage(title, { name })}</div>
              )}
              {subtitle && <div className={style.details}>{intl.formatMessage(subtitle)}</div>}
              <div className={classnames(style.list, style[language])}>
                {segments.map((seg, i) => segment(seg, current, i + 1, intl))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {hasPrev && (
                <div
                  className={style.back}
                  onClick={event => {
                    event.stopPropagation();
                    onNavigate("prev");
                  }}
                >
                  <FormattedMessage
                    id="summary.button.back"
                    description="Back button text"
                    defaultMessage="&lt; Back"
                  >
                    {(text) => text}
                  </FormattedMessage>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(SummaryPage);
