import { connect } from 'react-redux';

import EnhancerPanel from './EnhancerPanel';
import { addToCart, getCountry } from '../../state';

const mapStateToProps = state => ({
  country: getCountry(state),
});

export default connect(mapStateToProps, { addToCart })(EnhancerPanel);
