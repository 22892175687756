import { connect } from 'react-redux';

import MultipleChoice from './MultipleChoice';
import { getQuizAnswer, getQuizContent, logMultiAnswer } from '../../../state';

export const mapStateToProps = state => ({
  answer: getQuizAnswer(state),
  question: getQuizContent(state),
});

/**
 * Redux connector for MultipleChoice.
 */
export default connect(mapStateToProps, { logMultiAnswer })(MultipleChoice);
