import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import style from './enhancer.module.scss';
import { moreRecommendations } from './enhancers';
import EnhancerProduct from './EnhancerProduct';

class EnhancerPanel extends Component {
  render() {
    const { enhancer, summary, intl, country } = this.props;
    if (enhancer) {
      const reco = moreRecommendations.find(r => r.id === enhancer);
      const products = reco.products.filter(p => p.availability.indexOf(country) !== -1);
      return (
        <div className={style.hepanel}>
          <div className={style.titlewrapper}>
            <div className={style.topictitle}>{intl.formatMessage(reco.title)}</div>
            <div className={style.challenge}>{intl.formatMessage(reco.description)}</div>
          </div>
          <div className={style.prodlist}>
            <div className={style.products}>
              <FormattedMessage
                id="enhancer.recommended"
                description="Recommended products heading"
                defaultMessage="RECOMMENDED PRODUCTS:"
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <div className={style.prodwrapper}>
              {products.map(p => (
                <EnhancerProduct product={p} key={p.sku} summary={summary} />
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default injectIntl(EnhancerPanel);
