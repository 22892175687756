import { defineMessages } from 'react-intl';

import { makeActionCreator, createReducer, updateObject, updateItemInArray } from '../utils';

import segments, { summaryTitle } from '../questions';

const messages = defineMessages({
  covering: {
    id: 'quiz.covering',
    description: 'Intro content for summary page',
    defaultMessage: 'Here’s what we’ll be covering, and it should only take a few minutes:',
  }
});

// ACTION TYPES

const LOG_ANSWER = 'ygy/quiz/LOG_ANSWER';
const LOG_MULTI_ANSWER = 'ygy/quiz/LOG_MULTI_ANSWER';
const QUIZ_PREV = 'ygy/quiz/QUIZ_PREV';
const QUIZ_NEXT = 'ygy/quiz/QUIZ_NEXT';
const QUIZ_REPLAY_FROM = 'ygy/quiz/QUIZ_REPLAY_FROM';
export const QUIZ_RESET = 'ygy/quiz/QUIZ_RESET';

// REDUCER

const initialState = {
  segment: 0, 
  question: -2, 
  answers: {},
};

export const updateMulti = (array, action) => {
  return updateItemInArray(
    array || [],
    item => item === action.answer,
    () => {
      if (action.checked) {
        return action.answer;
      }
      return undefined;
    },
  ).filter(t => t !== undefined); // get rid of the undefined answers...
};

const reducer = createReducer(initialState, {
  [LOG_ANSWER]: (state, action) => {
    const next = {
      ...state.answers,
      [action.questionId]: action.answer,
    };
    return updateObject(state, {
      answers: next,
    });
  },
  [LOG_MULTI_ANSWER]: (state, action) => updateObject(state, {
    answers: {
      ...state.answers,
      [action.questionId]: updateMulti(state.answers[action.questionId], action),
    }
  }),
  [QUIZ_PREV]: (state) => {
    const { segment, question } = state;

    if (question > -1) {
      return updateObject(state, { 
        question: question - 1,
      });
    } else if (segment > 0) {
      return updateObject(state, {
        segment: segment - 1,
        question: segments[segment - 1].questions.length,
      });
    } else {
      throw Error('Passed start');
    }
  },
  [QUIZ_NEXT]: (state) => {
    const { segment, question } = state;
    
    if (question < segments[segment].questions.length) {
      return updateObject(state, { question: question + 1 });
    } else if (segment < segments.length - 1) {
      return updateObject(state, { segment: segment + 1, question: -1 });
    }
    throw Error('Passed end');
  },
  [QUIZ_RESET]: () => initialState,
  [QUIZ_REPLAY_FROM]: (state, action) => {
    return updateObject(state, { segment: action.segment, question: action.question });
  },
});

export default reducer; 

// ACTION CREATORS

export const atIntroOrSummary = (segs, segment, question) => {
  return (question === -1) 
  || question === -2
  || segment === -1
  || question === segs[segment].questions.length;
};

export const logAnswer = makeActionCreator(LOG_ANSWER, 'questionId', 'answer');
export const logMultiAnswer = makeActionCreator(LOG_MULTI_ANSWER, 'questionId', 'answer', 'checked');
export const quizNext = makeActionCreator(QUIZ_NEXT);
export const quizPrev = makeActionCreator(QUIZ_PREV);
export const resetQuiz = makeActionCreator(QUIZ_RESET);
export const replayFrom = makeActionCreator(QUIZ_REPLAY_FROM, 'segment', 'question');

// export function logSingleAndAdvance(answer) {
//   return (dispatch, getState) => {
//     const { segment, question } = getState().quiz;
//     if (!atIntroOrSummary(segments, segment, question)) {
//       dispatch(logAnswer(segments[segment].questions[question].id, answer));
//       dispatch(quizNext());
//     }
//     return Promise.resolve();
//   }; 
// }

// SELECTORS

export const getQuizStatus = state => {
  const { segment, question } = state.quiz;

  if (segment === 0 && question === -2) return 'not started';
  if (segment === segments.length - 1 && question === segments[segments.length - 1].questions.length) return 'completed';
  return 'in progress';
}

export const scoreSegment = (state, segment) => {
  const { answers } = state.quiz;
  const { segmentId, questions } = segment;
  const total = questions.reduce((arr, q) => arr + answers[q.id], 0);
  const max = questions.length * 5;
  const reason = questions.map(q => ({ id: q.id, question: q.short, answer: answers[q.id] }));
  // const product = products.find(prod => prod.segmentId === segmentId) || {};
  return ({
    segmentId, 
    score: total / max, reason, 
    // sku: product.sku,
  });
};

export const getSegmentScores = state => {
  const result = segments
    .slice(0, 7)
    .map(seg => scoreSegment(state, seg))
    .sort((a, b) => b.score - a.score);
  result[0].pick = true;
  return result;
};

export const getMoreRecos = state => {
  const { answers } = state.quiz;
  return Object.keys(answers)
    .filter(q => q.startsWith('misc'))
    .reduce((acc, val) => acc.concat(answers[val]), []);
};

export const getQuizAnswer = (state) => {
  const { segment, question, answers } = state.quiz;
  if (atIntroOrSummary(segments, segment, question)) return undefined;
  return answers[segments[segment].questions[question].id];
}

export const getQuizHasPrev = (state) => {
  const { segment, question } = state.quiz;
  return segment > 0 || (segment === 0 && question > -1);
};

export const getQuizHasNext = (state) => {
  const { segment, question } = state.quiz;

  return segment < segments.length - 1
    || ((segment === segments.length - 1) && (question < segments[segment].questions.length));
};

export const getQuizContentType = (state) => {
  const { segment, question } = state.quiz;
  if (question === -2) return 'summary';
  if (question === -1) return 'intro';
  if (question === segments[segment].questions.length) return 'summary';
  return segments[segment].questions[question].type;
}

export const getQuizContent = (state) => {
  const { name, quiz: { segment, question } } = state;

  if (question === -2) return ({
    type: 'summary',
    // title: summaryTitle(-1, name),
    subtitle: messages.covering,
    current: -1,
  });

  // Return the intro if at the start of a segment
  if (question === -1) return ({
    type: 'intro',
    title: segments[segment].intro.title,
    image: segments[segment].intro.image,
    animation: segments[segment].intro.animation,
    segment: segment + 1,
  });

  // Return the summary when at the end of a segment
  if (question === segments[segment].questions.length) return ({
    type: 'summary',
    title: summaryTitle(segment, name),
    current: segment,
  });
  
  // Otherwise, return the current question
  return segments[segment].questions[question];
}

export const getQuizProgress = (state) => {
  const { quiz: { segment, question } } = state;

  // Still at intro screen...
  if (question < 0) {
    return Math.max(segment - 1, 0);
  }

  // At the segment summary...
  if (question === segments[segment].questions.length) {
    return segment;
  }

  // Somewhere inbetween...
  return segment + question / segments[segment].questions.length;
}

export const getQuizSegmentColor = (state) => {
  const { segment } = state.quiz;
  return segments[segment].intro.bg;
};

export const getQuizAtEnd = (state) => {
  const { segment, question } = state.quiz;
  return segment === segments.length - 1 && question === segments[segment].questions.length;
};
