import React, { Component } from 'react';

import { Popover } from '../rb-import';

class UpdatingPopover extends Component {
  componentDidMount() {
    this.props.scheduleUpdate();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.props.scheduleUpdate();
    }
  }

  render() { return <Popover {...this.props} />; }
}

export default UpdatingPopover;
