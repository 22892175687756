import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage, injectIntl } from 'react-intl';

import QuizToggle from '../toggle';

import style from '../question.module.scss';

/**
 * Checks whether a value is in the supplied array. Multiple-selection answers consist of an array
 * of strings representing the selected options. If a value is found in the answer array, it indicates
 * that the value is selected.
 * 
 * @param {String} value the value being checked
 * @param {Array} arr the list of selected values
 */
export const selected = (value, arr) => arr ? arr.includes(value) : false;

/**
 * Subcomponent that presents the portion of the question view that handles multiple-choice answer selection.
 * 
 * @param {Object} props the component properties
 * @param {Object} props.question the id, title and options for the current question
 * @param {Array}  props.answer the currently selected options for the current question
 * @param {Function} props.logMultiAnswer Redux dispatch function to update the answer array 
 */
class MultipleChoice extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.question.id !== this.props.question.id) {
      ReactGA.pageview(`/runner/${this.props.question.id}`);
    }
  }

  render() {
    const { question, answer, logMultiAnswer, intl } = this.props;
    const { id, title, options } = question;

    return (
      <Fragment>
        <div className={style.multititle}>{intl.formatMessage(title)}</div>
        <div className={style.multisubtitle}>
          <FormattedMessage
            id="multi.subtitle"
            description="Multiple choice question subtitle"
            defaultMessage="SELECT ALL THAT APPLY"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
        <div className={style.options}>
          {options.map((o) => (
            <QuizToggle
              key={`${id}-${o.value}`}
              image={o.image}
              onClick={() => logMultiAnswer(id, o.value, !selected(o.value, answer))}
              selected={selected(o.value, answer)}
              square={true}
              text={o.text}
            />
          ))}
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(MultipleChoice);
