import React, { Component } from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, injectIntl } from 'react-intl';

import InterestGauge from "./interest";
import { Row, Col, Image } from "../../components/rb-import";
import products from "./products";
import SkuButton from "../SkuButton";
import Price from '../Price';

import style from "./pak.module.scss";

class PakPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { showing: this.props.pick === true };
    this.pakRef = React.createRef();
  }

  toggle = (event, scroll) => {
    const { showing } = this.state;
    this.setState({ showing: !showing });
    this.pakRef.current.scrollIntoView();
  };

  render() {
    const { score, segment, pick, reason, flat, intl } = this.props;
    const { showing } = this.state;
    const product = products.find(p => p.segmentId === segment);

    if (!product) {
      throw Error(
        `Cannot find product for segment id: ${segment} (${JSON.stringify(
          this.props
        )})`
      );
    }
    if (score && (score < 0 || score > 1.0)) {
      throw Error("score property must be a number between 0.0 and 1.0");
    }

    // Expanded view, potentially with interest guage
    if (showing || flat) {
      return (
        <div
          className={classnames(style.panel, { [style.pick]: pick })}
          ref={this.pakRef}
        >
          {score && !flat && (
            <InterestGauge
              num={Math.min(Math.floor(score * 5), 4)}
              reason={reason}
            />
          )}
          <div className={classnames(style.title, { [style.short]: flat })}>
            {intl.formatMessage(product.categoryTitle)}
          </div>
          {!flat &&
            (pick ? (
              <div className={style.ourpick}>
                <div className={style.patch} />
                <span>
                  <FormattedMessage
                    id="pak.ourpick"
                    description="Recommended Pak ribbon text"
                    defaultMessage="OUR PICK FOR YOU!"
                  >
                    {(text) => text}
                  </FormattedMessage>
                </span>
              </div>
            ) : (
              <div
                className={classnames(style.separator, style.dark, style.left)}
              />
            ))}
          <div className={classnames(style.tagline, { [style.short]: flat })}>
            {intl.formatMessage(product.tagline)}
          </div>
          <div
            className={classnames(style.product, {
              [style.show]: showing || flat,
              [style.pick]: pick
            })}
          >
            <div className={style.prodname}>
              {intl.formatMessage(product.name)}
            </div>
            <div className={style.price}>
              <Price prices={product.retailPrice} />
              {' '}
              <span>
                <FormattedMessage
                  id="pak.retail"
                  description="Pak retail label"
                  defaultMessage="Retail"
                >
                  {(text) => text}
                </FormattedMessage>
              </span>
              <br />
              <Price prices={product.wholesalePrice} />
              {' '}
              <span>
                <FormattedMessage
                  id="pak.wholesale"
                  description="Pak wholesale label"
                  defaultMessage="Wholesale*"
                >
                  {(text) => text}
                </FormattedMessage>
              </span>
              <div className={style.sku}>
                <FormattedMessage
                  id="pak.sku"
                  description="Pak sku label"
                  defaultMessage="SKU #"
                >
                  {(text) => text}
                </FormattedMessage>
                {product.sku}
              </div>
            </div>
            <Image
              src={product.image}
              className={classnames(style.prodimg, style.print)}
            />
            {!pick && (
              <div className={style.prodsummary}>{intl.formatMessage(product.summary)}</div>
            )}
            <div
              className={style.proddesc}
              dangerouslySetInnerHTML={{ __html: intl.formatHTMLMessage(product.description) }}
            />
            <Image
              src={product.image}
              className={classnames(style.prodimg, "mx-auto")}
            />
            <SkuButton
              block
              sku={product.sku}
              title={product.name}
              price={product.retailPrice}
              image={product.image}
              wholesale={product.wholesalePrice}
              className="mx-auto"
            />
            <div className={style.distr}>
              <FormattedMessage
                id="pak.preferred"
                description="Message to preferred customers or distributors"
                defaultMessage="*If you are a preferred customer or distributor, you will have the opportunity to sign in at checkout to receive wholesale pricing."
              >
                {(text) => text}
              </FormattedMessage>
            </div>
          </div>
          {!flat &&
            (showing ? (
              <div className={style.more} onClick={event => this.toggle(event)}>
                <FormattedMessage
                  id="pak.lessdetail"
                  description="Less detail button text"
                  defaultMessage="Less detail"
                >
                  {(text) => text}
                </FormattedMessage>
                {' '}
                <FontAwesomeIcon icon="chevron-up" />
              </div>
            ) : (
              <div className={style.more} onClick={event => this.toggle(event)}>
                <FormattedMessage
                  id="pak.moredetail"
                  description="More detail button text"
                  defaultMessage="More detail"
                >
                  {(text) => text}
                </FormattedMessage>
                {' '}
                <FontAwesomeIcon icon="chevron-down" />
              </div>
            ))}
        </div>
      );
    }

    // Compressed view
    return (
      <div
        className={classnames(style.panel, style.short)}
        ref={this.pakRef}
        onClick={this.toggle}
      >
        <Row>
          <Col>
            <div className={classnames(style.title, style.short)}>
              {intl.formatMessage(product.categoryTitle)}
            </div>
          </Col>
          <Col xs="auto" className={style.expander}>
            <FontAwesomeIcon icon="plus" size="2x" />
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectIntl(PakPanel);
