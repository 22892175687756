import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { Row, Col, Image, OverlayTrigger, Popover } from '../rb-import'
import Hamburger from '../Hamburger';

import logo from '../../images/YGY-Logo-Icon.svg';
import logoLight from '../../images/YGY-Logo-Icon-White.svg';
import cart from '../../images/cart.svg';
import cartLight from '../../images/cart-light.svg';

import style from './header.module.scss';

const messages = defineMessages({
  empty: {
    id: 'header.empty',
    description: 'Empty shopping list popover title',
    defaultMessage: 'Empty Shopping List',
  }
});

const Header = ({ light, showCart, cartEmpty, showPanel, setShowingCart, intl }) => (
  <Row className={classnames(style.header, "align-items-center")}>
    <Col xs="auto">
      <Hamburger light={light} onClick={() => showPanel('config')}/>
    </Col>
    <Col className="text-center">
      <Image src={light ? logoLight : logo} className={style.logo} />
    </Col>
      <Col xs="auto">
        {(!showCart || cartEmpty) && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={(
              <Popover id="reason-popover" title={intl.formatMessage(messages.empty)} >
                <FormattedMessage
                  id="header.emptylist"
                  description="Popover when list is empty"
                  defaultMessage="Your shopping list is empty. Add a product in order to proceed."
                >
                  {(text) => text}
                </FormattedMessage>
              </Popover>
            )}
          >
            <Image src={light ? cartLight : cart} className={style.carticon} />
          </OverlayTrigger>
        )}
        {showCart && !cartEmpty && (
          <Image src={light ? cartLight : cart} className={style.carticon} onClick={() => setShowingCart(true)}/>
        )}
      </Col>
  </Row>
);

export default injectIntl(Header);
