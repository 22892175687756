import heart from './images/heart.png';

export default {
  v: "5.3.4",
  fr: 29.9700012207031,
  ip: 0,
  op: 30.0000012219251,
  w: 200,
  h: 200,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    { id: "image_0", w: 125, h: 109, u: "", p: heart, e: 0 }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [99, 119, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 1",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1.75, -13.25, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  o: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
                  v: [
                    [-37.125, 1.25],
                    [-24.208, 1.25],
                    [-15.792, -27.083],
                    [-6.208, 27.083],
                    [3.875, -15.833],
                    [7.292, 0],
                    [37.125, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 5, ix: 5 },
              lc: 2,
              lj: 2,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: true
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        },
        {
          ty: "tm",
          s: { a: 0, k: 0, ix: 1 },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.145], y: [1] },
                o: { x: [0.428], y: [0] },
                n: ["0p145_1_0p428_0"],
                t: 4,
                s: [0],
                e: [99]
              },
              { t: 32.0000013033867 }
            ],
            ix: 2
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false
        }
      ],
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: "heart_noline.eps",
      cl: "eps",
      parent: 1,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [2.25, -13.75, 0], ix: 2 },
        a: { a: 0, k: [62.5, 54.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 5,
              s: [100, 100, 100],
              e: [105, 105, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 9,
              s: [105, 105, 100],
              e: [100, 100, 100]
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              n: ["0p667_1_0p333_0", "0p667_1_0p333_0", "0p667_1_0p333_0"],
              t: 15,
              s: [100, 100, 100],
              e: [100, 100, 100]
            },
            { t: 24.00000097754 }
          ],
          ix: 6
        }
      },
      ao: 0,
      ip: 0,
      op: 30.0000012219251,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
