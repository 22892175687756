import { connect } from 'react-redux';

import PromptPage from './PromptPage';
import { getQuizContent } from '../../state';

export const mapStateToProps = state => ({
  content: getQuizContent(state),
});

/**
 * Redux connector for PromptPage
 */
export default connect(mapStateToProps)(PromptPage);
