import { createReducer, makeActionCreator, updateObject } from "../utils";
import axios from 'axios';
import _ from 'underscore';

// ACTION TYPES

const VERIFY_ID_REQUEST = 'ygy/rep/VERIFY_ID_REQUEST';
const VERIFY_ID_SUCCESS = 'ygy/rep/VERIFY_ID_SUCCESS';
const VERIFY_ID_FAILURE = 'ygy/rep/VERIFY_ID_FAILURE';
const CLEAR_VERIFICATION_ERROR = 'ygy/rep/CLEAR_VERIFICATION_ERROR';
const SET_REP_ID = 'ygy/rep/SET_REP_ID';
const SET_REP_NAME = 'ygy/rep/SET_REP_NAME';
const SET_REP_CITY = 'ygy/rep/SET_REP_CITY';
const SET_REP_STATE = 'ygy/rep/SET_REP_STATE';
const SET_REP_ZIP = 'ygy/rep/SET_REP_ZIP';

// REDUCER

const initialState = { repId: '', repName: '', repCity: '', repState: '', repZip: '', verified: false };

const reducer = createReducer(initialState, {
  [VERIFY_ID_REQUEST]: state => _.omit(state, 'verified'),
  [VERIFY_ID_SUCCESS]: (state, action) => updateObject(initialState, {
    repId: action.repId,
    // verificationError: undefined, 
    verified: true,
  }),
  [VERIFY_ID_FAILURE]: (state, action) => updateObject(initialState, {
    repId: action.repId, 
    verificationError: action.error, 
    verified: false,
  }),
  [CLEAR_VERIFICATION_ERROR]: state => updateObject(state, { 
    verificationError: undefined, verified: false,
  }),
  [SET_REP_ID]: (state, action) => updateObject(initialState, {
    repId: action.repId,
    verified: false,
  }),
  [SET_REP_NAME]: (state, action) => updateObject(state, { 
    repId: '', repName: action.repName, repZip: '', verified: false,
    verificationError: undefined,
  }),
  [SET_REP_CITY]: (state, action) => updateObject(state, {
    repCity: action.repCity, repId: '', repZip: '', verified: false,
    verificationError: undefined,
  }),
  [SET_REP_STATE]: (state, action) => updateObject(state, {
    repState: action.repState, repId: '', repZip: '', verified: false,
    verificationError: undefined,
  }),
  [SET_REP_ZIP]: (state, action) => updateObject(initialState, {
    repZip: action.repZip,
  }),
});

export default reducer;

// ACTION CREATORS

export function verifyRepId(repId) {
  return {
    types: [
      VERIFY_ID_REQUEST,
      VERIFY_ID_SUCCESS,
      VERIFY_ID_FAILURE,
    ],
    // shouldCallApi: state => !(state.rep.verified && state.rep.repId === repId),
    callApi: () => axios.get('/api/verify', {
      params: { repId },
    }),
    payload: { repId },
  };
}

export const setRepId = makeActionCreator(SET_REP_ID, 'repId');
export const setRepName = makeActionCreator(SET_REP_NAME, 'repName');
export const setRepCity = makeActionCreator(SET_REP_CITY, 'repCity');
export const setRepState = makeActionCreator(SET_REP_STATE, 'repState');
export const setRepZip = makeActionCreator(SET_REP_ZIP, 'repZip');
export const clearVerificationError = makeActionCreator(CLEAR_VERIFICATION_ERROR);

// SELECTORS

export const getRepId = state => state.rep.repId;

export const getVerificationError = state => state.rep.verificationError;

export const getRepIdVerified = state => state.rep.verified === true;

export const getValidNameCityState = state => {
  const { repName, repCity, repState } = state.rep;

  if (
    !repName 
    || !repCity 
    || !repState
    || repName.length === 0
    || repCity.length === 0
    || repState.length === 0
  ) return false;
  return true; 
};

export const getValidZipCode = state => /^[0-9]{5}$/.test(state.rep.repZip);

export const getRepInfo = state => {
  const { repId, repName, repCity, repState, repZip } = state.rep;
  return { repId, repName, repCity, repState, repZip };
}