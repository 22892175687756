import { connect } from 'react-redux';

import {
  quizPrev, quizNext, getQuizHasPrev, getQuizHasNext, getQuizContentType, getQuizAtEnd,
} from '../../state';
import QuizRunner from './QuizRunner';

export const mapStateToProps = state => ({
  type: getQuizContentType(state),
  hasPrev: getQuizHasPrev(state),
  hasNext: getQuizHasNext(state),
  atEnd: getQuizAtEnd(state),
});

export default connect(mapStateToProps, {
  quizPrev, quizNext,
})(QuizRunner);
