import React, { Component } from "react";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";

import { Modal, Image } from "../rb-import";
import SkuButton from "../SkuButton";
import Price from '../Price';

import style from "./enhancer.module.scss";

class EnhancerProduct extends Component {
  constructor(props) {
    super(props);
    this.state = { showingModal: false };
  }

  toggleModal = () => {
    this.setState(state => ({ showingModal: !state.showingModal }));
  };

  render() {
    const { product, summary, intl } = this.props;
    const { showingModal } = this.state;

    return (
      <div className={style.productwrapper}>
        <Image src={product.image} />
        {summary ? (
          <div
            className={classnames(style.prodtitle, style.link)}
            onClick={this.toggleModal}
          >
            {intl.formatMessage(product.name)}
          </div>
        ) : (
          <div className={style.prodtitle}>
            {intl.formatMessage(product.name)}
          </div>
        )}
        <div className={style.price}>
          <Price prices={product.retail} />
          {" "}
          <span>
            <FormattedMessage
              id="enhancer.retail"
              description="Retail price label"
              defaultMessage="Retail"
            >
              {text => text}
            </FormattedMessage>
          </span>
          <br />
          <Price prices={product.wholesale} />
          {" "}
          <span>
            <FormattedMessage
              id="enhancer.wholesale"
              description="Wholesale price label"
              defaultMessage="Wholesale"
            >
              {text => text}
            </FormattedMessage>
          </span>
          <div className={style.sku}>
            <FormattedMessage
              id="enhancer.sku"
              description="Sku number label"
              defaultMessage="SKU #"
            >
              {text => text}
            </FormattedMessage>
            {product.sku}
          </div>
        </div>
        <div
          className={style.proddesc}
          dangerouslySetInnerHTML={{
            __html: intl.formatHTMLMessage(
              summary ? product.summary : product.description
            )
          }}
        />
        <SkuButton
          className={classnames(style["sm-button"], style.add)}
          sku={product.sku}
          title={product.name}
          price={product.retail}
          wholesale={product.wholesale}
          image={product.image}
        />
        {summary && (
          <Modal
            size="lg"
            centered
            show={showingModal}
            onHide={this.toggleModal}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <div className={style.bigprodtitle}>
                {intl.formatMessage(product.name)}
              </div>
              <div className={style.subtitle}>
                {intl.formatMessage(product.tagline)}
              </div>
              <div
                className={style.proddesc}
                dangerouslySetInnerHTML={{
                  __html: intl.formatHTMLMessage(product.description)
                }}
              />
              <div className={style.price}>
                <Price prices={product.retail} />
                {" "}
                <span>
                  <FormattedMessage
                    id="enhancer.retail"
                    description="Retail price label"
                    defaultMessage="Retail"
                  >
                    {text => text}
                  </FormattedMessage>
                </span>
                <br />
                <Price prices={product.wholesale} />
                {" "}
                <span>
                  <FormattedMessage
                    id="enhancer.wholesale"
                    description="Wholesale price label"
                    defaultMessage="Wholesale"
                  >
                    {text => text}
                  </FormattedMessage>
                </span>
                <div className={style.sku}>
                  <FormattedMessage
                    id="enhancer.sku"
                    description="Sku number label"
                    defaultMessage="SKU #"
                  >
                    {text => text}
                  </FormattedMessage>
                  {product.sku}
                </div>
              </div>
              <Image src={product.image} />
              <div className={style.buttondiv}>
                <SkuButton
                  variant="btn-pri"
                  size="sm"
                  className="w250"
                  sku={product.sku}
                  title={product.name}
                  price={product.retail}
                  image={product.image}
                  wholesale={product.wholesale}
                  onClick={this.toggleModal}
                />
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

export default injectIntl(EnhancerProduct);
