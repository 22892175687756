import React from 'react';
import { FormattedNumber } from 'react-intl';

const getPrice = (a, b) => {
  if (a.hasOwnProperty(b)) {
    return a[b];
  }
  return a['en-US'];
};

const Price = ({ prices, locale, style = 'currency' }) => {
  if (!prices) {
    throw Error('Prices is undefined/null');
  }

  const p = getPrice(prices, locale);

  if (!p) {
    throw Error(`No price found for ${locale} locale.`);
  }

  return (
    <FormattedNumber
      value={p.amount}
      style={style}
      currency={p.currency}
    >
      {(text) => text}
    </FormattedNumber>
  );
}

export default Price;