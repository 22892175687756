import { connect } from 'react-redux';

import { getName } from '../../state';
import MotivationPage from './MotivationPage';

const mapStateToProps = state => ({
  name: getName(state),
});

export default connect(mapStateToProps)(MotivationPage);
