import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { resetQuiz, emptyCart } from '../../state';
import DebugPage from './DebugPage';

/**
 * Redux connector for the NamePage component
 */
export default withRouter(connect(null, { resetQuiz, emptyCart })(DebugPage));
