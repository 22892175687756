import React from 'react';
import PropTypes from 'prop-types';

const MultiresImage = ({ images, sizes, alt, ...props }) => {
  const srcset = sizes.map((s, i) => `${images[i]} ${s}`).join(', ');
  return (
    <img {...props} srcSet={srcset} src={images[images.length - 1]} alt={alt} />
  );
};

MultiresImage.propTypes = {
  alt: PropTypes.string.isRequired,
};

export default MultiresImage;
