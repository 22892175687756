import React, { Component } from 'react';

/**
 * Component that provides an interruptible timer to present a screen. If
 * the timeout runs its course, the component will navigate in the direction
 * provided. If the user clicks the child component before the timeOut runs its
 * course, the user will navigate in the direction they chose.
 */
class Timed extends Component {
  /**
   * Creates a timeOut when the component is mounted.
   */
  componentDidMount() {
    this.setTimer();
  }


  /**
   * Clears the timeout when the component is unmounted.
   */
  componentWillUnmount() {
    this.clearTimer();
  }

  /**
   * Checking...
   */
  componentDidUpdate() {
    this.setTimer();
  }

  /**
   * Creates a timer
   * @param {Number} duration the number of milliseconds to delay before navigating
   * @param {String} direction the default direction to navigate on timeOut
   */
  setTimer = () => {
    // Stop the current timer
    this.clearTimer();
    
    // Start a new one
    const { duration, direction, mute } = this.props;
    if (!mute) {
      this.timer = setTimeout(() => {
        this.time = null;
        this.next(direction);
      }, duration);
    }
  }

  /**
   * Clears the previously created timer
   */
  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  /**
   * Calls the supplied navigation function with the supplied direction
   * @param {String} direction the direction to navigate ('prev' | 'next')
   */
  next = (direction) => {
    this.clearTimer();

    const { onNavigate } = this.props;
    onNavigate(direction);
  }

  /**
   * Renders the child component, adding an onNavigate prop to it so that it can navigate.
   */
  render() {
    return (
      React.Children.only(
        React.cloneElement(
          this.props.children, 
          { onNavigate: this.next }
        )
      ));
  }
}

export default Timed;
