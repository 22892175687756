import { connect } from 'react-redux';

import { getName, setName } from '../../state';
import NamePage from './NamePage';

export const mapStateToProps = state => ({
  name: getName(state),
});

/**
 * Redux connector for the NamePage component
 */
export default connect(mapStateToProps, { setName })(NamePage);
