import { connect } from 'react-redux';

import SingleChoice from './SingleChoice';
import { getQuizContent, getQuizAnswer, logAnswer } from '../../../state';

export const mapStateToProps = state => ({
  question: getQuizContent(state),
  answer: getQuizAnswer(state),
});

/**
 * Redux connector for the SingleChoice component.
 */
export default connect(mapStateToProps, { logAnswer })(SingleChoice);
