import React from 'react';
import classnames from 'classnames';
import Lottie from 'react-lottie';
import { FormattedMessage, injectIntl } from 'react-intl';

import style from './prompt.module.scss';
import { Row, Col, Image } from '../../components/rb-import';
import Header from '../../components/Header';

/**
 * Returns the appropriate background style for the current view based on the supplied segment number.
 * The styles map to colors found in the UI/UX design.
 * 
 * @param {Number} segment the current segment number
 * @returns {String} the CSS classname to apply
 * @throws {Error} Invalid segment number
 */
export const segmentBackgroundClass = (segment) => {
  switch (segment) {
    case 1: return style.joints;
    case 2: return style.energy;
    case 3: return style.heart;
    case 4: return style.immune;
    case 5: return style.aging;
    case 6: return style.perf;
    case 7: return style.weight;
    case 8: return style.more;
    default:
      throw Error(`Unknown segment number: ${segment}`);
  }
}

/**
 * Presents an intro screen consisting of an icon, title and optionally a back button. The background color of the
 * view is fixed and is determined by the supplied segment number.
 * 
 * @param {Object} props the component properties
 * @param {Object} props.content the title, image and segment number of the current view
 * @param {Function} props.onNavigate Redux dispatch action which accepts one argument: either 'prev' or 'next' 
 */
const PromptPage = ({ content, onNavigate, intl }) => {
  const { title, image, segment, animation } = content;
  
  return (
    <div
      className={classnames(style.prompt, segmentBackgroundClass(segment))}
      onClick={() => onNavigate('next')}
    >
      <Header light />
      <Row className={style.content}>
        <Col>
          {
            animation ? (
              <div className={style.logo}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: animation,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid meet',
                    },
                  }}
                  animationData={animation}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
            ) : (
              <Image src={image} height={120} className={style.logo} />
            )
          }
          <div className={style.title}>{intl.formatMessage(title)}</div>
          {segment > 1 && (
            <div 
              className={style.back}
              onClick={(event) => {
                event.stopPropagation();
                onNavigate('prev');
              }}
            >
              <FormattedMessage
                id="prompt.button.back"
                description="Back button text for prompt page"
                defaultMessage="&lt; Back"
              >
                {(text) => text}
              </FormattedMessage>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(PromptPage);
