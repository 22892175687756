import { connect } from 'react-redux';

import ConfigPanel from './ConfigPanel';
import { getLanguage, getCountry, getSupportedLanguages, setLocale } from '../../state';

const mapStateToProps = state => ({
  language: getLanguage(state),
  country: getCountry(state),
  supportedLanguages: getSupportedLanguages(state),
});

export default connect(mapStateToProps, { setLocale })(ConfigPanel);
