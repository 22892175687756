import React, { Component } from "react";
import { connect } from "react-redux";
import { Router } from "react-router-dom";
import classnames from "classnames";
import ScrollUpButton from "react-scroll-up-button";
import ReactGA from "react-ga";
import { IntlProvider } from "react-intl";

import {
  getShowingCart,
  getPanelVisible,
  setShowingCart,
  hidePanel,
  clearShowingCart,
  getLocale
} from "./state";

import Cart from "./components/Cart";
import Config from "./components/Config";
import AppRouter from "./AppRouter";
import Close from "./components/Close";

import { browserHistory } from "./history";

import style from "./App.module.scss";

// const initialState = { showingConfig: false, showingCart: false };

browserHistory.listen(location => {
  ReactGA.pageview(location.pathname);
});

const language = locale => {
  const lang =
    require(`./content/${locale}.json`) || require(`./content/en-US.json`);
  return lang;
};

class App extends Component {
  clear = () => {
    const { clearShowingCart, hidePanel } = this.props;
    clearShowingCart();
    hidePanel("config");
  };

  render() {
    const { showingCart, showingConfig, locale } = this.props;

    return (
      <IntlProvider locale={locale} key={locale} messages={language(locale)}>
        <Router history={browserHistory}>
          <div className={style.app}>
            <ScrollUpButton />
            <div
              className={classnames(style.overlay, {
                [style.yam]: showingConfig,
                [style.yammo]: showingCart
              })}
              onClick={this.clear}
            >
              <div className={style.close}>
                <Close width={20} />
              </div>
            </div>

            <div
              className={classnames(style.config, {
                [style.yam]: showingConfig
              })}
            >
              <Config />
            </div>
            <Cart />
            <AppRouter
              showingConfig={showingConfig}
              showingCart={showingCart}
            />
          </div>
        </Router>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  showingCart: getShowingCart(state),
  showingConfig: getPanelVisible(state, "config"),
  locale: getLocale(state)
});

export default connect(
  mapStateToProps,
  { hidePanel, setShowingCart, clearShowingCart }
)(App);
