import React from 'react';

const Close = () => (
  <svg
    viewBox={`0 0 20 20`}
    preserveAspectRatio="xMidYMid meet"
  >
    <line x1="0" y1="0" x2="20" y2="20" stroke="#fff" strokeWidth="2" />
    <line x1="20" y1="0" x2="0" y2="20" stroke="#fff" strokeWidth="2" />
  </svg>
);

export default Close;