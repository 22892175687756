import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fas, faChevronUp, faChevronDown, faChevronLeft, faInfoCircle, faTimes, faCheck,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { far, faLightbulb } from '@fortawesome/free-regular-svg-icons';

library.add(fas, 
  faChevronUp, faChevronDown, faInfoCircle, faChevronLeft, faTimes, faCheck, faPlus,
);

library.add(far, 
  faLightbulb,
);
