import React from 'react';
import classnames from 'classnames';

import style from './hb.module.scss';

/**
 * Displays the 'hamburger' menu button
 * @param {Boolean} light flag indicating whether the hamburger color should be inverted
 */
const Hamburger = ({ light, onClick }) => (
  <div className={style.wrapper} onClick={onClick}>
    <div className={classnames(style.dash, { [style.white]: light })} />
    <div className={classnames(style.dash, { [style.white]: light })} />
    <div className={classnames(style.dash, style.half, { [style.white]: light })} />
  </div>
);

export default Hamburger;
