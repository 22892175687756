import { connect } from 'react-redux';

import SkuButton from './SkuButton';
import { addToCart, getHasSku } from '../../state';

const mapStateToProps = (state, ownProps) => ({
  skuSelected: getHasSku(state, ownProps.sku),
});

export default connect(mapStateToProps, { addToCart })(SkuButton);
