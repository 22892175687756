import React, { Component, Fragment } from "react";
import { LinkContainer } from "react-router-bootstrap";
import classnames from "classnames";
import Media from "react-media";
import { FormattedMessage } from "react-intl";
import queryString from 'query-string';

import { Row, Col, Button } from "../../components/rb-import";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import MultiresImage from "./MultiresImage";

import wallach from "./HQ18_Dr-Wallach.png";
import wallach2x from "./HQ18_Dr-Wallach@2x.png";
import wallach3x from "./HQ18_Dr-Wallach@3x.png";

import products from "./YGY_Products.png";
import products2x from "./YGY_Products@2x.png";
import products3x from "./YGY_Products@3x.png";

import nutrients from "./HQ18_nutrients.png";
import nutrients2x from "./HQ18_nutrients@2x.png";
import nutrients3x from "./HQ18_nutrients@3x.png";

import h540 from "./hero-540.jpg";
import h720 from "./hero-720.jpg";
import h960 from "./hero-960.jpg";
import h1140 from "./hero-1140.jpg";

import style from "./start.module.scss";

export const distributorId = hostname => {
  const parts = hostname.split(".");
  if (parts.length < 3) {
    return undefined;
  }
  return parts.slice(-parts.length, 1 - parts.length)[0]; // return the distributor id...
};

class StartPage extends Component {
  componentDidMount() {
    this.handleSetup();
    this.verify(window.location.hostname);
  }

  verify = (hostname) => {
    const { verifyRepId } = this.props;
    const id = distributorId(hostname);
    if (id) {
      verifyRepId(id);
    }
  };

  handleSetup = () => {
    const { history, location } = this.props;
    const { country } = queryString.parse(location.search);

    if (country) {
      const { setCountry } = this.props;
      setCountry(country);
      history.replace({ ...location, search: '' });
    }
  };

  render() {
    const { completed }  = this.props;

    return (
      <Fragment>
        <Header key="header" />
        <Row key="hero">
          <Col className={classnames(style.hero, "text-center")}>
            <picture className="mx-auto">
              <source
                className={style.h}
                srcSet={h1140}
                media="(min-width: 1200px)"
              />
              <source
                className={style.h}
                srcSet={h960}
                media="(min-width: 992px)"
              />
              <source
                className={style.h}
                srcSet={h720}
                media="(min-width:  768px)"
              />
              <img className={style.h} src={h540} alt="" />
            </picture>
            <div className="textwrap">
              <div className={style.bigtitle}>
                <FormattedMessage
                  id="start.bigtitle"
                  description="Main title on start page"
                  defaultMessage="Tap Into Your Health"
                >
                  {text => text}
                </FormattedMessage>
              </div>
              <div className={style.subtitle}>
                <FormattedMessage
                  id="start.subtitle"
                  description="Start page subtitle"
                  defaultMessage="Find the right nutritional mix for your specific health goals with this simple assessment."
                >
                  {text => text}
                </FormattedMessage>
              </div>
              <LinkContainer to={completed ? "/returning" : "/name"}>
                <Button variant="ypri" size="yl" className="w250 go">
                  <FormattedMessage
                    id="start.cta"
                    description="Call to action button"
                    defaultMessage="Get Started"
                  >
                    {text => text}
                  </FormattedMessage>
                </Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
        <Row key="heart">
          <Col>
            <div className={style.heart}>
              <FormattedMessage
                id="start.heart"
                description="Heart of Our Company section title"
                defaultMessage="THE HEART OF OUR COMPANY"
              >
                {text => text}
              </FormattedMessage>
            </div>
            <div className={classnames(style.separator, style.dark)} />
          </Col>
        </Row>
        <Row key="elements" className={style.elements}>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={4} md={12} className={style.himgwrapper}>
                <MultiresImage
                  images={[wallach, wallach2x, wallach3x]}
                  sizes={["1x", "2x", "3x"]}
                  className={style.himg}
                  alt="Dr. Wallach"
                />
              </Col>
              <Col xs={8} md={12}>
                <div className={style.htitle}>
                  <FormattedMessage
                    id="start.drwallach"
                    description="Dr. Wallach title"
                    defaultMessage="DR. WALLACH"
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
                <div className={style.hdesc}>
                  <FormattedMessage
                    id="start.wallach.desc"
                    description="Wallach"
                    defaultMessage="Our core philosophy is based on the principles and research of Dr. Joel Wallach, whose career is 
                    dedicated to helping people live younger, longer lives."
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={4} md={12} className={style.himgwrapper}>
                <MultiresImage
                  images={[nutrients, nutrients2x, nutrients3x]}
                  sizes={["1x", "2x", "3x"]}
                  className={style.himg}
                  alt="90 essential nutrients"
                />
              </Col>
              <Col xs={8} md={12}>
                <div className={style.htitle}>
                  <FormattedMessage
                    id="start.ninety"
                    description="90 Essential Nutrients title"
                    defaultMessage="90 ESSENTIAL NUTRIENTS"
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
                <div className={style.hdesc}>
                  <FormattedMessage
                    id="start.nutrientdesc"
                    description="90 Essential nutrients description"
                    defaultMessage="Dr. Wallach has discovered there are 90 essential nutrients that bring us optimal health - each of our Healthy Body Start Paks contain these nutrients."
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={4} md={12} className={style.himgwrapper}>
                <MultiresImage
                  images={[products, products2x, products3x]}
                  sizes={["1x", "2x", "3x"]}
                  className={style.himg}
                  alt="Products for every lifestyle"
                />
              </Col>
              <Col xs={8} md={12}>
                <div className={style.htitle}>
                  <FormattedMessage
                    id="start.products"
                    description="Products title"
                    defaultMessage="PRODUCTS FOR EVERY LIFESTYLE"
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
                <div className={style.hdesc}>
                  <FormattedMessage
                    id="start.proddesc"
                    description="Products description"
                    defaultMessage="We offer a wide variety of wellness products that help you live your best life, for any age, any routine, and any need."
                  >
                    {text => text}
                  </FormattedMessage>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Media
          query="(max-width: 767px)"
          render={() => (
            <Row>
              <Col className="d-flex justify-content-center">
                <LinkContainer to={completed ? "/returning" : "/name"}>
                  <Button variant="ypri" size="yl" className="w250 mb-3 go">
                    <FormattedMessage
                      id="start.getstarted"
                      description="Get Started button"
                      defaultMessage="Get Started"
                    >
                      {text => text}
                    </FormattedMessage>
                  </Button>
                </LinkContainer>
              </Col>
            </Row>
          )}
        />
        <Row key="legal">
          <Col>
            <div className={style.disclaimer}>
              <FormattedMessage
                id="start.disclaimer"
                description="Disclaimer text"
                defaultMessage="This content is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition."
              >
                {text => text}
              </FormattedMessage>
            </div>
          </Col>
        </Row>
        <Footer />
      </Fragment>
    );
  }
}

export default StartPage;
