import React, { Component } from 'react';

// Utility constants

const DEG_TO_RADIAN = Math.PI / 180;
const DIAL_WIDTH = 50;
const PRECISION = 2;

// Dial config

const POINTER_OUTER_RADIUS = 10;
const GAUGE_WIDTH = 256;
const POINTER_INNER_RADIUS = 5;
const NUM_SEGMENTS = 5;

// Guage general

const OUTER_RADIUS = GAUGE_WIDTH / 2
const INNER_RADIUS = Math.max(OUTER_RADIUS - DIAL_WIDTH, 0);
const GAUGE_HEIGHT = OUTER_RADIUS + POINTER_OUTER_RADIUS;
const COLORS = ['#a1dfe2', '#40c2cd', '#01a4ad', '#137f7f', '#0d4f4d']
const POINTER_LENGTH = INNER_RADIUS + DIAL_WIDTH / 2;

const o = val => val.toFixed(PRECISION);

const _x = val => { const result = +val + OUTER_RADIUS; return result; }
const _y = val => { const result = +val + OUTER_RADIUS; return result; }

// const _p = ({ x, y }) => [_x(x), _y(y)];

const p2c = (degrees, radius) => {
  const x = o(radius * Math.cos((degrees + 180) * DEG_TO_RADIAN));
  const y = o(radius * Math.sin((degrees + 180) * DEG_TO_RADIAN));
  return { x, y };
}

const segment = (num) => {
  const p1 = p2c(num * 180 / NUM_SEGMENTS, OUTER_RADIUS);
  const p2 = p2c((num + 1) * 180 / NUM_SEGMENTS, OUTER_RADIUS);
  const p3 = p2c((num + 1) * 180 / NUM_SEGMENTS, INNER_RADIUS);
  const p4 = p2c(num * 180 / NUM_SEGMENTS, INNER_RADIUS);

  return (
    <path
      fill={COLORS[num]}
      className={`s${num}`}
      stroke="none"
      key={`seg-${num}`}
      d={`M${_x(p1.x)} ${_x(p1.y)}
        a${OUTER_RADIUS} ${OUTER_RADIUS} ${o(180 / NUM_SEGMENTS)} 0 1 ${o(p2.x - p1.x)} ${o(p2.y - p1.y)}
        L${_x(p3.x)} ${_y(p3.y)}
        a${INNER_RADIUS} ${INNER_RADIUS} ${o(180 / NUM_SEGMENTS)} 0 0 ${o(p4.x - p3.x)} ${o(p4.y - p3.y)}
        z
      `}
    />
  ); 
}

const pointer = (num) => {
  const baseAngle = (num + 0.5) * 180 / NUM_SEGMENTS;
  const p1 = p2c(baseAngle, POINTER_LENGTH);
  const p2 = p2c(baseAngle - 90, POINTER_OUTER_RADIUS);
  const p3 = p2c(baseAngle - 180, POINTER_OUTER_RADIUS);
  const p4 = p2c(baseAngle - 180, POINTER_INNER_RADIUS);
  const p5 = p2c(baseAngle, POINTER_INNER_RADIUS);
  const p6 = p2c(baseAngle + 90, POINTER_OUTER_RADIUS);
  return [
    <path
      fill="#686766"
      stroke="none"
      className="pl"
      key="p-light"
      d={`M${_x(p1.x)} ${_y(p1.y)}L${_x(p2.x)} ${_y(p2.y)}a${POINTER_OUTER_RADIUS} ${POINTER_OUTER_RADIUS} 90 0 0 ${o(p3.x - p2.x)} ${o(p3.y - p2.y)}
        L${_x(p4.x)} ${_y(p4.y)}a${POINTER_INNER_RADIUS} ${POINTER_INNER_RADIUS} 180 1 1 ${o(p5.x - p4.x)} ${o(p5.y - p4.y)}z
        `}
    />,
    <path
      fill="black"
      stroke="none"
      className="pd"
      key="p-dark"
      d={`M${_x(p1.x)} ${_y(p1.y)}L${_x(p6.x)} ${_y(p6.y)}a${POINTER_OUTER_RADIUS} ${POINTER_OUTER_RADIUS} 90 0 1 ${o(p3.x - p6.x)} ${o(p3.y - p6.y)}
        L${_x(p4.x)} ${_y(p4.y)}a${POINTER_INNER_RADIUS} ${POINTER_INNER_RADIUS} 180 1 0 ${o(p5.x - p4.x)} ${o(p5.y - p4.y)}z
        `}
    />,
  ];
}

const segments = () => {
  const arr = [];
  for(let i = 0; i < NUM_SEGMENTS; i += 1) {
    arr.push(segment(i));
  }
  return arr;
}

class Gauge extends Component {
  render() {
    const { num = 3 } = this.props;
    if(num < 0 || num > NUM_SEGMENTS) {
      throw Error(`num cannot be more than ${NUM_SEGMENTS}`);
    }
    return (
      <svg 
        viewBox={`0 0 ${GAUGE_WIDTH} ${GAUGE_HEIGHT}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* <g transform={`translate(${OUTER_RADIUS}, ${OUTER_RADIUS})`}> */}
          {segments()}
          {pointer(num)}
        {/* </g> */}
      </svg>
    );
  }
}

export default Gauge;

