import { createReducer, makeActionCreator, updateObject } from '../utils';

// ACTION TYPES

const ADD_TO_CART = 'ygy/cart/ADD_TO_CART';
const REMOVE_FROM_CART = 'ygy/cart/REMOVE_FROM_CART';
const EMPTY_CART = 'ygy/cart/EMPTY_CART';
const CLEAR_SHOWING_CART = 'ygy/cart/CLEAR_SHOWING_CART';
const SET_SHOWING_CART = 'ygy/cart/SET_SHOWING_CART';
const CLEAN_CART = 'ygy/cart/CLEAN_CART';

// REDUCER

const initialState = { showing: false, items: [] };

const reducer = createReducer(initialState, {
  [ADD_TO_CART]: (state, action) => {
    const { sku, title, image, retail, wholesale } = action;
    const existing = state.items.find(item => item.sku === sku);
    if (!existing) {
      return updateObject(state, {
        showing: true,
        items : [
          ...state.items,
          { sku, title, image, retail, wholesale },
        ],
      });
    }
    return state;
  },
  [REMOVE_FROM_CART]: (state, action) => updateObject(state, { 
    showing: true,
    items: state.items.filter(item => item.sku !== action.sku),
  }),
  [CLEAN_CART]: (state) => updateObject(state, {
    items: state.items.filter(item => 
      item.title.id !== undefined
      && item.sku !== undefined
      && item.image !== undefined
      && item.retail !== undefined
      && item.wholesale !== undefined
    ),
  }),
  [EMPTY_CART]: () => reducer(undefined, {}), // return to initial state
  [SET_SHOWING_CART]: (state, action) => updateObject(state, { showing: action.showing }),
  [CLEAR_SHOWING_CART]: state => updateObject(state, { showing: false }),
});

export default reducer;

// ACTION CREATORS

export const addToCart = makeActionCreator(ADD_TO_CART, 'sku', 'title', 'image', 'retail', 'wholesale');
export const removeFromCart = makeActionCreator(REMOVE_FROM_CART, 'sku');
export const emptyCart = makeActionCreator(EMPTY_CART);
export const clearShowingCart = makeActionCreator(CLEAR_SHOWING_CART);
export const setShowingCart = makeActionCreator(SET_SHOWING_CART, 'showing');

export const cleanCart = makeActionCreator(CLEAN_CART);

// SELECTORS

export const getHasSku = (state, sku) => state.cart.items.find(item => item.sku === sku) !== undefined;

export const getShowingCart = state => state.cart.showing;

export const getCartItems = state => state.cart.items;

export const getCartEmpty = state => state.cart.items.length === 0;