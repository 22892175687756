import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import products from '../../components/Pak/products';
import { moreRecommendations } from '../../components/HealthEnhancer/enhancers';

import Pak from '../../components/Pak';
import HealthEnhancer from '../../components/HealthEnhancer';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SharePanel from '../../components/SharePanel';

import style from './allproducts.module.scss';

const AllProductsPage = ({ cartEmpty }) => (
  <Fragment>
    <div className={style['title-block']} key="title">
      <Header cartEmpty={cartEmpty} showCart />
      <div className={style.titlewrapper}>
        <div className={style.title}>
          <FormattedMessage
            id="all.title"
            description="Browse All Products title"
            defaultMessage="Browse All of Our Products"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
        <div className={classnames(style.separator, style.dark)} />
        <div className={style.step}>
          <FormattedMessage
            id="all.subtitle"
            description="Browse All Products subtitle"
            defaultMessage="Incredible nutritional support for all your health concerns."
          >
            {(text) => text}
          </FormattedMessage>
        </div>
      </div>
    </div>
    <div className={style.prod}>
      <div className={style.prodwrapper} key="prod">
        <div className={style.title}>
          <FormattedMessage
            id="all.paks"
            description="Paks title"
            defaultMessage="Paks"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
        <div className={style.intro}>
          <FormattedMessage
            id="all.paksdescription"
            description="Description of Paks"
            defaultMessage="Our Healthy Body Start Paks contain the 90 essential nutrients you need daily, plus additional products to target your health goals."
          >
            {(text) => text}
          </FormattedMessage>
        </div>
        <div className={classnames(style.separator, style.dark)} />
        {products.map(res => (
          <Pak
            key={res.segmentId}
            segment={res.segmentId}
            flat
            showing
          />
        ))}
      </div>
    </div>
    <div className={style.ha}>
      <div className={style.hawrapper} key="more">
        {moreRecommendations.map(res => (
          <HealthEnhancer
            key={res.id}
            enhancer={res.id}
          />
        ))}
      </div>
    </div>
    <div className={style.browse} key="browse">
      <div className={style.browsewrapper}>
        <div className={style.share}>
          <SharePanel />
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
);

export default AllProductsPage;
