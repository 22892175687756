import { connect } from 'react-redux';

import {
  getLocale,
} from '../../state';

import Price from './Price';

export const mapStateToProps = state => ({
  locale: getLocale(state),
});

export default connect(mapStateToProps)(Price);
