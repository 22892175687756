import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

import style from "./footer.module.scss";

const Footer = () => (
  <div className={style.footer}>
    <div className={style.sectiontitle}>
      <FormattedMessage
        id="footer.contact"
        description="Contact section title"
        defaultMessage="Contact"
      >
        {text => text}
      </FormattedMessage>
    </div>
    <div className={style.separator} />
    <div className={style.link}>
      <FormattedMessage
        id="footer.phone"
        description="Contact phone number"
        defaultMessage="(619) 934-3980"
      >
        {text => text}
      </FormattedMessage>
    </div>
    <div className={style.link}>
      <FormattedMessage
        id="footer.email"
        description="Contact email"
        defaultMessage="support@youngevity.com"
      >
        {text => text}
      </FormattedMessage>
    </div>
    <div className={style.link}>
      <FormattedMessage
        id="footer.address1"
        description="Contact address 1"
        defaultMessage="2400 Boswell Rd"
      >
        {text => text}
      </FormattedMessage>
      <br />
      <FormattedMessage
        id="footer.address2"
        description="Contact address 2"
        defaultMessage="Chula Vista, CA 91914"
      >
        {text => text}
      </FormattedMessage>
    </div>

    <div className={style.sectiontitle}>
      <FormattedMessage
        id="footer.legal"
        description="Legal section title"
        defaultMessage="Legal"
      >
        {text => text}
      </FormattedMessage>
    </div>
    <div className={style.separator} />
    <div className={style.link}>
      <a href="https://youngevity.com/privacy-policy">
        <FormattedMessage
          id="footer.privacy"
          description="Privacy policy link"
          defaultMessage="Privacy Policy"
        >
          {text => text}
        </FormattedMessage>
      </a>
      {" | "}
      <a href="https://youngevity.com/terms-of-use">
        <FormattedMessage
          id="footer.terms"
          description="Terms of use link"
          defaultMessage="Terms of Use"
        >
          {text => text}
        </FormattedMessage>
      </a>
    </div>
    <div className={style.small} style={{ whiteSpace: "pre-wrap" }}>
      <FormattedHTMLMessage
        id="footer.dsa"
        description="Direct Selling Association statement"
        defaultMessage={`Youngevity is proud to be a member of the Direct Selling Association and follow their strict Code of Ethics.<br />To view the Code of Ethics by which we abide, <a href="https://www.dsa.org/consumerprotection/code-of-ethics">click here</a>.`}
      />
    </div>
    <div className={style.small}>
      <FormattedMessage
        id="footer.disclaimer"
        description="Footer disclaimer"
        defaultMessage={`Food and dietary supplement products sold by Youngevity are intented to
        contribute to the daily diet and overall health and are not intended for
        use in the prevention, treatment, mitigation, or cure of any disease or
        health-related condition. Individuals who have or suspect they have and
        illness or wish to commence a diet or exercise program should consult an
        appropriately licensed health care practitioner for a medical history
        evaluation, diagnosis, treatment, and Keto health recommendations.`}
      >
        {text => text}
      </FormattedMessage>
    </div>
    <div className={style.small}>
      <FormattedHTMLMessage
        id="footer.copyright"
        description="Footer copyright"
        defaultMessage="Copyright &copy; 2018<br />All rights reserved by Youngevity"
      />
    </div>
  </div>
);

export default Footer;
