import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ResultsPage from './ResultsPage';
import {
  getSegmentScores, getMoreRecos, getName, getCartEmpty, setShowingCart, replayFrom,
} from '../../state';

const mapStateToProps = state => ({
  scores: getSegmentScores(state),
  more: getMoreRecos(state),
  name: getName(state),
  cartEmpty: getCartEmpty(state),
});

export default withRouter(connect(mapStateToProps, { 
  setShowingCart, replayFrom, 
})(ResultsPage));
