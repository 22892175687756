import React, { Component } from 'react';
import QuestionPage from '../QuestionPage';
import PromptPage from '../PromptPage';
import SummaryPage from '../SummaryPage';

import TimedNavigation from '../../components/TimedNavigation';

import { browserHistory } from '../../history';

class QuizRunner extends Component {
  constructor(props) {
    super(props);
    this.navigate = this.navigate.bind(this);
  }

  navigate(direction) {
    const { hasPrev, hasNext, quizPrev, quizNext, atEnd } = this.props;
    switch (direction) {
      case 'prev':
        if (hasPrev) {
          quizPrev();
        }
        break;
      case 'next':
        if (atEnd) {
          //'/quiz' Navigate to the results screen
          browserHistory.push('/results');
        } else if (hasNext) {
          quizNext();
        }
        break;
      default:
        throw Error(`Invalid direction: ${direction}`);
    }
  }

  render() {
    const { type } = this.props;

    switch (type) {
      case 'single':
      case 'multi':
        return (<QuestionPage onNavigate={dir => this.navigate(dir)} />)
      case 'intro':
        return (
          <TimedNavigation duration={4000} direction="next" onNavigate={dir => this.navigate(dir)}>
            <PromptPage />
          </TimedNavigation>
        );
      case 'summary':
        return (
          <TimedNavigation duration={4000} direction="next" onNavigate={dir => this.navigate(dir)}>
            <SummaryPage />
          </TimedNavigation>
        );
      default:
        throw Error(`Unknown quiz content: ${type}`);
    }
  }
}

export default QuizRunner;
