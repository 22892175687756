import "raf";
import "babel-polyfill";

import "./style/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./state/configure_store";
import "./fa";
import "./intl";
import { withErrorBoundary } from './components/ErrorBoundary';

ReactGA.initialize("UA-84203306-17");

const store = configureStore();

const YgyApp = withErrorBoundary(App)

ReactDOM.render(
  <Provider store={store}>
    <YgyApp />
  </Provider>,
  document.getElementById("app-root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
