import { connect } from 'react-redux';

import Cart from './Cart';
import {
  getCartItems, getLanguage, removeFromCart, setShowingCart,
  getShowingCart, getCartTransferURL, getRepIdVerified,
  cleanCart,
} from '../../state';

const mapStateToProps = state => ({
  items: getCartItems(state),
  showing: getShowingCart(state),
  cartUrl: getCartTransferURL(state),
  verified: getRepIdVerified(state),
  language: getLanguage(state),
});

export default connect(mapStateToProps, { removeFromCart, setShowingCart, cleanCart })(Cart);
