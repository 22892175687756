import perf0 from './images/perf-0.png';
import perf1 from './images/perf-1.png';

export default {
  v: "5.3.4",
  fr: 29.9700012207031,
  ip: 0,
  op: 10.0000004073083,
  w: 200,
  h: 200,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    { id: "image_0", w: 40, h: 28, u: "", p: perf0, e: 0 },
    { id: "image_1", w: 82, h: 112, u: "", p: perf1, e: 0 }
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape Layer 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [100, 100, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0]],
                  o: [[0, 0], [0, 0]],
                  v: [[-56.75, 14], [-37.625, 14]],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ["0p667_1_0p333_0"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 2",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    0.804,
                    0.863,
                    0.114,
                    0.499,
                    0.508,
                    0.731,
                    0.175,
                    0.999,
                    0.212,
                    0.6,
                    0.235
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0, 0], ix: 5 },
              e: { a: 0, k: [100, 0], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: true
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 5,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [144, 124, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [162, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0]],
                  o: [[0, 0], [0, 0]],
                  v: [[-56.75, 14], [-37.625, 14]],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 2",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    0.804,
                    0.863,
                    0.114,
                    0.499,
                    0.508,
                    0.731,
                    0.175,
                    0.999,
                    0.212,
                    0.6,
                    0.235
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0, 0], ix: 5 },
              e: { a: 0, k: [100, 0], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: true
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 5,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [132, 112, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [157, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0]],
                  o: [[0, 0], [0, 0]],
                  v: [[-56.75, 14], [-37.625, 14]],
                  c: false
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    n: ["0p833_0p833_0p167_0p167"],
                    t: 2,
                    s: [100],
                    e: [0]
                  },
                  { t: 8.00000032584668 }
                ],
                ix: 1
              },
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 3,
              nm: "Trim Paths 2",
              mn: "ADBE Vector Filter - Trim",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 4, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "gf",
              o: { a: 0, k: 100, ix: 10 },
              r: 1,
              g: {
                p: 3,
                k: {
                  a: 0,
                  k: [
                    0,
                    0.804,
                    0.863,
                    0.114,
                    0.499,
                    0.508,
                    0.731,
                    0.175,
                    0.999,
                    0.212,
                    0.6,
                    0.235
                  ],
                  ix: 9
                }
              },
              s: { a: 0, k: [0, 0], ix: 5 },
              e: { a: 0, k: [100, 0], ix: 6 },
              t: 1,
              nm: "Gradient Fill 1",
              mn: "ADBE Vector Graphic - G-Fill",
              hd: true
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Shape 1",
          np: 5,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 1",
      parent: 7,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 11, ix: 10 },
        p: { a: 0, k: [97.351, 54.464, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [108, 108, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.958, 0], [0, -0.958], [-0.958, 0], [0, 0.958]],
                  o: [[-0.958, 0], [0, 0.958], [0.958, 0], [0, -0.958]],
                  v: [[0, -1.735], [-1.735, 0], [0, 1.735], [1.735, 0]],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "st",
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              ml2: { a: 0, k: 4, ix: 8 },
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [-15.015, 47.797], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 3,
      nm: "Null 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              n: "0p833_0p833_0p167_0p167",
              t: 0,
              s: [62, 100, 0],
              e: [100, 100, 0],
              to: [6.33333349227905, 0, 0],
              ti: [-6.33333349227905, 0, 0]
            },
            { t: 8.00000032584668 }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 2,
      nm: "athletic_performance_toe.eps",
      cl: "eps",
      parent: 5,
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [39.5, 42, 0], ix: 2 },
        a: { a: 0, k: [20, 14, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "athletic_performance_shoeback.eps",
      cl: "eps",
      parent: 5,
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0], y: [0.998] },
              o: { x: [0.333], y: [0] },
              n: ["0_0p998_0p333_0"],
              t: 0,
              s: [-11],
              e: [0]
            },
            { t: 3.00000012219251 }
          ],
          ix: 10
        },
        p: { a: 0, k: [20, 49.75, 0], ix: 2 },
        a: { a: 0, k: [70, 105.75, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 10.0000004073083,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
