import { connect } from 'react-redux';

import SummaryPage from './SummaryPage';
import { getQuizContent, getQuizHasPrev, getName, getLanguage } from '../../state';

export const mapStateToProps = state => ({
  content: getQuizContent(state),
  hasPrev: getQuizHasPrev(state),
  name: getName(state),
  language: getLanguage(state),
});

export default connect(mapStateToProps)(SummaryPage);
