import React, { Component } from "react";
import mem from "mem";

import { Container, Row, Col, Button } from "../rb-import";

import { browserHistory } from "../../history";
import { logProps } from "../hoc";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
    this.reportError = this.reportError.bind(this);
    this.reset = this.reset.bind(this);
    this.reportError = mem(this.reportError);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    this.reportError(error, errorInfo);
  }

  reset() {
    this.props.resetAppState();
    browserHistory.push("/");
    window.location.reload();
  }

  reportError(error, errorInfo) {
    console.log(
      `Reporting error: ${error.toString()}`,
      this.props.logErrorOnServer
    );
    const { logErrorOnServer } = this.props;
    logErrorOnServer(error.toString(), errorInfo.componentStack);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <Container className="bg-light pb-5 mt-5">
          <Row>
            <Col>
              <h1 className="mt-5">Something went wrong. :(</h1>
              <p className="lead mb-4">
                Our apologies for the inconvenience. Please try again later...
              </p>
              <p className="px-5">
                If you are still experiencing issues, please press the button
                below to start the app again. This will allow you to start fresh
                to see whether that will resolve your issue.
              </p>
              <Button variant="primary" className="mb-4 ml-5" onClick={this.reset}>
                Reset
              </Button>
              <details style={{ whiteSpace: "pre-wrap", fontSize: '80%', color: '#888' }}>
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo.componentStack}
              </details>
            </Col>
          </Row>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
