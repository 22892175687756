import React, { Fragment } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { FormControl, Button } from '../../components/rb-import';
import Header from '../../components/Header';

import style from './name.module.scss';

/**
 * Presents a view that enables the user to enter their first name.
 * 
 * @param {Object} props the component properties
 * @param (String) props.name the user's name
 * @param {Function} props.setName Redux dispatch function to update the user's name
 */
const NamePage = ({ name, setName }) => (
  <Fragment>
    <Header key="header" />
    <div className={classnames(style.panel, 'mx-auto')} key="panel">
      <div className={style.question}>
        <FormattedMessage
          id="name.first"
          description="Request for name"
          defaultMessage="What's your first name?"
        >
          {(text) => text}
        </FormattedMessage>
      </div>
      <form>
        <FormControl
          id="name"
          type="text"
          size="lg"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <LinkContainer to="/motive">
          <Button
            variant="ypri"
            size="yl"
            disabled={name === ''}
            className={classnames('w250','btn-space')}>
            <FormattedMessage
              id="name.button.continue"
              description="Continue button text"
              defaultMessage="Continue"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
        </LinkContainer>
      </form>
    </div>
  </Fragment>
);

export default NamePage;
