import { createReducer, makeActionCreator } from '../utils';
import getFirstBrowserLanguage from '../browser-utils';
import axios from 'axios';

import config from '../../content/config.json';

const SET_LOCALE = 'ygy/config/SET_LOCALE';
const SET_COUNTRY = 'ygy/config/SET_COUNTRY';

const LOG_ISSUE_REQUEST = 'ygy/config/LOG_ISSUE_REQUEST';
const LOG_ISSUE_SUCCESS = 'ygy/config/LOG_ISSUE_SUCCESS';
const LOG_ISSUE_FAILURE = 'ygy/config/LOG_ISSUE_FAILURE';

const findAvailableLocale = (locale) => {
  const parts = locale.split('-');
  if (parts.length === 2) {
    const [language, country] = parts;
    if (config[country]) {
      const { languages } = config[country];
      for (let lang of languages) {
        if (lang === language) {
          return `${language}-${country}`;
        }
      }
      return `${languages[0]}-${country}`;
    }  
  }
  return 'en-US';
};

const initialState = findAvailableLocale(getFirstBrowserLanguage());

const reducer = createReducer(initialState, {
  [SET_LOCALE]: (state, action) => {
    const locale = findAvailableLocale(action.locale);
    return locale;
  },
  [SET_COUNTRY]: (state, action) => {
    const { country } = action;
    const locale = findAvailableLocale(`${state.slice(0,2)}-${country}`);
    return locale;
  }
});

export default reducer;

export const setLocale = makeActionCreator(SET_LOCALE, 'locale');

export const setCountry = makeActionCreator(SET_COUNTRY, 'country');

export function logErrorOnServer(error, info) {
  return {
    types: [
      LOG_ISSUE_REQUEST,
      LOG_ISSUE_SUCCESS,
      LOG_ISSUE_FAILURE,
    ],
    callApi: () => axios.post('/api/logissue', {
      error, info,
    }),
    payload: { error, info },
  };
}

export const getLocale = state => state.config;

export const getLanguage = state => state.config.length > 2 ? state.config.slice(0, 2) : state.config;

export const getCountry = state => state.config.length > 2 ? state.config.slice(3, 5) : 'US';

export const getSupportedLanguages = state => {
  const country = getCountry(state);
  return (config[country] || { languages: ['en'] }).languages;
}