import { defineMessages } from 'react-intl';

import bones from "./images/bones.svg";
import energy from "./images/energy.svg";
import heart from "./images/heart.svg";
import cloud from "./images/cloud.svg";
import clock from "./images/clock.svg";
import shoe from "./images/shoe.svg";
import scale from "./images/scale.svg";
import clipboard from "./images/clipboard.svg";

import animAging from "./animations/aging";
import animEnergy from "./animations/energy";
import animHeart from "./animations/heart";
import animImmune from "./animations/immune";
import animJoints from "./animations/joints";
import animPerf from "./animations/perf";
import animWeight from "./animations/weight";

import scaleMulti from "./images/options/weight.svg";
import shoeMulti from "./images/options/shoe.svg";
import energyMulti from "./images/options/energy.svg";
import muscleMulti from "./images/options/muscle.svg";
import stressMulti from "./images/options/stress.svg";
import menHealthMulti from "./images/options/menhealth.svg";
import womenHealthMulti from "./images/options/womenhealth.svg";
import vitaminsMulti from "./images/options/vitamins.svg";
import immuneMulti from "./images/options/immune.svg";
import antiagingMulti from "./images/options/antiaging.svg";
import circulationMulti from "./images/options/circulation.svg";
import eyeMulti from "./images/options/eye.svg";
import jointMulti from "./images/options/joint.svg";
import mentalMulti from "./images/options/mental.svg";
import broccoliMulti from "./images/options/broccoli.svg";

const summaryText = defineMessages({
  hi: {
    id: "summary.title.hi",
    description: "Hi [name]",
    defaultMessage: "Hi {name}"
  },
  nicework: {
    id: "summary.title.nicework",
    description: "Nice work...",
    defaultMessage: "Nice work, {name}! Section 1 is complete."
  },
  roll2: {
    id: "summary.title.roll2",
    description: "Section 2 is complete",
    defaultMessage: "You're on a roll, {name}! Section 2 is complete."
  },
  roll3: {
    id: "summary.title.roll3",
    description: "Section 3 is complete",
    defaultMessage: "You're on a roll, {name}! Section 3 is complete."
  },
  done4: {
    id: "summary.title.done4",
    description: "Section 4 is complete",
    defaultMessage: "Section 4 is complete"
  },
  done5: {
    id: "summary.title.done5",
    description: "Section 5 is complete",
    defaultMessage: "Section 5 is complete"
  },
  done6: {
    id: "summary.title.done6",
    description: "Section 6 is complete",
    defaultMessage: "Section 6 is complete"
  },
  done7: {
    id: "summary.title.done7",
    description: "Section 7 is complete",
    defaultMessage: "Almost finished! Section 7 is complete"
  },
  done8: {
    id: "summary.title.done8",
    description: "All sections completed",
    defaultMessage: "Woo hoo - you did it!"
  }
});

export const summaryTitle = (segment, name) => {
  switch (segment) {
    case -1:
      return summaryText.hi;
    case 0:
      return summaryText.nicework;
    case 1:
      return summaryText.roll2;
    case 2:
      return summaryText.roll3;
    case 3:
      return summaryText.done4;
    case 4:
      return summaryText.done5;
    case 5:
      return summaryText.done6;
    case 6:
      return summaryText.done7;
    case 7:
      return summaryText.done8;
    default:
      throw new Error(`Invalid segment number: ${segment}`);
  }
};

const quizText = defineMessages({
  "joints.title": {
    id: "quiz.joints.title",
    description: "Title of joints section of quiz",
    defaultMessage: `Let’s start with joints, bones & teeth.`
  },
  "joints-1.title": {
    id: "quiz.joints-1.title",
    description: "Question joints-1 title",
    defaultMessage: `How do your knees, shoulders, and back feel?`
  },
  "joints-1.subtitle": {
    id: "quiz.joints-1.subtitle",
    description: "Question joints-1 subtitle",
    defaultMessage: `LOOSEN UP!`
  },
  "joints-1.body": {
    id: "quiz.joints-1.body",
    description: "Question joints-1 body",
    defaultMessage: `Suffering from pain and/or stiffness? This can be caused by too much sitting and a lack of lower body flexibility.`
  },
  "joints-1.tip": {
    id: "quiz.joints-1.tip",
    description: "Question joints-1 tip",
    defaultMessage: `Stretching and exercising help improve flexibility and relieve pressure.`
  },
  "joints-1.short": {
    id: "quiz.joints-1.short",
    description: "Question joints-1 short",
    defaultMessage: `Knee, shoulder, or back pain`
  },
  "joints-2.title": {
    id: "quiz.joints-2.title",
    description: "Question joints-2 title",
    defaultMessage: `How would you rate your teeth & gum health?`
  },
  "joints-2.subtitle": {
    id: "quiz.joints-2.subtitle",
    description: "Question joints-2 subtitle",
    defaultMessage: `YOUR SMILE SAYS A LOT`
  },
  "joints-2.didYouKnow": {
    id: "quiz.joints-2.didYouKnow",
    description: "Question joints-2 didYouKnow",
    defaultMessage: `Things like bleeding gums, cavities and sensitivity could be signals of poor teeth and gum health.`
  },
  "joints-2.short": {
    id: "quiz.joints-2.short",
    description: "Question joints-2 short",
    defaultMessage: `Gum and teeth sensitivity `
  },
  "joints-3.title": {
    id: "quiz.joints-3.title",
    description: "Question joints-3 title",
    defaultMessage: `How’s your blood pressure?`
  },
  "joints-3.subtitle": {
    id: "quiz.joints-3.subtitle",
    description: "Question joints-3 subtitle",
    defaultMessage: `ON COMMON GROUND`
  },
  "joints-3.didYouKnow": {
    id: "quiz.joints-3.didYouKnow",
    description: "Question joints-3 didYouKnow",
    defaultMessage: `It’s commonly believed that pain, including discomfort in your bones and joints, increases blood pressure.`
  },
  "joints-3.short": {
    id: "quiz.joints-3.short",
    description: "Question joints-3 short",
    defaultMessage: `Blood pressure concerns `
  },
  "joints-4.title": {
    id: "quiz.joints-4.title",
    description: "Question joints-4 title",
    defaultMessage: `How would you rate your sleep patterns?`
  },
  "joints-4.subtitle": {
    id: "quiz.joints-4.subtitle",
    description: "Question joints-4 subtitle",
    defaultMessage: `REST EASY`
  },
  "joints-4.didYouKnow": {
    id: "quiz.joints-4.didYouKnow",
    description: "Question joints-4 didYouKnow",
    defaultMessage: `In addition to helping your bones and joints, minerals such as magnesium can also help increase GABA (a chemical made in the brain), which encourages relaxation as well as sleep.`
  },
  "joints-4.short": {
    id: "quiz.joints-4.short",
    description: "Question joints-4 short",
    defaultMessage: `Trouble falling and staying asleep`
  },
  "joints-5.title": {
    id: "quiz.joints-5.title",
    description: "Question joints-5 title",
    defaultMessage: `How would you rate your joint flexibility?`
  },
  "joints-5.subtitle": {
    id: "quiz.joints-5.subtitle",
    description: "Question joints-5 subtitle",
    defaultMessage: `WALK THE WALK!`
  },
  "joints-5.didYouKnow": {
    id: "quiz.joints-5.didYouKnow",
    description: "Question joints-5 didYouKnow",
    defaultMessage: `When you walk, cartilage acts like a sponge that soaks up nutrients from the compression and decompression. This helps rebuild joints and take pressure off stiff or achy knees.`
  },
  "joints-5.short": {
    id: "quiz.joints-5.short",
    description: "Question joints-5 short",
    defaultMessage: `Joint flexibilty and stiffness`
  },
  "joints-6.title": {
    id: "quiz.joints-6.title",
    description: "Question joints-6 title",
    defaultMessage: `How would you describe your bone and joint history?`
  },
  "joints-6.subtitle": {
    id: "quiz.joints-6.subtitle",
    description: "Question joints-6 subtitle",
    defaultMessage: `REVERSE THE TREND`
  },
  "joints-6.body": {
    id: "quiz.joints-6.body",
    description: "Question joints-6 body",
    defaultMessage: `Most people with prior conditions still live with bone and joint pain.`
  },
  "joints-6.tip": {
    id: "quiz.joints-6.tip",
    description: "Question joints-6 tip",
    defaultMessage: `Glucosamine supplements can help, as it plays a vital role in building cartilage.`
  },
  "joints-6.short": {
    id: "quiz.joints-6.short",
    description: "Question joints-6 short",
    defaultMessage: `Previous bone & joint injury`
  },
  "energy.title": {
    id: "quiz.energy.title",
    description: "Title of energy section of quiz",
    defaultMessage: `Now let’s talk about your metabolism & energy.`
  },
  "energy-1.title": {
    id: "quiz.energy-1.title",
    description: "Question energy-1 title",
    defaultMessage: `How is your energy level during the day?`
  },
  "energy-1.subtitle": {
    id: "quiz.energy-1.subtitle",
    description: "Question energy-1 subtitle",
    defaultMessage: `SEIZE THE DAY!`
  },
  "energy-1.body": {
    id: "quiz.energy-1.body",
    description: "Question energy-1 body",
    defaultMessage: `Protein and fiber help support healthy blood sugar levels, preventing those mid-day energy dips.`
  },
  "energy-1.tip": {
    id: "quiz.energy-1.tip",
    description: "Question energy-1 tip",
    defaultMessage: `To maintain better focus and energy, start your day with protein (e.g. shakes, eggs) instead of bagels or muffins.`
  },
  "energy-1.short": {
    id: "quiz.energy-1.short",
    description: "Question energy-1 short",
    defaultMessage: `Fatigue throughout the day`
  },
  "energy-2.title": {
    id: "quiz.energy-2.title",
    description: "Question energy-2 title",
    defaultMessage: `How easy is it for you to maintain your weight?`
  },
  "energy-2.subtitle": {
    id: "quiz.energy-2.subtitle",
    description: "Question energy-2 subtitle",
    defaultMessage: `WATER FOR THOUGHT`
  },
  "energy-2.body": {
    id: "quiz.energy-2.body",
    description: "Question energy-2 body",
    defaultMessage: `An underactive thyroid can directly impact your ability to lose weight.`
  },
  "energy-2.tip": {
    id: "quiz.energy-2.tip",
    description: "Question energy-2 tip",
    defaultMessage: `Start by drinking 17 ounces of water first thing in the morning. Research shows this can increase metabolism by 30%.`
  },
  "energy-2.short": {
    id: "quiz.energy-2.short",
    description: "Question energy-2 short",
    defaultMessage: `Progressive weight gain`
  },
  "energy-3.title": {
    id: "quiz.energy-3.title",
    description: "Question energy-3 title",
    defaultMessage: `How would you rate your resistance to sugar cravings?`
  },
  "energy-3.subtitle": {
    id: "quiz.energy-3.subtitle",
    description: "Question energy-3 subtitle",
    defaultMessage: `CURB THOSE CRAVINGS!`
  },
  "energy-3.body": {
    id: "quiz.energy-3.body",
    description: "Question energy-3 body",
    defaultMessage: `Carbohydrates convert to glucose and can spike blood sugar
          levels.`
  },
  "energy-3.tip": {
    id: "quiz.energy-3.tip",
    description: "Question energy-3 tip",
    defaultMessage: `To help, try pairing carbs with protein and healthy fats. It can be as easy as nuts and berries, or cheese and veggies.`
  },
  "energy-3.short": {
    id: "quiz.energy-3.short",
    description: "Question energy-3 short",
    defaultMessage: `Constant cravings for sweets `
  },
  "energy-4.title": {
    id: "quiz.energy-4.title",
    description: "Question energy-4 title",
    defaultMessage: `How well does your body stay hydrated?`
  },
  "energy-4.subtitle": {
    id: "quiz.energy-4.subtitle",
    description: "Question energy-4 subtitle",
    defaultMessage: `LOOK OUT FOR SIGNS`
  },
  "energy-4.didYouKnow": {
    id: "quiz.energy-4.didYouKnow",
    description: "Question energy-4 didYouKnow",
    defaultMessage: `If you feel more thirsty than usual, or the need to constantly drink water, this could be a sign from your body that something is wrong.`
  },
  "energy-4.short": {
    id: "quiz.energy-4.short",
    description: "Question energy-4 short",
    defaultMessage: `Excessive sweating or constant thirst `
  },
  "energy-5.title": {
    id: "quiz.energy-5.title",
    description: "Question energy-5 title",
    defaultMessage: `Describe your previous history with blood sugar levels?`
  },
  "energy-5.subtitle": {
    id: "quiz.energy-5.subtitle",
    description: "Question energy-5 subtitle",
    defaultMessage: `MANAGE YOUR BLOOD SUGAR`
  },
  "energy-5.didYouKnow": {
    id: "quiz.energy-5.didYouKnow",
    description: "Question energy-5 didYouKnow",
    defaultMessage: `A year of unmanaged blood sugar levels can cause damage to various body systems and organs.  Have them checked regularly.`
  },
  "energy-5.short": {
    id: "quiz.energy-5.short",
    description: "Question energy-5 short",
    defaultMessage: `Difficulty with weight loss`
  },
  "energy-6.title": {
    id: "quiz.energy-6.title",
    description: "Question energy-6 title",
    defaultMessage: `How would you rate your level of moodiness?`
  },
  "energy-6.subtitle": {
    id: "quiz.energy-6.subtitle",
    description: "Question energy-6 subtitle",
    defaultMessage: `FEELING HANGRY?`
  },
  "energy-6.body": {
    id: "quiz.energy-6.body",
    description: "Question energy-6 body",
    defaultMessage: `Blood sugar swings can rapidly change your mood, making you sad, irritable, and confused.`
  },
  "energy-6.tip": {
    id: "quiz.energy-6.tip",
    description: "Question energy-6 tip",
    defaultMessage: `When you feel that bad mood coming on, eating a healthy snack can put that smile back on your face!`
  },
  "energy-6.short": {
    id: "quiz.energy-6.short",
    description: "Question energy-6 short",
    defaultMessage: `Changes in mood `
  },
  "heart.title": {
    id: "quiz.heart.title",
    description: "Title of heart section of quiz",
    defaultMessage: `Up next: your brain & heart health.`
  },
  "heart-1.title": {
    id: "quiz.heart-1.title",
    description: "Question heart-1 title",
    defaultMessage: `How would you rate your blood circulation?`
  },
  "heart-1.subtitle": {
    id: "quiz.heart-1.subtitle",
    description: "Question heart-1 subtitle",
    defaultMessage: `GET WITH THE FLOW`
  },
  "heart-1.didYouKnow": {
    id: "quiz.heart-1.didYouKnow",
    description: "Question heart-1 didYouKnow",
    defaultMessage: `Exercising regularly supports healthy circulation and blood flow. It can help improve the body’s ability to use oxygen, helping the blood vessels work more efficiently.`
  },
  "heart-1.short": {
    id: "quiz.heart-1.short",
    description: "Question heart-1 short",
    defaultMessage: `Blood flow or circulation problems`
  },
  "heart-2.title": {
    id: "quiz.heart-2.title",
    description: "Question heart-2 title",
    defaultMessage: `How would you describe your heart health history?`
  },
  "heart-2.subtitle": {
    id: "quiz.heart-2.subtitle",
    description: "Question heart-2 subtitle",
    defaultMessage: `TAKE THIS TO HEART`
  },
  "heart-2.tip": {
    id: "quiz.heart-2.tip",
    description: "Question heart-2 tip",
    defaultMessage: `For a variety of reasons, including family genetics, it’s a good idea to support your heart health with certain supplements, including EFAs and antioxidants.`
  },
  "heart-2.short": {
    id: "quiz.heart-2.short",
    description: "Question heart-2 short",
    defaultMessage: `Previous cardiovascular issues`
  },
  "heart-3.title": {
    id: "quiz.heart-3.title",
    description: "Question heart-3 title",
    defaultMessage: `How would you rate your breathing during activity?`
  },
  "heart-3.subtitle": {
    id: "quiz.heart-3.subtitle",
    description: "Question heart-3 subtitle",
    defaultMessage: `BREATHE EASY`
  },
  "heart-3.didYouKnow": {
    id: "quiz.heart-3.didYouKnow",
    description: "Question heart-3 didYouKnow",
    defaultMessage: `If your respiration is healthy, you should be able to carry on a conversation while jogging at a slow pace for 30 minutes.`
  },
  "heart-3.short": {
    id: "quiz.heart-3.short",
    description: "Question heart-3 short",
    defaultMessage: `Difficulty breathing`
  },
  "heart-4.title": {
    id: "quiz.heart-4.title",
    description: "Question heart-4 title",
    defaultMessage: `How do you feel about your vision?`
  },
  "heart-4.subtitle": {
    id: "quiz.heart-4.subtitle",
    description: "Question heart-4 subtitle",
    defaultMessage: `NOT SO EASY ON THE EYES`
  },
  "heart-4.didYouKnow": {
    id: "quiz.heart-4.didYouKnow",
    description: "Question heart-4 didYouKnow",
    defaultMessage: `Untreated hypertension can damage blood vessels in the retina, at the back of the eye where images focus, affecting your overall eye health.`
  },
  "heart-4.short": {
    id: "quiz.heart-4.short",
    description: "Question heart-4 short",
    defaultMessage: `Eye or vision difficulties`
  },
  "heart-5.title": {
    id: "quiz.heart-5.title",
    description: "Question heart-5 title",
    defaultMessage: `How would you rate your memory recall?`
  },
  "heart-5.subtitle": {
    id: "quiz.heart-5.subtitle",
    description: "Question heart-5 subtitle",
    defaultMessage: `IT’S ALL SO CLEAR!`
  },
  "heart-5.body": {
    id: "quiz.heart-5.body",
    description: "Question heart-5 body",
    defaultMessage: `Dark leafy greens provide vitamin E to support cognitive function.`
  },
  "heart-5.tip": {
    id: "quiz.heart-5.tip",
    description: "Question heart-5 tip",
    defaultMessage: `An antioxidant-rich diet may help combat free radicals and support a healthy brain.`
  },
  "heart-5.short": {
    id: "quiz.heart-5.short",
    description: "Question heart-5 short",
    defaultMessage: `Trouble remember things`
  },
  "heart-6.title": {
    id: "quiz.heart-6.title",
    description: "Question heart-6 title",
    defaultMessage: `How would you describe your hair & skin health?`
  },
  "heart-6.subtitle": {
    id: "quiz.heart-6.subtitle",
    description: "Question heart-6 subtitle",
    defaultMessage: `ARE YOU EXPOSED TO STRESS?`
  },
  "heart-6.didYouKnow": {
    id: "quiz.heart-6.didYouKnow",
    description: "Question heart-6 didYouKnow",
    defaultMessage: `Pollution, stress, and a poor diet all contribute to oxidative stress in the body - affecting many aspects of your health, including your hair and skin.`
  },
  "heart-6.short": {
    id: "quiz.heart-6.short",
    description: "Question heart-6 short",
    defaultMessage: `Hair & skin concerns `
  },
  "immune.title": {
    id: "quiz.immune.title",
    description: "Title of immune section of quiz",
    defaultMessage: `Now let’s talk about your digestion & immune health.`
  },
  "immune-1.title": {
    id: "quiz.immune-1.title",
    description: "Question immune-1 title",
    defaultMessage: `How would you rate your regularity?`
  },
  "immune-1.subtitle": {
    id: "quiz.immune-1.subtitle",
    description: "Question immune-1 subtitle",
    defaultMessage: `WHAT YOU DON’T KNOW ABOUT H2O!`
  },
  "immune-1.body": {
    id: "quiz.immune-1.body",
    description: "Question immune-1 body",
    defaultMessage: `Dehydration can slow your metabolism, impacting weight and slowing digestion.`
  },
  "immune-1.tip": {
    id: "quiz.immune-1.tip",
    description: "Question immune-1 tip",
    defaultMessage: `Drink eight glasses of water a day and eat more fiber.`
  },
  "immune-1.short": {
    id: "quiz.immune-1.short",
    description: "Question immune-1 short",
    defaultMessage: `Constipation or diarrhea`
  },
  "immune-2.title": {
    id: "quiz.immune-2.title",
    description: "Question immune-2 title",
    defaultMessage: `How would you rate your frequency with gas and bloating?`
  },
  "immune-2.subtitle": {
    id: "quiz.immune-2.subtitle",
    description: "Question immune-2 subtitle",
    defaultMessage: `DRINK BEFORE YOU EAT`
  },
  "immune-2.body": {
    id: "quiz.immune-2.body",
    description: "Question immune-2 body",
    defaultMessage: `Gas and burping are common after drinking and eating.`
  },
  "immune-2.tip": {
    id: "quiz.immune-2.tip",
    description: "Question immune-2 tip",
    defaultMessage: `To help reduce gas and bloating, drink fluids 30 minutes before eating.`
  },
  "immune-2.short": {
    id: "quiz.immune-2.short",
    description: "Question immune-2 short",
    defaultMessage: `Gas, bloating or upset stomach `
  },
  "immune-3.title": {
    id: "quiz.immune-3.title",
    description: "Question immune-3 title",
    defaultMessage: `Rate your frequency of heartburn or indigestion?`
  },
  "immune-3.subtitle": {
    id: "quiz.immune-3.subtitle",
    description: "Question immune-3 subtitle",
    defaultMessage: `SPICE ISN’T ALWAYS NICE`
  },
  "immune-3.didYouKnow": {
    id: "quiz.immune-3.didYouKnow",
    description: "Question immune-3 didYouKnow",
    defaultMessage: `Stress, travel, poor diet, and even spicy foods can all lead to an upset stomach, heartburn, or indigestion.`
  },
  "immune-3.short": {
    id: "quiz.immune-3.short",
    description: "Question immune-3 short",
    defaultMessage: `Heartburn or indigestion `
  },
  "immune-4.title": {
    id: "quiz.immune-4.title",
    description: "Question immune-4 title",
    defaultMessage: `How well does your body fight off colds and flus?`
  },
  "immune-4.subtitle": {
    id: "quiz.immune-4.subtitle",
    description: "Question immune-4 subtitle",
    defaultMessage: `STAY STRONG!`
  },
  "immune-4.body": {
    id: "quiz.immune-4.body",
    description: "Question immune-4 body",
    defaultMessage: `A weak immune system is often due to an imbalance of good and bad bacteria in the gut.`
  },
  "immune-4.tip": {
    id: "quiz.immune-4.tip",
    description: "Question immune-4 tip",
    defaultMessage: `Probiotics help by naturally boosting your immune health.`
  },
  "immune-4.short": {
    id: "quiz.immune-4.short",
    description: "Question immune-4 short",
    defaultMessage: `Prolonged cold or flu`
  },
  "immune-5.title": {
    id: "quiz.immune-5.title",
    description: "Question immune-5 title",
    defaultMessage: `Rate your sensitivity to food or environmental allergies?`
  },
  "immune-5.subtitle": {
    id: "quiz.immune-5.subtitle",
    description: "Question immune-5 subtitle",
    defaultMessage: `PAY ATTENTION TO THE SYMPTOMS`
  },
  "immune-5.body": {
    id: "quiz.immune-5.body",
    description: "Question immune-5 body",
    defaultMessage: `If you’re allergic to certain substances, your immune system overreacts by producing antibodies that cause wheezing, cramping and other undesirable symptoms.`
  },
  "immune-5.short": {
    id: "quiz.immune-5.short",
    description: "Question immune-5 short",
    defaultMessage: `Allergies to foods or environment`
  },
  "immune-6.title": {
    id: "quiz.immune-6.title",
    description: "Question immune-6 title",
    defaultMessage: `How would you rate your immune system?`
  },
  "immune-6.subtitle": {
    id: "quiz.immune-6.subtitle",
    description: "Question immune-6 subtitle",
    defaultMessage: `A HEALTHY BALANCE`
  },
  "immune-6.body": {
    id: "quiz.immune-6.body",
    description: "Question immune-6 body",
    defaultMessage: `Your immune system spends most of its time in your gut.`
  },
  "immune-6.tip": {
    id: "quiz.immune-6.tip",
    description: "Question immune-6 tip",
    defaultMessage: `Maintaining a healthy balance of good bacteria in your digestive system also helps you maintain a healthy immune system.`
  },
  "immune-6.short": {
    id: "quiz.immune-6.short",
    description: "Question immune-6 short",
    defaultMessage: `Immune system concerns  `
  },
  "aging.title": {
    id: "quiz.aging.title",
    description: "Title of aging section of quiz",
    defaultMessage: `Time to discuss how you’re aging.`
  },
  "aging-1.title": {
    id: "quiz.aging-1.title",
    description: "Question aging-1 title",
    defaultMessage: `How do you feel about your skin’s appearance?`
  },
  "aging-1.subtitle": {
    id: "quiz.aging-1.subtitle",
    description: "Question aging-1 subtitle",
    defaultMessage: `DEEPER THAN SKIN`
  },
  "aging-1.didYouKnow": {
    id: "quiz.aging-1.didYouKnow",
    description: "Question aging-1 didYouKnow",
    defaultMessage: `Foods rich in polyphenols and phytochemicals (berries & dark leafy greens) can help you have beautiful skin from within - helping with fine lines and wrinkles.`
  },
  "aging-1.short": {
    id: "quiz.aging-1.short",
    description: "Question aging-1 short",
    defaultMessage: `Fine lines & wrinkles`
  },
  "aging-2.title": {
    id: "quiz.aging-2.title",
    description: "Question aging-2 title",
    defaultMessage: `How does your skin react to sun exposure?`
  },
  "aging-2.subtitle": {
    id: "quiz.aging-2.subtitle",
    description: "Question aging-2 subtitle",
    defaultMessage: `TAKE NOTE!`
  },
  "aging-2.didYouKnow": {
    id: "quiz.aging-2.didYouKnow",
    description: "Question aging-2 didYouKnow",
    defaultMessage: `Pollution, sun exposure, and other stressors can take a toll on your skin and other cells in your body. Antioxidants can help combat oxidative stress at the cellular level.`
  },
  "aging-2.short": {
    id: "quiz.aging-2.short",
    description: "Question aging-2 short",
    defaultMessage: `Environmental skin damage`
  },
  "aging-3.title": {
    id: "quiz.aging-3.title",
    description: "Question aging-3 title",
    defaultMessage: `How are your energy levels in the midafternoon?`
  },
  "aging-3.subtitle": {
    id: "quiz.aging-3.subtitle",
    description: "Question aging-3 subtitle",
    defaultMessage: `AFTERNOON CRASH`
  },
  "aging-3.didYouKnow": {
    id: "quiz.aging-3.didYouKnow",
    description: "Question aging-3 didYouKnow",
    defaultMessage: `A common sign of not being able to keep up with toxins is tiredness; especially during that part of the day.`
  },
  "aging-3.short": {
    id: "quiz.aging-3.short",
    description: "Question aging-3 short",
    defaultMessage: `Exposure to free radicals`
  },
  "aging-4.title": {
    id: "quiz.aging-4.title",
    description: "Question aging-4 title",
    defaultMessage: `How would you rate your cardiovascular health?`
  },
  "aging-4.subtitle": {
    id: "quiz.aging-4.subtitle",
    description: "Question aging-4 subtitle",
    defaultMessage: `DON'T STRESS!`
  },
  "aging-4.body": {
    id: "quiz.aging-4.body",
    description: "Question aging-4 body",
    defaultMessage: `Oxidative stress can cause a myriad of problems, including impacting your heart health.`
  },
  "aging-4.tip": {
    id: "quiz.aging-4.tip",
    description: "Question aging-4 tip",
    defaultMessage: `An antioxidant-rich diet helps, by protecting the heart and other vital organs.`
  },
  "aging-4.short": {
    id: "quiz.aging-4.short",
    description: "Question aging-4 short",
    defaultMessage: `Cardiovascular concerns`
  },
  "aging-5.title": {
    id: "quiz.aging-5.title",
    description: "Question aging-5 title",
    defaultMessage: `How would you rate the firmness of your skin?`
  },
  "aging-5.subtitle": {
    id: "quiz.aging-5.subtitle",
    description: "Question aging-5 subtitle",
    defaultMessage: `DRINK UP!`
  },
  "aging-5.didYouKnow": {
    id: "quiz.aging-5.didYouKnow",
    description: "Question aging-5 didYouKnow",
    defaultMessage: `Milk peptides sourced from milk help boost collagen and increase the production of hyaluronic acid, supporting the regeneration of aging skin and helping improve skin firmness.`
  },
  "aging-5.short": {
    id: "quiz.aging-5.short",
    description: "Question aging-5 short",
    defaultMessage: `Sagging skin`
  },
  "aging-6.title": {
    id: "quiz.aging-6.title",
    description: "Question aging-6 title",
    defaultMessage: `How do you feel about your mental clarity?`
  },
  "aging-6.subtitle": {
    id: "quiz.aging-6.subtitle",
    description: "Question aging-6 subtitle",
    defaultMessage: `FOOD FOR THOUGHT`
  },
  "aging-6.didYouKnow": {
    id: "quiz.aging-6.didYouKnow",
    description: "Question aging-6 didYouKnow",
    defaultMessage: `Deep water fish like salmon are rich in omega-3 essential fatty acids which may support brain function and cardiovascular health.`
  },
  "aging-6.short": {
    id: "quiz.aging-6.short",
    description: "Question aging-6 short",
    defaultMessage: `Cognition problems`
  },
  "perf.title": {
    id: "quiz.perf.title",
    description: "Title of perf section of quiz",
    defaultMessage: `Now onto athletic performance.`
  },
  "perf-1.title": {
    id: "quiz.perf-1.title",
    description: "Question perf-1 title",
    defaultMessage: `How do your joints feel during exercise?`
  },
  "perf-1.subtitle": {
    id: "quiz.perf-1.subtitle",
    description: "Question perf-1 subtitle",
    defaultMessage: `FOOD FOR THOUGHT`
  },
  "perf-1.body": {
    id: "quiz.perf-1.body",
    description: "Question perf-1 body",
    defaultMessage: `Joint pain prevents many individuals from exercising.`
  },
  "perf-1.tip": {
    id: "quiz.perf-1.tip",
    description: "Question perf-1 tip",
    defaultMessage: `Eating foods that are high in Omega-3 and Vitamin D may help keep those joints and tendons discomfort-free and flexible.`
  },
  "perf-1.short": {
    id: "quiz.perf-1.short",
    description: "Question perf-1 short",
    defaultMessage: `Achy joints during exercise`
  },
  "perf-2.title": {
    id: "quiz.perf-2.title",
    description: "Question perf-2 title",
    defaultMessage: `How well does your body manage thirst during exercise?`
  },
  "perf-2.subtitle": {
    id: "quiz.perf-2.subtitle",
    description: "Question perf-2 subtitle",
    defaultMessage: `QUENCH YOUR HEALTH`
  },
  "perf-2.didYouKnow": {
    id: "quiz.perf-2.didYouKnow",
    description: "Question perf-2 didYouKnow",
    defaultMessage: `Minerals like potassium and magnesium contain electrolytes, which are responsible for directing water and nutrients to the areas of the body where it’s needed most.`
  },
  "perf-2.short": {
    id: "quiz.perf-2.short",
    description: "Question perf-2 short",
    defaultMessage: `Constant thirst`
  },
  "perf-3.title": {
    id: "quiz.perf-3.title",
    description: "Question perf-3 title",
    defaultMessage: `How would you rate your muscle recovery after exercise?`
  },
  "perf-3.subtitle": {
    id: "quiz.perf-3.subtitle",
    description: "Question perf-3 subtitle",
    defaultMessage: `HELP THOSE MUSCLES GROW`
  },
  "perf-3.didYouKnow": {
    id: "quiz.perf-3.didYouKnow",
    description: "Question perf-3 didYouKnow",
    defaultMessage: `Essential amino acids such as lysine, phenylalanine, methionine, and leucine are produced from protein synthesis and essential for muscle recovery and growth.`
  },
  "perf-3.short": {
    id: "quiz.perf-3.short",
    description: "Question perf-3 short",
    defaultMessage: `Muscle recovery`
  },
  "perf-4.title": {
    id: "quiz.perf-4.title",
    description: "Question perf-4 title",
    defaultMessage: `Rate your joint mobility & flexibility during exercise?`
  },
  "perf-4.subtitle": {
    id: "quiz.perf-4.subtitle",
    description: "Question perf-4 subtitle",
    defaultMessage: `REACH FOR YOUR TOES`
  },
  "perf-4.body": {
    id: "quiz.perf-4.body",
    description: "Question perf-4 body",
    defaultMessage: `Stretching is not only good for your muscles and joints, but it also helps support blood circulation and increase energy levels.`
  },
  "perf-4.tip": {
    id: "quiz.perf-4.tip",
    description: "Question perf-4 tip",
    defaultMessage: `Take at least 10 mins a day to stretch!`
  },
  "perf-4.short": {
    id: "quiz.perf-4.short",
    description: "Question perf-4 short",
    defaultMessage: `Joint mobility & flexibility  `
  },
  "perf-5.title": {
    id: "quiz.perf-5.title",
    description: "Question perf-5 title",
    defaultMessage: `How would you rate your current hydration options?`
  },
  "perf-5.subtitle": {
    id: "quiz.perf-5.subtitle",
    description: "Question perf-5 subtitle",
    defaultMessage: `ALWAYS GET YOUR FLUIDS!`
  },
  "perf-5.didYouKnow": {
    id: "quiz.perf-5.didYouKnow",
    description: "Question perf-5 didYouKnow",
    defaultMessage: `As 70% of your lean body mass is made up of water, staying hydrated before, during, and after exercise is essential for many functions throughout the body.`
  },
  "perf-5.short": {
    id: "quiz.perf-5.short",
    description: "Question perf-5 short",
    defaultMessage: `Dehydration`
  },
  "perf-6.title": {
    id: "quiz.perf-6.title",
    description: "Question perf-6 title",
    defaultMessage: `How well do you maintain energy during exercise?`
  },
  "perf-6.subtitle": {
    id: "quiz.perf-6.subtitle",
    description: "Question perf-6 subtitle",
    defaultMessage: `FUEL UP!`
  },
  "perf-6.tip": {
    id: "quiz.perf-6.tip",
    description: "Question perf-6 tip",
    defaultMessage: `Eating light carbs like fruits two hours before a long workout helps you sustain energy for optimal results.`
  },
  "perf-6.short": {
    id: "quiz.perf-6.short",
    description: "Question perf-6 short",
    defaultMessage: `Low energy`
  },
  "weight.title": {
    id: "quiz.weight.title",
    description: "Title of weight section of quiz",
    defaultMessage: `Almost there. Let’s talk weight loss.`
  },
  "weight-1.title": {
    id: "quiz.weight-1.title",
    description: "Question weight-1 title",
    defaultMessage: `How well do you stick to a diet?`
  },
  "weight-1.subtitle": {
    id: "quiz.weight-1.subtitle",
    description: "Question weight-1 subtitle",
    defaultMessage: `NEED A PLAN FOR SUCCESS?`
  },
  "weight-1.tip": {
    id: "quiz.weight-1.tip",
    description: "Question weight-1 tip",
    defaultMessage: `Check out our Better Health Challenge. With three programs to choose from, you can find one that’s ideal for your lifestyle.`
  },
  "weight-1.short": {
    id: "quiz.weight-1.short",
    description: "Question weight-1 short",
    defaultMessage: `Adherence to diets`
  },
  "weight-2.title": {
    id: "quiz.weight-2.title",
    description: "Question weight-2 title",
    defaultMessage: `How well do you refrain from overeating?`
  },
  "weight-2.subtitle": {
    id: "quiz.weight-2.subtitle",
    description: "Question weight-2 subtitle",
    defaultMessage: `CHEW SLOWLY`
  },
  "weight-2.didYouKnow": {
    id: "quiz.weight-2.didYouKnow",
    description: "Question weight-2 didYouKnow",
    defaultMessage: `Eating too quickly doesn’t allow your body to digest foods and absorb the nutrients properly, leaving you feeling hungry. Chew slowly, and let your body do its work.`
  },
  "weight-2.short": {
    id: "quiz.weight-2.short",
    description: "Question weight-2 short",
    defaultMessage: `Overeating`
  },
  "weight-3.title": {
    id: "quiz.weight-3.title",
    description: "Question weight-3 title",
    defaultMessage: `How would you rate your body’s ability lose weight?`
  },
  "weight-3.subtitle": {
    id: "quiz.weight-3.subtitle",
    description: "Question weight-3 subtitle",
    defaultMessage: `IT’S TEA TIME!`
  },
  "weight-3.didYouKnow": {
    id: "quiz.weight-3.didYouKnow",
    description: "Question weight-3 didYouKnow",
    defaultMessage: `Certain foods like green tea are credited by numerous scientific studies to help accelerate metabolism, which can naturally enhance your results.`
  },
  "weight-3.short": {
    id: "quiz.weight-3.short",
    description: "Question weight-3 short",
    defaultMessage: `Losing weight`
  },
  "weight-4.title": {
    id: "quiz.weight-4.title",
    description: "Question weight-4 title",
    defaultMessage: `How would you describe your food portion control?`
  },
  "weight-4.subtitle": {
    id: "quiz.weight-4.subtitle",
    description: "Question weight-4 subtitle",
    defaultMessage: `TAKE CONTROL OF YOUR KITCHEN!`
  },
  "weight-4.body": {
    id: "quiz.weight-4.body",
    description: "Question weight-4 body",
    defaultMessage: `Restaurants tend to offer much more food than we actually need.`
  },
  "weight-4.tip": {
    id: "quiz.weight-4.tip",
    description: "Question weight-4 tip",
    defaultMessage: `Cooking your own meals puts you in the driver’s seat with portion control.`
  },
  "weight-4.short": {
    id: "quiz.weight-4.short",
    description: "Question weight-4 short",
    defaultMessage: `Portion control`
  },
  "weight-5.title": {
    id: "quiz.weight-5.title",
    description: "Question weight-5 title",
    defaultMessage: `How well does your body manage weight fluctuation?`
  },
  "weight-5.subtitle": {
    id: "quiz.weight-5.subtitle",
    description: "Question weight-5 subtitle",
    defaultMessage: `KEEP IT STEADY`
  },
  "weight-5.didYouKnow": {
    id: "quiz.weight-5.didYouKnow",
    description: "Question weight-5 didYouKnow",
    defaultMessage: `Consuming too many carbohydrates without being active can cause weight fluctuation. When carbs aren’t used to fuel exercise, they get stored in your body’s fat cells.`
  },
  "weight-5.short": {
    id: "quiz.weight-5.short",
    description: "Question weight-5 short",
    defaultMessage: `Fluctuating weight`
  },
  "weight-6.title": {
    id: "quiz.weight-6.title",
    description: "Question weight-6 title",
    defaultMessage: `How would you rate your enthusiasm for reaching goals?`
  },
  "weight-6.subtitle": {
    id: "quiz.weight-6.subtitle",
    description: "Question weight-6 subtitle",
    defaultMessage: `BABY STEPS!`
  },
  "weight-6.tip": {
    id: "quiz.weight-6.tip",
    description: "Question weight-6 tip",
    defaultMessage: `Set and reach small goals. As you master experiences, your confidence level improves, and each completed task becomes a stepping stone to a larger accomplishment.`
  },
  "weight-6.short": {
    id: "quiz.weight-6.short",
    description: "Question weight-6 short",
    defaultMessage: `Lack of Enthusiasm `
  },
  "misc.title": {
    id: "quiz.misc.title",
    description: "Title of misc section of quiz",
    defaultMessage: `Just a few more things.`
  },
  "misc-1.title": {
    id: "quiz.misc-1.title",
    description: "Question misc-1 title",
    defaultMessage: `Do you have any physical wellness goals?`
  },
  "misc-1.subtitle": {
    id: "quiz.misc-1.subtitle",
    description: "Question misc-1 subtitle",
    defaultMessage: `SELECT ALL THAT APPLY`
  },
  "misc-1.weight": {
    id: "quiz.misc-1.weight",
    description: "Option weight for question misc-1",
    defaultMessage: `WEIGHT LOSS`
  },
  "misc-1.muscle": {
    id: "quiz.misc-1.muscle",
    description: "Option muscle for question misc-1",
    defaultMessage: `BUILDING MUSCLE`
  },
  "misc-1.exercise": {
    id: "quiz.misc-1.exercise",
    description: "Option exercise for question misc-1",
    defaultMessage: `EXERCISE & PERFORMANCE`
  },
  "misc-1.energy": {
    id: "quiz.misc-1.energy",
    description: "Option energy for question misc-1",
    defaultMessage: `ENERGY`
  },
  "misc-2.title": {
    id: "quiz.misc-2.title",
    description: "Question misc-2 title",
    defaultMessage: `Do you have any hormonal or stress related goals?`
  },
  "misc-2.subtitle": {
    id: "quiz.misc-2.subtitle",
    description: "Question misc-2 subtitle",
    defaultMessage: `SELECT ALL THAT APPLY`
  },
  "misc-2.men-health": {
    id: "quiz.misc-2.men-health",
    description: "Option men-health for question misc-2",
    defaultMessage: `MEN'S HEALTH`
  },
  "misc-2.women-health": {
    id: "quiz.misc-2.women-health",
    description: "Option women-health for question misc-2",
    defaultMessage: `WOMEN'S HEALTH`
  },
  "misc-2.stress": {
    id: "quiz.misc-2.stress",
    description: "Option stress for question misc-2",
    defaultMessage: `STRESS & SLEEP`
  },
  "misc-3.title": {
    id: "quiz.misc-3.title",
    description: "Question misc-3 title",
    defaultMessage: `Do you have any advanced nutrition goals?`
  },
  "misc-3.subtitle": {
    id: "quiz.misc-3.subtitle",
    description: "Question misc-3 subtitle",
    defaultMessage: `SELECT ALL THAT APPLY`
  },
  "misc-3.vitamins": {
    id: "quiz.misc-3.vitamins",
    description: "Option vitamins for question misc-3",
    defaultMessage: `GETTING ENOUGH VITAMINS`
  },
  "misc-3.whole-foods": {
    id: "quiz.misc-3.whole-foods",
    description: "Option whole-foods for question misc-3",
    defaultMessage: `NUTRIENT-DENSE WHOLE FOODS`
  },
  "misc-3.anti-aging": {
    id: "quiz.misc-3.anti-aging",
    description: "Option anti-aging for question misc-3",
    defaultMessage: `ANTI-AGING NEEDS`
  },
  "misc-3.immune-system": {
    id: "quiz.misc-3.immune-system",
    description: "Option immune-system for question misc-3",
    defaultMessage: `BUILD A BETTER IMMUNE SYSTEM`
  },
  "misc-4.title": {
    id: "quiz.misc-4.title",
    description: "Question misc-4 title",
    defaultMessage: `Do you have any targeted health goals?`
  },
  "misc-4.subtitle": {
    id: "quiz.misc-4.subtitle",
    description: "Question misc-4 subtitle",
    defaultMessage: `SELECT ALL THAT APPLY`
  },
  "misc-4.circulation": {
    id: "quiz.misc-4.circulation",
    description: "Option circulation for question misc-4",
    defaultMessage: `CIRCULATION & BLOOD VESSELS`
  },
  "misc-4.eye-health": {
    id: "quiz.misc-4.eye-health",
    description: "Option eye-health for question misc-4",
    defaultMessage: `EYE HEALTH`
  },
  "misc-4.joint-mobility": {
    id: "quiz.misc-4.joint-mobility",
    description: "Option joint-mobility for question misc-4",
    defaultMessage: `JOINT MOBILITY & COMFORT`
  },
  "misc-4.mental-focus": {
    id: "quiz.misc-4.mental-focus",
    description: "Option mental-focus for question misc-4",
    defaultMessage: `MENTAL FOCUS & MEMORY`
  }
});

const segments = [
  {
    segmentId: "joints",
    intro: {
      title: quizText["joints.title"],
      image: bones,
      animation: animJoints,
      bg: "#EF8137"
    },
    questions: [
      {
        id: "joints-1",
        category: 1,
        type: "single",
        title: quizText["joints-1.title"],
        subtitle: quizText["joints-1.subtitle"],
        body: quizText["joints-1.body"],
        tip: quizText["joints-1.tip"],
        short: quizText["joints-1.short"]
      },
      {
        id: "joints-2",
        category: 1,
        type: "single",
        title: quizText["joints-2.title"],
        subtitle: quizText["joints-2.subtitle"],
        didYouKnow: quizText["joints-2.didYouKnow"],
        short: quizText["joints-2.short"]
      },
      {
        id: "joints-3",
        category: 1,
        type: "single",
        title: quizText["joints-3.title"],
        subtitle: quizText["joints-3.subtitle"],
        didYouKnow: quizText["joints-3.didYouKnow"],
        short: quizText["joints-3.short"]
      },
      {
        id: "joints-4",
        category: 1,
        type: "single",
        title: quizText["joints-4.title"],
        subtitle: quizText["joints-4.subtitle"],
        didYouKnow: quizText["joints-4.didYouKnow"],
        short: quizText["joints-4.short"]
      },
      {
        id: "joints-5",
        category: 1,
        type: "single",
        title: quizText["joints-5.title"],
        subtitle: quizText["joints-5.subtitle"],
        didYouKnow: quizText["joints-5.didYouKnow"],
        short: quizText["joints-5.short"]
      },
      {
        id: "joints-6",
        category: 1,
        type: "single",
        title: quizText["joints-6.title"],
        subtitle: quizText["joints-6.subtitle"],
        body: quizText["joints-6.body"],
        tip: quizText["joints-6.tip"],
        short: quizText["joints-6.short"]
      }
    ]
  },
  {
    segmentId: "energy",
    intro: {
      title: quizText["energy.title"],
      animation: animEnergy,
      image: energy,
      bg: "#01A4AD"
    },
    questions: [
      {
        id: "energy-1",
        category: 2,
        type: "single",
        title: quizText["energy-1.title"],
        subtitle: quizText["energy-1.subtitle"],
        body: quizText["energy-1.body"],
        tip: quizText["energy-1.tip"],
        short: quizText["energy-1.short"]
      },
      {
        id: "energy-2",
        category: 2,
        type: "single",
        title: quizText["energy-2.title"],
        subtitle: quizText["energy-2.subtitle"],
        body: quizText["energy-2.body"],
        tip: quizText["energy-2.tip"],
        short: quizText["energy-2.short"]
      },
      {
        id: "energy-3",
        category: 2,
        type: "single",
        title: quizText["energy-3.title"],
        subtitle: quizText["energy-3.subtitle"],
        body: quizText["energy-3.body"],
        tip: quizText["energy-3.tip"],
        short: quizText["energy-3.short"]
      },
      {
        id: "energy-4",
        category: 2,
        type: "single",
        title: quizText["energy-4.title"],
        subtitle: quizText["energy-4.subtitle"],
        didYouKnow: quizText["energy-4.didYouKnow"],
        short: quizText["energy-4.short"]
      },
      {
        id: "energy-5",
        category: 2,
        type: "single",
        title: quizText["energy-5.title"],
        subtitle: quizText["energy-5.subtitle"],
        didYouKnow: quizText["energy-5.didYouKnow"],
        short: quizText["energy-5.short"]
      },
      {
        id: "energy-6",
        category: 2,
        type: "single",
        title: quizText["energy-6.title"],
        subtitle: quizText["energy-6.subtitle"],
        body: quizText["energy-6.body"],
        tip: quizText["energy-6.tip"],
        short: quizText["energy-6.short"]
      }
    ]
  },
  {
    segmentId: "heart",
    intro: {
      title: quizText["heart.title"],
      animation: animHeart,
      image: heart,
      bg: "#C0D546"
    },
    questions: [
      {
        id: "heart-1",
        category: 3,
        type: "single",
        title: quizText["heart-1.title"],
        subtitle: quizText["heart-1.subtitle"],
        didYouKnow: quizText["heart-1.didYouKnow"],
        short: quizText["heart-1.short"]
      },
      {
        id: "heart-2",
        category: 3,
        type: "single",
        title: quizText["heart-2.title"],
        subtitle: quizText["heart-2.subtitle"],
        tip: quizText["heart-2.tip"],
        short: quizText["heart-2.short"]
      },
      {
        id: "heart-3",
        category: 3,
        type: "single",
        title: quizText["heart-3.title"],
        subtitle: quizText["heart-3.subtitle"],
        didYouKnow: quizText["heart-3.didYouKnow"],
        short: quizText["heart-3.short"]
      },
      {
        id: "heart-4",
        category: 3,
        type: "single",
        title: quizText["heart-4.title"],
        subtitle: quizText["heart-4.subtitle"],
        didYouKnow: quizText["heart-4.didYouKnow"],
        short: quizText["heart-4.short"]
      },
      {
        id: "heart-5",
        category: 3,
        type: "single",
        title: quizText["heart-5.title"],
        subtitle: quizText["heart-5.subtitle"],
        body: quizText["heart-5.body"],
        tip: quizText["heart-5.tip"],
        short: quizText["heart-5.short"]
      },
      {
        id: "heart-6",
        category: 3,
        type: "single",
        title: quizText["heart-6.title"],
        subtitle: quizText["heart-6.subtitle"],
        didYouKnow: quizText["heart-6.didYouKnow"],
        short: quizText["heart-6.short"]
      }
    ]
  },
  {
    segmentId: "immune",
    intro: {
      title: quizText["immune.title"],
      animation: animImmune,
      image: cloud,
      bg: "#EB552A"
    },
    questions: [
      {
        id: "immune-1",
        category: 4,
        type: "single",
        title: quizText["immune-1.title"],
        subtitle: quizText["immune-1.subtitle"],
        body: quizText["immune-1.body"],
        tip: quizText["immune-1.tip"],
        short: quizText["immune-1.short"]
      },
      {
        id: "immune-2",
        category: 4,
        type: "single",
        title: quizText["immune-2.title"],
        subtitle: quizText["immune-2.subtitle"],
        body: quizText["immune-2.body"],
        tip: quizText["immune-2.tip"],
        short: quizText["immune-2.short"]
      },
      {
        id: "immune-3",
        category: 4,
        type: "single",
        title: quizText["immune-3.title"],
        subtitle: quizText["immune-3.subtitle"],
        didYouKnow: quizText["immune-3.didYouKnow"],
        short: quizText["immune-3.short"]
      },
      {
        id: "immune-4",
        category: 4,
        type: "single",
        title: quizText["immune-4.title"],
        subtitle: quizText["immune-4.subtitle"],
        body: quizText["immune-4.body"],
        tip: quizText["immune-4.tip"],
        short: quizText["immune-4.short"]
      },
      {
        id: "immune-5",
        category: 4,
        type: "single",
        title: quizText["immune-5.title"],
        subtitle: quizText["immune-5.subtitle"],
        body: quizText["immune-5.body"],
        short: quizText["immune-5.short"]
      },
      {
        id: "immune-6",
        category: 4,
        type: "single",
        title: quizText["immune-6.title"],
        subtitle: quizText["immune-6.subtitle"],
        body: quizText["immune-6.body"],
        tip: quizText["immune-6.tip"],
        short: quizText["immune-6.short"]
      }
    ]
  },
  {
    segmentId: "aging",
    intro: {
      title: quizText["aging.title"],
      animation: animAging,
      image: clock,
      bg: "#48B67C"
    },
    questions: [
      {
        id: "aging-1",
        category: 5,
        type: "single",
        title: quizText["aging-1.title"],
        subtitle: quizText["aging-1.subtitle"],
        didYouKnow: quizText["aging-1.didYouKnow"],
        short: quizText["aging-1.short"]
      },
      {
        id: "aging-2",
        category: 5,
        type: "single",
        title: quizText["aging-2.title"],
        subtitle: quizText["aging-2.subtitle"],
        didYouKnow: quizText["aging-2.didYouKnow"],
        short: quizText["aging-2.short"]
      },
      {
        id: "aging-3",
        category: 5,
        type: "single",
        title: quizText["aging-3.title"],
        subtitle: quizText["aging-3.subtitle"],
        didYouKnow: quizText["aging-3.didYouKnow"],
        short: quizText["aging-3.short"]
      },
      {
        id: "aging-4",
        category: 5,
        type: "single",
        title: quizText["aging-4.title"],
        subtitle: quizText["aging-4.subtitle"],
        body: quizText["aging-4.body"],
        tip: quizText["aging-4.tip"],
        short: quizText["aging-4.short"]
      },
      {
        id: "aging-5",
        category: 5,
        type: "single",
        title: quizText["aging-5.title"],
        subtitle: quizText["aging-5.subtitle"],
        didYouKnow: quizText["aging-5.didYouKnow"],
        short: quizText["aging-5.short"]
      },
      {
        id: "aging-6",
        category: 5,
        type: "single",
        title: quizText["aging-6.title"],
        subtitle: quizText["aging-6.subtitle"],
        didYouKnow: quizText["aging-6.didYouKnow"],
        short: quizText["aging-6.short"]
      }
    ]
  },
  {
    segmentId: "perf",
    intro: {
      title: quizText["perf.title"],
      animation: animPerf,
      image: shoe,
      bg: "#3FC2CD"
    },
    questions: [
      {
        id: "perf-1",
        category: 6,
        type: "single",
        title: quizText["perf-1.title"],
        subtitle: quizText["perf-1.subtitle"],
        body: quizText["perf-1.body"],
        tip: quizText["perf-1.tip"],
        short: quizText["perf-1.short"]
      },
      {
        id: "perf-2",
        category: 6,
        type: "single",
        title: quizText["perf-2.title"],
        subtitle: quizText["perf-2.subtitle"],
        didYouKnow: quizText["perf-2.didYouKnow"],
        short: quizText["perf-2.short"]
      },
      {
        id: "perf-3",
        category: 6,
        type: "single",
        title: quizText["perf-3.title"],
        subtitle: quizText["perf-3.subtitle"],
        didYouKnow: quizText["perf-3.didYouKnow"],
        short: quizText["perf-3.short"]
      },
      {
        id: "perf-4",
        category: 6,
        type: "single",
        title: quizText["perf-4.title"],
        subtitle: quizText["perf-4.subtitle"],
        body: quizText["perf-4.body"],
        tip: quizText["perf-4.tip"],
        short: quizText["perf-4.short"]
      },
      {
        id: "perf-5",
        category: 6,
        type: "single",
        title: quizText["perf-5.title"],
        subtitle: quizText["perf-5.subtitle"],
        didYouKnow: quizText["perf-5.didYouKnow"],
        short: quizText["perf-5.short"]
      },
      {
        id: "perf-6",
        category: 6,
        type: "single",
        title: quizText["perf-6.title"],
        subtitle: quizText["perf-6.subtitle"],
        tip: quizText["perf-6.tip"],
        short: quizText["perf-6.short"]
      }
    ]
  },
  {
    segmentId: "weight",
    intro: {
      title: quizText["weight.title"],
      animation: animWeight,
      image: scale,
      bg: "#E13A85"
    },
    questions: [
      {
        id: "weight-1",
        category: 7,
        type: "single",
        title: quizText["weight-1.title"],
        subtitle: quizText["weight-1.subtitle"],
        tip: quizText["weight-1.tip"],
        short: quizText["weight-1.short"]
      },
      {
        id: "weight-2",
        category: 7,
        type: "single",
        title: quizText["weight-2.title"],
        subtitle: quizText["weight-2.subtitle"],
        didYouKnow: quizText["weight-2.didYouKnow"],
        short: quizText["weight-2.short"]
      },
      {
        id: "weight-3",
        category: 7,
        type: "single",
        title: quizText["weight-3.title"],
        subtitle: quizText["weight-3.subtitle"],
        didYouKnow: quizText["weight-3.didYouKnow"],
        short: quizText["weight-3.short"]
      },
      {
        id: "weight-4",
        category: 7,
        type: "single",
        title: quizText["weight-4.title"],
        subtitle: quizText["weight-4.subtitle"],
        body: quizText["weight-4.body"],
        tip: quizText["weight-4.tip"],
        short: quizText["weight-4.short"]
      },
      {
        id: "weight-5",
        category: 7,
        type: "single",
        title: quizText["weight-5.title"],
        subtitle: quizText["weight-5.subtitle"],
        didYouKnow: quizText["weight-5.didYouKnow"],
        short: quizText["weight-5.short"]
      },
      {
        id: "weight-6",
        category: 7,
        type: "single",
        title: quizText["weight-6.title"],
        subtitle: quizText["weight-6.subtitle"],
        tip: quizText["weight-6.tip"],
        short: quizText["weight-6.short"]
      }
    ]
  },
  {
    segmentId: "misc",
    intro: {
      title: quizText["misc.title"],
      image: clipboard,
      bg: "#F8BD3A"
    },
    questions: [
      {
        id: "misc-1",
        category: 8,
        type: "multi",
        title: quizText["misc-1.title"],
        subtitle: quizText["misc-1.subtitle"],
        options: [
          {
            value: "weight",
            text: quizText["misc-1.weight"],
            image: scaleMulti
          },
          {
            value: "muscle",
            text: quizText["misc-1.muscle"],
            image: muscleMulti
          },
          {
            value: "exercise",
            text: quizText["misc-1.exercise"],
            image: shoeMulti
          },
          {
            value: "energy",
            text: quizText["misc-1.energy"],
            image: energyMulti
          }
        ]
      },
      {
        id: "misc-2",
        category: 8,
        type: "multi",
        title: quizText["misc-2.title"],
        subtitle: quizText["misc-2.subtitle"],
        options: [
          {
            value: "men-health",
            text: quizText["misc-2.men-health"],
            image: menHealthMulti
          },
          {
            value: "women-health",
            text: quizText["misc-2.women-health"],
            image: womenHealthMulti
          },
          {
            value: "stress",
            text: quizText["misc-2.stress"],
            image: stressMulti
          }
        ]
      },
      {
        id: "misc-3",
        category: 8,
        type: "multi",
        title: quizText["misc-3.title"],
        subtitle: quizText["misc-3.subtitle"],
        options: [
          {
            value: "vitamins",
            text: quizText["misc-3.vitamins"],
            image: vitaminsMulti
          },
          {
            value: "whole-foods",
            text: quizText["misc-3.whole-foods"],
            image: broccoliMulti
          },
          {
            value: "anti-aging",
            text: quizText["misc-3.anti-aging"],
            image: antiagingMulti
          },
          {
            value: "immune-system",
            text: quizText["misc-3.immune-system"],
            image: immuneMulti
          }
        ]
      },
      {
        id: "misc-4",
        category: 8,
        type: "multi",
        title: quizText["misc-4.title"],
        subtitle: quizText["misc-4.subtitle"],
        options: [
          {
            value: "circulation",
            text: quizText["misc-4.circulation"],
            image: circulationMulti
          },
          {
            value: "eye-health",
            text: quizText["misc-4.eye-health"],
            image: eyeMulti
          },
          {
            value: "joint-mobility",
            text: quizText["misc-4.joint-mobility"],
            image: jointMulti
          },
          {
            value: "mental-focus",
            text: quizText["misc-4.mental-focus"],
            image: mentalMulti
          }
        ]
      }
    ]
  }
];

export default segments;
