import React, { Component } from 'react';
import classnames from 'classnames';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';

import {
  Tabs, Tab, Form, FormControl, Button, Alert,
} from '../../components/rb-import';
import Header from '../../components/Header';

import style from './dist.module.scss';
import './dist.scss';

const ga = (label, value) => ReactGA.event({
  category: 'Cart',
  action: 'Checked out',
  label,
  value,
});


class DistributorPage extends Component {
  constructor(props) {
    super(props);
    this.state = { key: 'id' };
  }

  verify = () => {
    const { verifyRepId, rep } = this.props;
    verifyRepId(rep.repId);
  }

  handleTabSelect = (key) => {
    this.setState({ key });
  }

  handleSubmit = (type) => {
    const { cartUrl, items } = this.props;
    let reason;
    switch (type) {
      case 'id': reason = 'Verified ID'; break;
      case 'ncs': reason = 'Name, city, state'; break;
      case 'z': reason = 'Zip'; break;
      default:
        throw new Error(`Invalid reason type: ${type}`);
    }
    ga(reason, items.length);
    window.location.assign(cartUrl);
  }

  render() {
    const {
      verified, verificationError, rep,
      setRepId, setRepName, setRepCity, setRepState, setRepZip,
      validNameCityState, validZipCode, cartEmpty,
    } = this.props;
    const { repId, repName, repCity, repState, repZip } = rep;
    const { key } = this.state;

    return [
      <div className={style.wrapper} key="content">
        <Header cartEmpty={cartEmpty} showCart />
        <div className={style.titlewrapper}>
          <div className={style.title}>
            <FormattedMessage
              id="dist.title"
              description="Title of Distributor Page"
              defaultMessage="One last thing!"
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <div className={style.description}>
            <FormattedMessage
              id="dist.description"
              description="Description of distributor credit"
              defaultMessage="Youngevity believes in giving credit where credit is due. All orders, whether online or in-person, will be credited to a distributor."
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <div className={style.subtitle}>
            <FormattedMessage
              id="dist.know"
              description="Already know a distributor?"
              defaultMessage="ALREADY KNOW A DISTRIBUTOR?"
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <div className={classnames(style.separator, style.dark)} />
          <Tabs
            id="dist-tabs"
            activeKey={key}
            onSelect={this.handleTabSelect}
          >
            <Tab eventKey="id"
              title={
                <FormattedMessage
                  id="dist.tab1"
                  description="Tab for ID"
                  defaultMessage="Yes, and I have their ID"
                >
                  {(title) => (<div className="tab-title">{title}</div>)}
                </FormattedMessage>
              }
            >
              <div className={style.prompt}>
                <FormattedMessage
                  id="dist.enterid"
                  description="Enter distributor id"
                  defaultMessage="Enter the distributor ID below and hit verify ID. Once verified, you can continue to checkout."
                >
                  {(text) => text}
                </FormattedMessage>
              </div>
              <div className={classnames(style.tabform, style.id)}>
                {verificationError && (
                  <Alert dismissible variant="danger" onClose={() => {}}>
                    <FormattedMessage
                      id="dist.invalidrepid"
                      description="Invalid Rep ID error message"
                      defaultMessage="Invalid Rep ID"
                    >
                      {(text) => text}
                    </FormattedMessage>
                  </Alert>
                )}
                <div className="form-label" style={{ marginBottom: '0.5rem' }}>
                  <FormattedMessage
                    id="dist.label.distributorid"
                    description="Distributor ID form label"
                    defaultMessage="* Distributor ID"
                  >
                    {(text) => text}
                  </FormattedMessage>
                </div>
                <Form inline>
                  <FormControl
                    id="repid"
                    type="text"
                    size="ys"
                    value={repId}
                    onChange={e => setRepId(e.target.value)}
                  />
                  <Button
                    variant="ypri"
                    size="ys" 
                    style={{ marginTop: 0 }}
                    disabled={verified}
                    className="verify"
                    onClick={this.verify}
                  >
                    <FormattedMessage
                      id="dist.button.verifyId"
                      description="Verify ID button text"
                      defaultMessage="Verify ID"
                    >
                      {(text) => text}
                    </FormattedMessage>
                  </Button>
                </Form>
              </div>
              <Button
                variant="ypri" 
                size="ys" 
                className={classnames(style.go, 'w250')} 
                disabled={!verified}
                onClick={() => this.handleSubmit('id')}
                // href={cartUrl}
              >
                  <FormattedMessage
                    id="dist.button.continue"
                    description="Continue button text"
                    defaultMessage="Continue"
                  >
                    {(text) => text}
                  </FormattedMessage>
              </Button>
            </Tab>
            <Tab
              eventKey="forgot"
              title={
                <FormattedMessage
                  id="dist.tab2"
                  description="Tab for name, city, state"
                  defaultMessage="Yes, but I don't know their ID"
                >
                  {(text) => (
                    <div className="tab-title">{text}</div>
                  )}
                </FormattedMessage>
              }
            >
              <div className={style.prompt}>
                <FormattedMessage
                  id="dist.tab2.description"
                  description="Second tab description"
                  defaultMessage="No problem! Give us a little bit of information to help us connect you with the distributor of your choice."
                >
                  {(text) => text}
                </FormattedMessage>
              </div>
              <div className={classnames(style.tabform, style.forgot)}>
                <form>
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage
                        id="dist.form.name"
                        description="Distributor name field label"
                        defaultMessage="* Distributor Name"
                      >
                        {(text) => text}
                      </FormattedMessage>
                    </Form.Label>
                    <FormControl
                      id="repname"
                      type="text"
                      size=""
                      value={repName}
                      style={{ width: '15.625rem' }}
                      onChange={e => setRepName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage
                        id="dist.form.city"
                        description="Distributor city field label"
                        defaultMessage="* Distributor City"
                      >
                        {(text) => text}
                      </FormattedMessage>
                    </Form.Label>
                    <FormControl
                      id="repcity"
                      type="text"
                      size=""
                      value={repCity}
                      style={{ width: '15.625rem' }}
                      onChange={e => setRepCity(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage
                        id="dist.form.state"
                        description="Distributor state field label"
                        defaultMessage="* Distributor State"
                      >
                        {(text) => text}
                      </FormattedMessage>
                      </Form.Label>
                    <FormControl
                      id="repstate"
                      type="text"
                      size=""
                      value={repState}
                      style={{ width: '15.625rem' }}
                      onChange={e => setRepState(e.target.value)}
                    />
                  </Form.Group>
                  <Button
                    variant="ypri" 
                    size="ys" 
                    className={classnames(style.go, 'w250')} 
                    disabled={!validNameCityState}
                    onClick={() => this.handleSubmit('ncs')}
                    // href={cartUrl}
                  >
                      <FormattedMessage
                        id="dist.button.continue"
                        description="Continue button text"
                        defaultMessage="Continue"
                      >
                        {(text) => text}
                      </FormattedMessage>
                  </Button>
                </form>
              </div>
            </Tab>
            <Tab
              eventKey="orphan"
              title={
                <FormattedMessage
                  id="dist.tab3"
                  description="Title for tab 3"
                  defaultMessage="No, no one referred me"
                >
                  {(text) => (
                    <div className="tab-title">{text}</div>
                  )}
                </FormattedMessage>
              }
            >
              <div className={style.prompt}>
                <FormattedMessage
                  id="dist.tab3.description"
                  description="Tab 3 description"
                  defaultMessage="No problem! Tell us where you’re located and we’ll find someone near you."
                >
                  {(text) => text}
                </FormattedMessage>
              </div>
              <div className={classnames(style.tabform, style.forgot)}>
                <form>
                  <Form.Group>
                    <Form.Label>
                      <FormattedMessage
                        id="dist.form.zip"
                        description="Zip code for distributor page"
                        defaultMessage="* Zip Code"
                      >
                        {(text) => text}
                      </FormattedMessage>
                    </Form.Label>
                    <FormControl
                      id="repzip"
                      type="text"
                      size=""
                      value={repZip}
                      style={{ width: '15.625rem' }}
                      onChange={e => setRepZip(e.target.value)}
                    />
                  </Form.Group>
                  <Button 
                    variant="ypri" 
                    size="ys" 
                    className={classnames(style.go, 'w250')}
                    disabled={!validZipCode}
                    onClick={() => this.handleSubmit('z')}
                    // href={cartUrl}
                  >
                      <FormattedMessage
                        id="dist.button.continue"
                        description="Continue button text"
                        defaultMessage="Continue"
                      >
                        {(text) => text}
                      </FormattedMessage>
                  </Button>
                </form>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>,
    ];
  }
}

export default DistributorPage;
