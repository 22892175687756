import { createReducer, makeActionCreator } from "../utils";

import { QUIZ_RESET } from './quiz';

const SET_NAME = 'ygy/name/SET_NAME';

const initialState = '';

const reducer = createReducer(initialState, {
  [SET_NAME]: (state, action) => action.name,
  [QUIZ_RESET]: () => initialState,
});

export default reducer;

export const setName = makeActionCreator(SET_NAME, 'name');

export const getName = state => state.name;
