import React from 'react';

import style from './quizprogressbar.module.scss';

// The height of the progressbar including the numbered segment indicators
const PROGRESSBAR_HEIGHT = 36

// The number of segments to display
const TOTAL_SEGMENTS = 8;

// The width of each segment
const SEGMENT_WIDTH = 41;

// The total width of the progressbar given the number of segments and the segment width.
const BAR_WIDTH = TOTAL_SEGMENTS * SEGMENT_WIDTH;

// The height of the thermometer portion of the progress bar
const BAR_HEIGHT = 10;

// The radius of the endcaps on each side of the progressbar
const BAR_RADIUS = BAR_HEIGHT / 2;

// The radius of the colored background for each numbered segment indicator
const CIRCLE_RADIUS = 7.5;

/**
 * 
 * @param {Numnber} number the number of the numbered segment indicator being generated
 * @param {Number} current the current segment number
 * @param {String} color the color to apply to the current segment. Completed segments have a gray backround
 * @param {String} key the virtual DOM key required by React
 * '✓'
 */
const segmentNumber = (number, current, color, key) => (
  <React.Fragment key={key}>
    <circle
      className="progress-dot"
      cx={number * SEGMENT_WIDTH - SEGMENT_WIDTH / 2} 
      cy={PROGRESSBAR_HEIGHT - CIRCLE_RADIUS}
      r={CIRCLE_RADIUS}
      stroke="none"
      fill={number === current ? color : '#CCCCCC'}
    />
    <text
      className="progress-num"
      x={number * SEGMENT_WIDTH - SEGMENT_WIDTH / 2}
      y={PROGRESSBAR_HEIGHT - CIRCLE_RADIUS}
      style={{ font: '600 12px/21px Montserrat'}}
      textAnchor="middle"
      dy="4px"
      fill="white"
      stroke="none"
    >
      {number < current ? '✓' : number}
    </text>
  </React.Fragment>
);

/**
 * Generates the numbered segment indicators that are displayed below the progressbar.
 * 
 * @param {Number} current the current segment number
 * @param {String} color the progressbar color code
 */
const segments = (current, color) => {
  const result = [];
  for (let i = 1; i <= TOTAL_SEGMENTS; i += 1) {
    result.push(segmentNumber(i, current, color, `pb-${i}`));
  }
  return result;
}

function shadeRGBColor(color, percent) {
  const f=parseInt(color.slice(1),16);
  const t=percent<0?0:255;
  const p=percent<0?percent*-1:percent;
  const R=f>>16;
  const G=(f>>8)&0x00FF;
  const B=f&0x0000FF;
  return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
}


const backgroundBar = () => {
  return (
    <path
      fill="white"
      stroke="none"
      d={`
        M ${BAR_RADIUS} 0
        L ${BAR_WIDTH - BAR_RADIUS} 0
        A ${BAR_RADIUS} ${BAR_RADIUS} 180 1 1 ${BAR_WIDTH - BAR_RADIUS} ${BAR_HEIGHT}
        L ${BAR_RADIUS} ${BAR_HEIGHT}
        A ${BAR_RADIUS} ${BAR_RADIUS} 180 1 1 ${BAR_RADIUS} 0 z
      `}
    />
  );
};

/**
 * Returns a bar that covers the entire width from start to end of current segment,
 * but with the current color lightened by 75%.
 */
const currentBackgroundBar = (current, color) => {
  return (
    <path
      fill={shadeRGBColor(color, 0.75)}
      stroke="none"
      d={`
        M ${BAR_RADIUS} 0
        L ${SEGMENT_WIDTH * current} 0
        L ${SEGMENT_WIDTH * current} ${BAR_HEIGHT}
        L ${BAR_RADIUS} ${BAR_HEIGHT}
        A ${BAR_RADIUS} ${BAR_RADIUS} 180 1 1 ${BAR_RADIUS} 0 z
      `}
    />
  );
}

const bar = (width, color) => {
  if (width > 0) {
    return (
      <path
        fill={color}
        stroke="none"
        d={`
          M ${BAR_RADIUS} 0
          L ${width} 0
          L ${width} ${BAR_HEIGHT}
          L ${BAR_RADIUS} ${BAR_HEIGHT}
          A ${BAR_RADIUS} ${BAR_RADIUS} 180 1 1 ${BAR_RADIUS} 0 z
        `}
      />
    );
  }
  return null;
}

/**
 * Presents a horizontal progressbar with fixed number of segments. Completed and current segments
 * are depicted via numbered circles below the progressbar itself.
 * 
 * @param {Object} props the component properties
 * @param {Number} props.segment the current quiz segment including fractional progress
 * @param {Number} props.current the current quiz segment
 * @param {String} props.color the color to apply to the progress bar and segment number backgrounds
 */
const QuizProgressBar = ({ segment, current, color }) => {
  const progressWidth = SEGMENT_WIDTH * segment;
  return (
    <svg width={`${BAR_WIDTH}px`} height={`${PROGRESSBAR_HEIGHT}px`} className={style.progressbar}>
      {segment < TOTAL_SEGMENTS && backgroundBar()}
      {segment < TOTAL_SEGMENTS && currentBackgroundBar(current, color)}
      {bar(progressWidth, color)}
      {segments(current, color)}
    </svg>
  );
};

export default QuizProgressBar;