import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button } from '../../components/rb-import';
import { browserHistory } from '../../history';
import Header from '../../components/Header';

import style from './returning.module.scss';

/**
 * Presents a view that enables the user to enter their first name.
 * 
 * @param {Object} props the component properties
 * @param (String) props.name the user's name
 * @param {Function} props.setName Redux dispatch function to update the user's name
 */
class ReturningPage extends Component {
  /**
   * Loads the results page if the user has completed the quiz. Otherwise it loads the
   * index page, assuming that the user erroneously navigated to this page. Under normal
   * circumstances the user will only show up here if the survey was complete. The fact
   * that they turned up with an incomplete survey suggests they are poking around and
   * should be redirected to the home page preemptively.
   */
  continue = () => {
    const { completed } = this.props;
    const nextPath = completed ? '/results' : '/';
    browserHistory.push(nextPath);
  }

  reset = () => {
    const { resetQuiz } = this.props;
    resetQuiz();
    setTimeout(() => browserHistory.push('/quiz'), 100); 
  }

  render() {
    const { name } = this.props;
    return (
      <Fragment>
        <Header key="header" />
        <div className={classnames(style.panel, 'mx-auto')} key="panel">
          <div className={style.question}>
            <FormattedMessage
              id="returning.welcome"
              description="Welcome message"
              defaultMessage="Welcome back, {name}!"
              values={{
                name,
              }}
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <div className={style.description}>
            <FormattedMessage
              id="returning.description"
              description="Description on returning page"
              defaultMessage="You have already completed the quiz. Would you like to start over, or review your previous results?"
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <Button
            variant="ypri"
            size="ys"
            className={classnames('w250','btn-space', 'continue')}
            onClick={() => this.continue()}
          >
            <FormattedMessage
              id="returning.button.continue"
              description="Continue button text"
              defaultMessage="Continue"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
          <Button
            variant="ypri"
            size="ys"
            className={classnames('w250','btn-space', 'reset')}
            onClick={() => this.reset()}
          >
            <FormattedMessage
              id="returning.button.startover"
              description="Start over button text"
              defaultMessage="Start over"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
        </div>
      </Fragment>        
    );
  }
}

export default ReturningPage;
