import { createReducer, makeActionCreator } from '../utils';

// ACTION TYPES

const SHOW_PANEL = 'ygy/panel/SHOW_PANEL';
const HIDE_PANEL = 'ygy/panel/HIDE_PANEL';

// REDUCER

const initialState = {};

const reducer = createReducer(initialState, {
  [SHOW_PANEL]: (state, action) => {
    return ({ showing: action.panel });
  },
  [HIDE_PANEL]: (state, action) => {
    if (state.showing === action.panel) {
      return {};
    }
    return state;
  },
});

export default reducer;

// ACTION CREATORS

export const showPanel = makeActionCreator(SHOW_PANEL, 'panel');
export const hidePanel = makeActionCreator(HIDE_PANEL, 'panel');

// SELECTORS

export const getPanelVisible = (state, panel) => state.panel.showing === panel;
