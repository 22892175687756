import React, { Component, Fragment } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scroll from 'react-scroll';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Image, Button } from '../../components/rb-import';
import Footer from '../../components/Footer';
import Pak from '../../components/Pak';
import HealthEnhancer from '../../components/HealthEnhancer';
import Header from '../../components/Header';
import SharePanel from '../../components/SharePanel';
import { browserHistory } from '../../history';

import segments from '../../components/Pak/products';
import clipboard from './clipboard.svg';
import print from './printer.svg';

import style from './results.module.scss';
import './print.scss';

export const getClause = (score, intl) => intl.formatMessage(
  (segments.find(seg => seg.segmentId === score.segmentId) || {}).clause
);

class ResultsPage extends Component {
  replayEnhancers = () => {
    const { replayFrom } = this.props;
    Promise.resolve(replayFrom(7,0))
    .then(() => browserHistory.push('/runner'));
  }

  render() {
    const { scores, more, name, cartEmpty, intl } = this.props;
    return (
      <Fragment>
        <div className={style["title-block"]} key="title">
          <Header light cartEmpty={cartEmpty} showCart />
          <div className={style.titlewrapper}>
            <div className={style.title}>
              <FormattedMessage
                id="results.title"
                description="Title for results page"
                defaultMessage="{name}, we have your results"
                values={{
                  name
                }}
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            {/* <div className={style.separator} /> */}
            <div className={style.step}>
              <FormattedMessage
                id="results.step"
                description="Results page subtitle"
                defaultMessage="Great job! You just took a big step towards better health."
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <div className={style.down}>
              <FontAwesomeIcon icon="chevron-down" size="3x" />
            </div>
          </div>
        </div>
        <div className={style.recos} key="recos">
          <div className={style.recoswrapper}>
            <div className={style.paks}>
              <FormattedMessage
                id="results.pakstitle"
                description="Title for Paks section"
                defaultMessage="View recommendations below:"
              >
                {(text) => text}
              </FormattedMessage>
              <ul className={style.pagelinks}>
                <li>
                  <Scroll.Link
                    to="paks"
                    spy={true}
                    smooth={true}
                    offset={-20}
                    duration={500}
                  >
                    <FormattedMessage
                      id="results.index.recommendedpaks"
                      description="Index entry for recommended paks"
                      defaultMessage="Recommended Health &amp; Body Start Paks"
                    >
                      {(text) => text}
                    </FormattedMessage>
                  </Scroll.Link>
                </li>
                <li>
                  <Scroll.Link
                    to="more" 
                    spy={true} 
                    smooth={true} 
                    offset={-20} 
                    duration={500}
                  >
                    <FormattedMessage
                      id="results.index.enhancers"
                      description="Index entry for targeted nutrition"
                      defaultMessage="Recommended Targeted Nutrition"
                    >
                      {(text) => text}
                    </FormattedMessage>
                  </Scroll.Link>
                </li>
                <li onClick={() => browserHistory.push('/all')}>
                  <FormattedMessage
                    id="results.index.allresults"
                    description="Index entry for all results section"
                    defaultMessage="All Products"
                  >
                    {(text) => text}
                  </FormattedMessage>
                </li>
              </ul>
            </div>
            <div className={classnames(style.link, style.print, 'my-5')} onClick={() => window.print()}>
              <Image src={print} />{' '}
              <FormattedMessage
                id="results.printresults"
                description="Print these results button"
                defaultMessage="Print these results"
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <Scroll.Element name="paks" className={style.paks}>
              <FormattedMessage
                id="results.pak.title"
                description="Title for paks list"
                defaultMessage="Our product recommendations based on your results:"
              >
                {(text) => text}
              </FormattedMessage>
            </Scroll.Element>
            <div className={style.picwrapper}>
              <div className={style.pic}>
                <Image src={clipboard} height={73} />
              </div>
              <div className={style.description}>
                <FormattedMessage
                  id="results.pak.concern"
                  description="Concerns intro paragraph"
                  defaultMessage="You told us you were most concerned with:"
                >
                  {(text) => text}
                </FormattedMessage>
                <ul className="my-3">
                  <li className={style.callout}>{getClause(scores[0], intl)}</li>
                  <li className={style.callout}>{getClause(scores[1], intl)}</li>
                </ul>
                <FormattedMessage
                  id="results.pak.review"
                  description="Review list below"
                  defaultMessage="Review your full results below to see how you scored in each category and learn what pak is best for you."
                >
                  {(text) => text}
                </FormattedMessage>
              </div>
            </div>
            <div className={style.sectiontitle} id="paks">
              <FormattedMessage
                id="results.healthybody"
                description="Healthy body start pak 2.0"
                defaultMessage="HEALTHY BODY START PAK 2.0"
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <Pak
              segment={scores[0].segmentId}
              pick={true}
              score={scores[0].score}
              reason={scores[0].reason}
              sku={scores[0].sku}
            />
            <div className={style.otherrecos}>
              {scores.slice(1).map(res => (
                <Pak
                  key={res.segmentId}
                  segment={res.segmentId}
                  summary={res.summary}
                  pick={res.pick}
                  score={res.score}
                  reason={res.reason}
                  sku={res.sku}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={style.morerecos} key="more">
          <div className={style.morerecoswrapper}>
            <Scroll.Element name="more" className={style.sectiontitle}>
              <FormattedMessage
                id="results.enhancers.title"
                description="Targeted Nutrition title"
                defaultMessage="RECOMMENDED TARGETED NUTRITION"
              >
                {(text) => text}
              </FormattedMessage>
            </Scroll.Element>
            {more.length !== 0 ? (
              <Fragment>
                <div className={classnames(style.lead, 'mb-5')}>
                  <FormattedMessage
                    id="results.enhancers.description"
                    description="Other aspects"
                    defaultMessage="It also looks like there are other aspects of your health you would like to improve. Below you’ll find other products we highly recommend to support you with your overall health goals. "
                  >
                    {(text) => text}
                  </FormattedMessage>
                </div>
                {more.map(res => (
                  <HealthEnhancer
                    key={res}
                    enhancer={res}
                    summary
                  />
                ))}
              </Fragment>
            ) : (
              <Fragment>
                <div className={classnames(style.lead, 'mb-3')}>
                  <FormattedMessage
                    id="results.forgot"
                    description="Text to display when user doesn't select targeted nutrition"
                    defaultMessage="It looks like you may have forgotten to add some important items to your nutritional mix. Let’s revisit our Targeted Nutrition section to make sure you’ve covered all your health goals."
                  >
                    {(text) => text}
                  </FormattedMessage>
                </div>
                <div className={classnames(style.link, 'mb-4')} onClick={this.replayEnhancers}>
                  <FontAwesomeIcon icon="chevron-left" />
                  {' '}
                  <FormattedMessage
                    id="results.button.back"
                    description="Back to Targeted Nutrition button text"
                    defaultMessage="Back to Targeted Nutrition"
                  >
                    {(text) => text}
                  </FormattedMessage>
              </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className={style.browse} key="browse">
          <div className={style.browsewrapper}>
            <div className={style.browsetitle}>
              <FormattedMessage
                id="results.all.title"
                description="Browse all products title"
                defaultMessage="Browse all products"
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <div className={style.browsedesc}>
              <FormattedMessage
                id="results.all.subtitle"
                description="Subtitle for All Products page"
                defaultMessage="Incredible nutritional support for all your health concerns."
              >
                {(text) => text}
              </FormattedMessage>
            </div>
            <LinkContainer to="/all">
              <Button variant="ysec" size="ys" className="w250 btn-space">
                <FormattedMessage
                  id="results.button.all"
                  description="Browse all products button text"
                  defaultMessage="Browse all products"
                >
                  {(text) => text}
                </FormattedMessage>
              </Button>
            </LinkContainer>
            <div className={style.share}>
              <SharePanel />
            </div>
          </div>
        </div>
        <Footer key="footer" />
      </Fragment>
    );
  }
}

export default injectIntl(ResultsPage);

