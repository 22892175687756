import React, { Fragment } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Row, Col, Button } from '../../components/rb-import';
import QuizProgressBar from './progress';
import SingleChoice from './single';
import MultipleChoice from './multi';
import Header from '../../components/Header';

import style from './question.module.scss';


const QuestionPage = ({ type, segment, progress, color, onNavigate }) => {
  return (
    <Fragment>
      <Header key="header" />
      <Row key="progress" >
        <Col>
          <QuizProgressBar segment={progress} current={segment} color={color} width="100%" />
        </Col>
      </Row>
      <div className={classnames(style.panel, "mx-auto")} key="single-multi">
        {type === 'single' && (<SingleChoice onNavigate={onNavigate} />)}
        {type === 'multi' && (<MultipleChoice />)}
      </div>
      {type === 'single' && (
        <div className={classnames(style.disclaim, 'mt-2', 'mx-auto')}>
          <FormattedMessage
            id="question.disclaimer"
            description="Disclaimer on single choice questions"
            defaultMessage="These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure, or prevent any disease."
          >
            {(text) => text}
          </FormattedMessage>
        </div>
      )}
      {type === 'single' && (
        <div 
          key="single-back"
          className={classnames(style.back, 'mx-auto')}
          onClick={() => onNavigate('prev')}
        >
          <FormattedMessage
            id="question.button.back"
            description="Back button on question page"
            defaultMessage="&lt; Back"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
      )}
      {type === 'multi' && (
        <div key="multi-back" className={style.buttons}>
          <Button
            className={classnames(style.button, style.prev)}
            onClick={() => onNavigate('prev')}
          >
            <FormattedMessage
              id="question.button.multiback"
              description="Back button on multiple-choice question page"
              defaultMessage="Back"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
          <Button
            className={classnames(style.button, style.next)}
            onClick={() => onNavigate('next')}
          >
            <FormattedMessage
              id="question.button.next"
              description="Next button on question page"
              defaultMessage="Next"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default QuestionPage;
