import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import ReactGA from 'react-ga';
import { FormattedMessage, injectIntl } from 'react-intl';

import { browserHistory } from '../../history';
import { Button, Image } from '../rb-import';
import Price from '../Price';

import style from './cart.module.scss';

const checkItemIntl = (item) => {
  const { title } = item;
  return (
    title.id !== undefined
    && title.description !== undefined
    && title.defaultMessage !== undefined
  );
}

export const CartItem = ({ item, removeFromCart, intl }) => {
  if(checkItemIntl(item)) {
    return (
      <div className={style.item}>
        <div className={style.imagewrapper}>
          <Image src={item.image} />
        </div>
        <div className={style.title}>{
          intl.formatMessage(item.title)
        }</div>
        <div className={style.price}>
          <Price prices={item.retail} />
          {' '}
          <span>
            <FormattedMessage
              id="cart.retail"
              description="Retail price label"
              defaultMessage="Retail"
            >
              {(text) => text}
            </FormattedMessage>
          </span><br />
          <Price prices={item.wholesale} />
          {' '}
          <span>
            <FormattedMessage
              id="cart.wholesale"
              description="Wholesale price label"
              defaultMessage="Wholesale"
            >
              {(text) => text}
            </FormattedMessage>
          </span>
          <div className={style.sku}>
            <FormattedMessage
              id="cart.sku"
              description="Sku for cart"
              defaultMessage="SKU #"
            >
              {(text) => text}
            </FormattedMessage>
            {item.sku}
          </div>
        </div>
        <div
          className={style.remove} 
          onClick={() => removeFromCart(item.sku)}
        >
          <FormattedMessage
            id="cart.remove"
            description="Cart item remove button"
            defaultMessage="x Remove"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
      </div>
    );
  } else {
    console.log(`Invalid item: ${JSON.stringify(item)}`);
  }
  return null;
};

class Cart extends Component {
  componentDidMount() {
    const { cleanCart } = this.props;
    cleanCart();
  }

  handleClick = () => {
    const { setShowingCart, verified, cartUrl, items } = this.props;
    setShowingCart(false);
    if (verified) {
      ReactGA.event({
        category: 'Cart',
        action: 'Checked out',
        label: 'Verified replicated ID',
        value: items.length,
      });
      window.location.assign(cartUrl);
    } else {
      browserHistory.push('/distributor');
    }
  }

  render() {
    const { items, showing, removeFromCart, setShowingCart, intl, language } = this.props;
    return (
      <div className={classnames(style.cart, style[language], { [style.yammo]: showing })}>
        <div className={style.header}>
          <FormattedMessage
            id="cart.list"
            description="Your Shopping List title"
            defaultMessage="YOUR SHOPPING LIST"
          >
            {(text) => text}
          </FormattedMessage>
        </div>
        <div className={style.list}>
          {items &&
            items.map(item => (<CartItem key={item.sku} item={item} removeFromCart={removeFromCart} intl={intl} />))
          }
        </div>
        <div className={style.cartfoot}>
          <div className={classnames(style.prompt, 'w250')}>
            <FormattedMessage
              id="cart.goteverything"
              description="Cart call to action"
              defaultMessage="Got everything you need? Click the button below to finish up &amp; checkout."
            >
              {(text) => text}
            </FormattedMessage>
          </div>
          <Button variant="ypri" size="ys" className="w250 smaller btn-space" onClick={this.handleClick}>
            <FormattedMessage
              id="cart.ctabutton"
              description="Call to action button text"
              defaultMessage="FINISH &amp; PROCEED TO CHECKOUT"
            >
              {(text) => text}
            </FormattedMessage>
          </Button>
          <div className={style.back} onClick={() => setShowingCart(false)}>
            <FontAwesomeIcon icon="chevron-left" />
            {' '}
            <FormattedMessage
              id="cart.back"
              description="Cart back to results"
              defaultMessage="Back to results"
            >
              {(text) => text}
            </FormattedMessage>
          </div>
        </div>
      </div>
    ) ;
  }
}

export default injectIntl(Cart);