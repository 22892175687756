import { connect } from 'react-redux';

import {
  verifyRepId, getRepIdVerified, getVerificationError, getValidNameCityState, getValidZipCode,
  setRepName, setRepCity, setRepState, setRepZip, getRepInfo, setRepId, getCartTransferURL,
  getCartItems,
} from '../../state'; 
import DistributorPage from './DistributorPage';

const mapStateToProps = state => ({
  rep: getRepInfo(state),
  verified: getRepIdVerified(state),
  verificationError: getVerificationError(state),
  validNameCityState: getValidNameCityState(state),
  validZipCode: getValidZipCode(state),
  cartUrl: getCartTransferURL(state),
  items: getCartItems(state),
})

export default connect(mapStateToProps, {
  verifyRepId, setRepId, setRepName, setRepCity, setRepState, setRepZip,
})(DistributorPage);
