import { combineReducers } from "redux";

import quiz from "./ducks/quiz";
import name from "./ducks/name";
import cart from "./ducks/cart";
import rep from "./ducks/rep";
import panel from "./ducks/panel";
import config, { getCountry } from "./ducks/config";
import { makeActionCreator } from "./utils";

const RESET_APP_STATE = "ygy/global/RESET_APP_STATE";

const allReducers = combineReducers({
  name,
  quiz,
  cart,
  rep,
  panel,
  config
});

const ygyApp = (state, action) => {
  if (action.type === RESET_APP_STATE) {
    return allReducers(undefined, action);
  }
  return allReducers(state, action);
};

export default ygyApp;

export * from "./ducks/quiz";
export * from "./ducks/name";
export * from "./ducks/cart";
export * from "./ducks/rep";
export * from "./ducks/panel";
export * from "./ducks/config";

const e = val => encodeURIComponent(val);

const cartHost = process.env.REACT_APP_CART_HOST || "ygyi-stg.com";
const cartProtocol = process.env.REACT_APP_CART_PROTOCOL || "http://";

export const getCartTransferURL = state => {
  const {
    rep: { repId, repName, repCity, repState, repZip },
    cart: { items }
  } = state;
  const country = getCountry(state).toLowerCase();
  const language = "en"; // Temporary per Juito Hartanto 2091-05-16
  // const language = getLanguage(state);
  const cartPath = `/${country}_${language}`;

  const itemQuery = items.map(item => item.sku).join(",");
  if (repId !== "") {
    return `${cartProtocol}${repId}.${cartHost}${cartPath}/quiz/?sku=${encodeURIComponent(
      itemQuery
    )}&cart=1`;
  } else if (repName !== "" && repCity !== "" && repState !== "") {
    return `${cartProtocol}${cartHost}${cartPath}/quiz/?sku=${encodeURIComponent(
      itemQuery
    )}&cart=1&notes=${e(repName)},${e(repCity)},${e(repState)}`;
  } else if (repZip !== "") {
    return `${cartProtocol}${cartHost}${cartPath}/quiz/?sku=${encodeURIComponent(
      itemQuery
    )}&cart=1&notes=${e(repZip)}`;
  }
  return "#";
};

export const resetAppState = makeActionCreator(RESET_APP_STATE);

// const countryHost = {
//   CA: process.env.REACT_APP_CART_HOST_CA || 'ygyi-stg-ca.com',
//   US: process.env.REACT_APP_CART_HOST || 'ygyi-stg.com',
// };

// export const cartHost = country => countryHost[country] || 'ygyi-stg.com';
// const cartProtocol = process.env.REACT_APP_CART_PROTOCOL || 'http://';

// export const getCartTransferURL = state => {
//   const { rep: { repId, repName, repCity, repState, repZip }, cart: { items } } = state;
//   const country = getCountry(state);
//   const host = cartHost(country);

//   const itemQuery = items.map(item => item.sku).join(',');
//   if (repId !== '') {
//     return `${cartProtocol}${repId}.${host}/quiz/?sku=${e(itemQuery)}&cart=1`;
//   } else if (repName !== '' && repCity !== '' && repState !== '') {
//     return `${cartProtocol}${host}/quiz/?sku=${e(itemQuery)}&cart=1&notes=${e(repName)},${e(repCity)},${e(repState)}`
//   } else if (repZip !== '') {
//     return `${cartProtocol}${host}/quiz/?sku=${e(itemQuery)}&cart=1&notes=${e(repZip)}`
//   }
//   return '#';
// }
