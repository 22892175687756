import { connect } from 'react-redux';

import {
  getQuizContentType, getQuizContent, getQuizSegmentColor, getQuizProgress,
} from '../../state';
import QuestionPage from './QuestionPage';

export const mapStateToProps = state => ({
  type: getQuizContentType(state),
  segment: getQuizContent(state).category,
  color: getQuizSegmentColor(state),
  progress: getQuizProgress(state),
});

export default connect(mapStateToProps)(QuestionPage);
